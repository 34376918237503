import "../views/playlistMixedView.scss";

import { ItemCollection } from "~models/itemCollection";
import { HeroLandingZone } from "~swimlaneViews/heroLandingZone";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { AllSportsPage } from "../pages/allSports/allSports";
import { pushTabWithMenu } from "../pages/rootPage";
import { onSelectTile } from "../pages/rootPage";
import { PlaylistMixedView } from "../views/playlistMixedView";
import { mappingMedalsEvent } from "./../tools/idalgo/idalgo";

export class PlaylistTagSwimlane extends View {
  cacheable = true;
  private _heroZoneElt: HeroLandingZone;
  private _VIEW_ALL_SPORTS: {
    id: string;
    title: string;
  };
  constructor(source: ItemCollection, viewAllbackgroundImgUrl?: string, viewAllLogoImgUrl?: string) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneTagVignette", "list"));
    this._heroZoneElt = new HeroLandingZone(this.rootElement, source);

    this._VIEW_ALL_SPORTS =
      mappingMedalsEvent(source.itemCollection?.extras?.url_complete) === "jp"
        ? {
            id: "viewAllSports",
            title: "Tous les sports des Jeux Paralympiques de Paris 2024",
          }
        : {
            id: "viewAllSports",
            title: "Tous les sports des Jeux Olympiques de Paris 2024",
          };
    const modelSource = [this._VIEW_ALL_SPORTS, ...source.items];

    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(modelSource),
      viewFactory: model => {
        const view = model === this._VIEW_ALL_SPORTS ? new AllSportsView() : new PlaylistMixedView(model);
        view.onFocused = () => {
          this._heroZoneElt.updateItem(model).then();
        };
        return view;
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: (model, index) => {
        if (model === this._VIEW_ALL_SPORTS) {
          pushTabWithMenu(
            new AllSportsPage(source.items, model.title, viewAllbackgroundImgUrl, viewAllLogoImgUrl),
            "AllSportsPage"
          );
          return true;
        }
        return onSelectTile(model, index);
      },
    });
  }
}

class AllSportsView extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "allSportsView", "ItemMixedVignette"));
    const boxElement = DOMHelper.createDivWithParent(this.rootElement, null, "ItemMixedViewBoxZoom");
    DOMHelper.createDivImg(boxElement, null, "elementImg", require("~images/tagCard/allSportsCard.png"));
  }
  onFocused?: () => void;
}
