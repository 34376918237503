import "./liveTilesSwimlane.scss";

import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Unit } from "~models/unit";
import { LiveTileView } from "~pages/lives/liveTile";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

export class LiveTilesSwimlane extends View {
  itemTitleElt?: HTMLElement;

  constructor(source: ItemCollection, title?: string) {
    super(DOMHelper.createDivWithParent(null, "", "liveTilesSwimlane"));
    const itemDescElt = DOMHelper.createDivWithParent(this.rootElement, null, "itemDesc");
    this.itemTitleElt = DOMHelper.createDivWithParent(itemDescElt, null, "itemTitle", title || "");
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Integrale | Unit)[]),
      viewFactory: (model: Integrale | Unit) => {
        return new LiveTileView(model, false);
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: onSelectTile,
    });
  }
}
