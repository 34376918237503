import { Artwork } from "~models/artwork";
import { Item } from "~models/item";
import { IModel } from "~ui-lib";

export abstract class BrowsableItem extends Item implements IModel {
  artworks: Array<Artwork>;
  extras: any;
  sponsored?: boolean;

  protected constructor(
    id: string,
    type: string,
    title: string,
    summary: any,
    artworks: Array<Artwork>,
    extras: any,
    sponsored?: boolean
  ) {
    super(id, type, title, summary);

    this.artworks = artworks;
    this.extras = extras;
    this.sponsored = sponsored;
  }

  getBackgroundImgUrl(): string {
    const format = "background_16x9";
    const resolution = 1920;
    let imgUrl = this.selectImageUrl(format, resolution);
    if (!imgUrl || !imgUrl.length) {
      imgUrl = this.extras.program?.selectImageUrl(format, resolution);
    }
    if (!imgUrl || !imgUrl.length) {
      imgUrl = this.extras.event?.selectImageUrl(format, resolution);
    }
    return imgUrl;
  }

  getLandscapeTileImgUrl(): string {
    return this.selectImageUrl("vignette_16x9", 400);
  }

  getPosterTileImgUrl(): string {
    return this.selectImageUrl("vignette_3x4", 400);
  }

  getLogoImgUrl(): string {
    return this.selectImageUrl("logo", 450);
  }

  getSquareImgUrl(): string {
    return this.selectImageUrl("carre", 400);
  }

  getHeroImgUrl(): string {
    return this.selectImageUrl("hero", 400);
  }

  getMEABannerImgUrl(): string {
    return this.selectImageUrl("vignette_16x9", 1920);
  }

  selectImageUrl(
    format: "background_16x9" | "vignette_16x9" | "vignette_3x4" | "logo" | "hero" | "carre",
    targetResolution: number
  ): string {
    if (this.artworks.length <= 0) {
      return "";
    } else if (this.artworks.length === 1) {
      return this.artworks[0].src;
    }

    let selectedImgUrl: string | undefined = undefined;
    let currentMax = Number.MAX_SAFE_INTEGER;
    for (const artwork of this.artworks) {
      if (artwork.format === format) {
        // Find the closest to maxResolution
        const distance = Math.abs(Number(artwork.key) - targetResolution);
        if (distance < currentMax) {
          selectedImgUrl = artwork.src;
          currentMax = distance;
        }
      }
    }
    return selectedImgUrl ?? "";
  }
}
