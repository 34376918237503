export function convertDuration(seconds: number, format?: string): string {
  // verify that seconds is not negative
  if (seconds < 0) {
    seconds = 0;
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  if (format == "HHMMSS") {
    let hoursString = "";
    let minutesString = "";
    let secondsString = "";
    if (hours < 10) {
      hoursString = "0" + hours;
    }
    if (minutes < 10) {
      minutesString = "0" + minutes;
    }
    if (seconds < 10) {
      secondsString = "0" + seconds;
    }

    return hoursString + ":" + minutesString + ":" + secondsString;
  } else {
    let str = "";
    if (hours > 0) {
      str = hours + " h ";
    }
    if (minutes > 0) {
      str += minutes + " min";
    }
    return str !== "" ? str : "1 min";
  }
}

export function convertDateDayMonth(date: Date): string {
  if (date) {
    const dd = date.getDate();
    const mm = date.getMonth() + 1;

    return (dd > 9 ? "" : "0") + dd + "." + (mm > 9 ? "" : "0") + mm;
  } else {
    return "";
  }
}

export function convertDate(date: Date): string {
  let str = "";
  const dd = date.getDate();
  const mm = date.getMonth() + 1;

  if (date) {
    const hours = date.getHours();
    if (hours < 10) str = "0" + hours;
    str =
      (dd > 9 ? "" : "0") +
      dd +
      "/" +
      (mm > 9 ? "" : "0") +
      mm +
      "/" +
      date.getFullYear() +
      " à " +
      hours +
      ":" +
      (date.getMinutes() < 10 ? "0" : "") +
      date.getMinutes();
  }

  return str;
}

export function parseDaysLeft(value?: string): string {
  const availability = new Date(value!).getTime();
  const currentDate = Date.now();
  const daysLeft = availability - currentDate;
  const totalSeconds = Math.floor(daysLeft / 1000);
  const totalHours = Math.floor(totalSeconds / 3600);

  if (totalHours > 0 && totalHours <= 120) {
    if (totalHours <= 120 && totalHours >= 97) return "5j";
    else if (totalHours <= 96 && totalHours >= 73) return "4j";
    else if (totalHours <= 72 && totalHours >= 49) return "3j";
    else if (totalHours <= 48 && totalHours >= 25) return "2j";
    else if (totalHours <= 24 && totalHours >= 13) return "1j";
    else if (totalHours <= 12 && totalHours >= 2) {
      // 12 - 2 h left
      return totalHours + "h";
    } else if (totalHours <= 1) {
      // 1 hour left
      return "1h";
    }
  }
  return "";
}
