import "./infobanner.scss";

import { getChannelLogoPlayer } from "~tools/getChannelPics";
import { DOMHelper } from "~ui-lib";

import { PlayableItem } from "../../models/playableItem";

export class InfoBanner {
  constructor(parent: HTMLElement, item: PlayableItem, isLive: boolean) {
    const rootElement = DOMHelper.createDivWithParent(parent, "PlayerInfoBanner", "playerInfoBanner");
    const programEventTitle = item.extras.program?.title || item.extras.event?.title || "";
    const title = programEventTitle || item.title;
    const videoTitle = programEventTitle ? item.extras?.episode_title : "";

    const metadataContainer = DOMHelper.createDivWithParent(rootElement, "metadataContainer", "metadataContainer");

    let marginTop = 163;

    // En direct
    if (isLive) {
      // Logo
      const channelLogo = getChannelLogoPlayer(item);
      if (channelLogo) {
        DOMHelper.createDivImg(metadataContainer, "playerChannelIcon", "playerChannelIcon", channelLogo);
      }
      DOMHelper.createDivWithParent(metadataContainer, null, "badgeLive", "en direct");
      marginTop -= 80;
    }

    // Title & subtitle
    DOMHelper.createDivWithParent(metadataContainer, "PlayerTitle", "playerTitle", title);
    if (videoTitle) {
      marginTop -= 83;
      DOMHelper.createDivWithParent(metadataContainer, "PlayerVideoTitle", "playerVideoTitle", videoTitle);
    }

    metadataContainer.style.marginTop = marginTop + "px";
  }
}
