import "./okooTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { Plugin } from "../../datas/plugin";
import { BrowsableItem } from "../../models/browsableItem";
import { sendPianoAnalytic } from "../../tools/analytics/piano";

const _validSwimlaneTypes = ["mise_en_avant", "playlist_video", "playlist_program", "playlist_mixed"];

export class OkooTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _scrollIndexUnregister?: () => void;
  private _background?: DynamicBackground;

  // This will be used as Okoo Tab container, which has static background artworks
  private _source?: BrowsableItem;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "OkooTab"));
    this._fetchSource();
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "accueil_enfant",
        page_type: "enfant",
      },
      {}
    );
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchChildCategories()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[CHANNEL] next !", value);
          this._source = value[0];
          this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[CHANNEL] Error !", error);
        },
        () => {
          console.log("[CHANNEL] Complete !");
        }
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      staticSource: this?._source?.getBackgroundImgUrl(),
    });
    const containerImage = DOMHelper.createDivWithParent(this.rootElement, null, "containerImage");
    DOMHelper.createDivImg(containerImage, null, "logoImg", require("~images/pictos_okoo/logo_okoo.png"));
    const validSwimlanes: ItemCollection[] = [];
    source.forEach(sl => {
      if (_validSwimlaneTypes.includes(sl.type)) {
        validSwimlanes.push(sl);
      }
    });

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource(validSwimlanes),
        viewFactory: model => {
          if (model.type == "mise_en_avant") {
            return new MEASwimlane(model);
          } else if (model.type == "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type == "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "live") {
            return new PlaylistVideoSwimlane(model);
          } else {
            //default
            return new PlaylistMixedSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );
    this._background.setItemCollectionList(this.listComponent);

    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
  };
}
