import "./cguView.scss";

import { CGU } from "~models/cgu";
import { DOMHelper, View } from "~ui-lib";

export class CGUView extends View {
  constructor(item: CGU) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemCGUVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemCGUViewBoxZoom");
    const elementImg = DOMHelper.createDivImg(box, null, "elementImg");
    DOMHelper.createDivWithParent(elementImg, null, "cguText", item.title);
  }
}
