import "./allSports.scss";

import { createListComponent, DOMHelper, ScrollingMode, StaticModelSource, View } from "~ui-lib";

import { Tag } from "../../models/tag";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { PlaylistMixedView } from "../../views/playlistMixedView";
import { DynamicBackground, DynamicBackgroundOverlayType } from "../dynamicBackground";
import { onSelectTile } from "../rootPage";

const crossSectionWidth = 4;
const pageSize = 3;

export class AllSportsPage extends View {
  private _scrollIndexUnregister?: () => void;
  constructor(items: Tag[], title: string, backgroundImgUrl?: string, logoImgUrl?: string) {
    super(DOMHelper.createDivWithParent(null, "AllSportsPage"));

    new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      source: backgroundImgUrl,
    });

    if (logoImgUrl !== undefined && logoImgUrl.length) {
      DOMHelper.createImg(this.rootElement, "EventLogo", null, logoImgUrl, "");
    }

    DOMHelper.createDivWithParent(this.rootElement, null, "allSportsTitle", title);

    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource(items),
        viewFactory: model => {
          const view = new PlaylistMixedView(model);
          view.rootElement.classList.add("allSportsTileView");
          return view;
        },
        horizontal: false,
        pageSize: pageSize,
        scrollingMode: ScrollingMode.elasticWindow,
        visibleBefore: 0,
        visibleAfter: 1,
        crossSectionWidth: crossSectionWidth,
        spatialFocus: false,
        mouseFocusInPageOnly: true,
        onSelect: onSelectTile,
      },
      list => {
        const updateViews = (scrollIndex: number) => {
          items.forEach((tag, index) => {
            const view = list.viewFromIndex(index);
            if (view && scrollIndex !== undefined) {
              if (index < (scrollIndex + pageSize) * crossSectionWidth) {
                view.rootElement.classList.remove("masked");
              } else {
                view.rootElement.classList.add("masked");
              }
            }
          });
        };
        updateViews(0); // initial calling because didChange doesn't have callListenerInitially parameter
        this._scrollIndexUnregister = list.scrollIndex$.didChange(newScrollIndex => {
          updateViews(newScrollIndex ?? 0);
        });
      }
    );
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "hub_tag",
        page_type: "liste",
      },
      { highlight: "les_jeux_olympiques_2024" }
    );
  }

  onRelease() {
    this._scrollIndexUnregister?.();
  }
}
