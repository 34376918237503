import { ButtonType } from "~pages/player/playerButtons";
import { DOMHelper, IListComponent, View } from "~ui-lib";
import { Listenable } from "~ui-lib";

export enum SkipButtonStateValue {
  removed = "removed",
  hidden = "hidden",
  visible = "visible",
}

export class PlayerSkipButton extends View {
  buttonState$ = new Listenable<SkipButtonStateValue>(SkipButtonStateValue.removed);
  buttonStateListener: () => void;
  playerButtonsDelegate: IListComponent;
  constructor(type: ButtonType, buttonText: string, delegate: IListComponent) {
    super(DOMHelper.createDivWithParent(null, type, "playerSkipButton hide " + type, buttonText));
    this.playerButtonsDelegate = delegate;
    this.buttonStateListener = this.buttonState$.didChange(state => {
      switch (state) {
        case SkipButtonStateValue.visible:
          this._displayButton();
          this.playerButtonsDelegate.setFocusOnId(type);
          break;
        case SkipButtonStateValue.hidden:
          this._hideButton();
          break;
        case SkipButtonStateValue.removed:
          this._removeButton();
          this.playerButtonsDelegate.setFocusOnId(ButtonType.play_pause);
          break;
        default:
          Log.analytics.error(`Error, "${type}" button state "${state}" doesn't exist.`);
          break;
      }
    });
  }

  private _removeButton = () => {
    if (DOMHelper.hasClass(this.rootElement, "visible")) {
      DOMHelper.removeClass(this.rootElement, "visible");
    }
    if (!DOMHelper.hasClass(this.rootElement, "hide")) {
      DOMHelper.addClass(this.rootElement, "hide");
    }
  };

  private _hideButton = () => {
    if (DOMHelper.hasClass(this.rootElement, "visible")) {
      DOMHelper.removeClass(this.rootElement, "visible");
    }
    if (DOMHelper.hasClass(this.rootElement, "hide")) {
      DOMHelper.removeClass(this.rootElement, "hide");
    }
  };

  private _displayButton = () => {
    if (!DOMHelper.hasClass(this.rootElement, "visible")) {
      DOMHelper.addClass(this.rootElement, "visible");
    }
    if (DOMHelper.hasClass(this.rootElement, "hide")) {
      DOMHelper.removeClass(this.rootElement, "hide");
    }
  };

  rejectsFocus = () => DOMHelper.hasClass(this.rootElement, "hide");

  onRelease = () => {
    this.buttonStateListener();
  };
}
