import { PlayableItem } from "../models/playableItem";

export type CurrentOffSet = {
  id: string;
  currentOffset: number;
};

class PlayHistoryManager {
  private static _instance?: PlayHistoryManager;
  private _mapCurrentOffset: Map<string, number> = new Map();

  static get instance() {
    return (PlayHistoryManager._instance = PlayHistoryManager._instance ?? new PlayHistoryManager());
  }

  public setList(playHistoryList: CurrentOffSet[]) {
    for (const offset of playHistoryList) {
      this._mapCurrentOffset.set(offset.id, offset.currentOffset);
    }
    Log.app.warn("setList ", this._mapCurrentOffset);
  }

  /**
   * Update offset
   * @param item PLayableItem
   * @param newOffset
   */
  updateOffset(item: PlayableItem, newOffset: number) {
    this._mapCurrentOffset.set(item.id, newOffset);
  }

  set(offset: CurrentOffSet) {
    this._mapCurrentOffset.set(offset.id, offset.currentOffset);
  }
  /**
   * Get currentOffset for the media id
   * @param item if of the media
   * @returns currentOffset
   */
  getCurrentOffset(item: PlayableItem): number | undefined {
    return this._mapCurrentOffset.get(item.id);
  }

  /**
   * get all ids with a currentOffset
   * @returns Offset
   */
  getIds(): string[] {
    return Array.from(this._mapCurrentOffset.keys());
  }

  /**
   * clear the history list
   */
  clearlist() {
    this._mapCurrentOffset.clear();
  }
}

export const PlayHistoryHelper = PlayHistoryManager.instance;
