import "./playlistProgramView.scss";

import { getChannelPicto } from "~tools/getChannelPics";
import { checkImage } from "~tools/tools";
import { DOMHelper, View } from "~ui-lib";

export class PlaylistProgramView extends View {
  item: any;
  constructor(item: any) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemProgramVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemProgramViewBoxZoom");
    this.item = item;
    const elementImg = DOMHelper.createDivImg(
      box,
      null,
      "elementImg",
      require("~images/fallbacks/fallback-vignette.png")
    );
    const titleElt = DOMHelper.createDivWithParent(elementImg, null, "titleProgramFallback", item.title);
    const imgUrl = this.item.getLandscapeTileImgUrl();
    if (imgUrl.length) {
      checkImage(
        imgUrl,
        () => {
          elementImg.removeChild(titleElt);
          elementImg.style.backgroundImage = `url("${imgUrl}")`;
        },
        () => {}
      );
    }

    const channelPicto = getChannelPicto(item, true);
    if (channelPicto) {
      DOMHelper.createImg(elementImg, null, "infoChannelIcon", channelPicto, "");
    }

    item.sponsored && DOMHelper.createSpanWithParent(elementImg, null, "sponsoredTag tile_16_9", "sponsorisé");
  }
  onFocused = () => {};
}
