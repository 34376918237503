import "./accountCardView.scss";

import { CGU } from "~models/cgu";
import { DOMHelper, View } from "~ui-lib";

export class accountView extends View {
  constructor(item: CGU) {
    super(DOMHelper.createDivWithParent(null, null, "accountCardview"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "accountCardviewBoxZoom");
    DOMHelper.createDivWithParent(box, null, "buttonsText", item.title);
    DOMHelper.createDivImg(box, null, "accountTabTextBoxIcon");
  }
}
