import { Player } from "~libs/player";
import { DOMHelper } from "~ui-lib";

import { IYouboraAdapter, YouboraManager } from "../../tools/analytics/youbora";

export class PlayerHTML5 extends Player {
  protected _youboraManager: YouboraManager<IYouboraAdapter>;

  constructor(initializeYoubora: boolean) {
    super(initializeYoubora);

    this._youboraManager = new YouboraManager(this);
  }

  init(): void {
    Log.player.log("[HTML5] init");
    this.videoElement = DOMHelper.createElementWithParent("video", this.DOMElement, "playerVideo");
    this.sourceElement = DOMHelper.createSourceWithParent(this.videoElement);

    super.init();
  }

  /**
   * Init listeners
   */

  initListener(): void {
    const that = this;

    this.videoElement.ontimeupdate = () => {
      that.currentTime$.value = (that.videoElement as HTMLMediaElement).currentTime;

      if (that.duration$.value <= 0) {
        that.duration$.value = ~~(this.videoElement?.duration ? this.videoElement?.duration : 0);
      }

      // Ad Controller update
      that.onTimeUpdate(that.position(), that.duration() - that.position(), that.url$.value);
    };
    this.videoElement.onplaying = () => {
      that.onEventPlay();
    };
    this.videoElement.onpause = () => {
      that.onEventPause();
    };
    this.videoElement.onloadstart = () => {
      that.onEventConnect();
    };
    this.videoElement.onloadedmetadata = () => {};
    this.videoElement.onended = () => {
      that.onEventFinish();
    };
    (this.sourceElement as HTMLElement).onerror = e => {
      that.onEventError(e);
    };

    super.initListener();
  }

  /**
   * load source url and play
   */
  load(url: string, _playready?: boolean): void {
    if (url === undefined) return;
    this.videoElement?.removeAttribute("src");

    this.videoElement?.setAttribute("preload", "auto");
    this.videoElement?.setAttribute("src", url);

    this.sourceElement?.setAttribute("type", "video/mp4");
    this.sourceElement?.setAttribute("src", url);

    this.launchPlay();
  }

  launchPlay(): void {
    if (this.shouldPlayVideo()) {
      this.play();
    }
  }

  /**
   * Play
   */
  play(): void {
    const playPromise = (this.videoElement as HTMLMediaElement).play();

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(e => {
          // Auto-play was prevented
          // Show paused UI.
          Log.player.error("[HTML5][playPromise] ERROR :: " + e.message);
        });
    }
  }

  /**
   * Pause
   */
  pause(): void {
    if (this.shouldPauseVideo()) {
      (this.videoElement as HTMLMediaElement).pause();
    }
  }

  /**
   * Stop
   */
  stop(): void {
    (this.videoElement as HTMLMediaElement).src = "";
  }

  /**
   * Seek
   */
  seek(): void {
    if (this.shouldSeekVideo()) {
      this.onEventSeeking();

      const posSeek = this.position();
      (this.videoElement as HTMLMediaElement).currentTime = posSeek;

      this.play();
    }
  }

  /**
   * Deinit player
   */

  release(): void {
    super.release();

    this.videoElement?.remove();
    this.sourceElement?.remove();
  }

  getVersion = () => {
    return "HTML5";
  };
}
