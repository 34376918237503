import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { onSelectTile } from "~pages/rootPage";
import { HeroLandingZone } from "~swimlaneViews/heroLandingZone";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { PlaylistMixedView } from "~views/playlistMixedView";

export class PlaylistMixedSwimlane extends View {
  cacheable = true;
  private _heroZoneElt: HeroLandingZone;
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneMixedVignette", "list"));
    this._heroZoneElt = new HeroLandingZone(this.rootElement, source);

    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Program | Unit)[]),
      viewFactory: model => {
        const view = new PlaylistMixedView(model);
        view.onFocused = () => {
          this._heroZoneElt.updateItem(model).then();
        };
        return view;
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: onSelectTile,
    });
  }
}
