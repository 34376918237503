import { Item } from "~models/item";
import { IModel } from "~ui-lib";

export enum CGUType {
  cgu = "CGU",
  mentionsLegales = "Mentions légales",
  cmp = "Gérer mes consentements",
}

export class CGU extends Item implements IModel {
  constructor(id: string, type: CGUType, title: string) {
    super(id, type, title);
  }
}
