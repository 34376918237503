class BaseException {
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export class UnknownException extends BaseException {
  constructor(message: string) {
    super(message);
  }
}

export class ParseException extends BaseException {
  constructor(message: string) {
    super(message);
  }
}

export class NotImplementedException extends BaseException {
  constructor(message: string) {
    super(message);
  }
}

export class BackendErrorException extends BaseException {
  requestId: string;
  backendCode: string;
  url: string;
  status: number | undefined;

  constructor(message: string, requestId: string, backendCode: string, url: string, status: number | undefined) {
    super(message);
    this.requestId = requestId;
    this.backendCode = backendCode;
    this.url = url;
    this.status = status;
  }
}
