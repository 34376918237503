import "./keyComponent.scss";

import {
  createListComponent,
  createSwitchComponent,
  DOMHelper,
  IListComponent,
  ISwitchComponent,
  Listenable,
  StaticModelSource,
  View,
} from "../../../bud-lite-tv/src/lib";

enum KeyboardType {
  Lowercase = "Lowercase",
  Digits = "Digits",
  Uppercase = "Uppercase",
}

enum SpecialKey {
  Space = "Space",
  Digits = "Digits",
  Lowercase = "Lowercase",
  Delete = "Delete",
  Uppercase = "Uppercase",
}

type Keyboard = {
  id: KeyboardType;
};

const keyboardList: Keyboard[] = [
  {
    id: KeyboardType.Lowercase,
  },
  {
    id: KeyboardType.Digits,
  },
  {
    id: KeyboardType.Uppercase,
  },
];
class KeyView extends View {
  constructor(id: string) {
    super(DOMHelper.createDivWithParent(null, null, id.length > 1 ? "specialKeyBox" : "keyBox"));
    const isUppercaseLetter = id.length === 1 && "ABCDEFGHIJKLMNOPQRSTUVWXYZ".includes(id);
    DOMHelper.createDivWithParent(
      this.rootElement,
      `${id}_key`,
      "key" + (isUppercaseLetter ? " uppercase" : ""),
      id.length > 1 ? null : id
    );
  }
}

export class KeyboardView extends View {
  listOfKeysComponent: IListComponent;
  textToSearch$: Listenable<string>;
  keyboardsSwitch?: ISwitchComponent<Keyboard>;
  constructor(textToSearch$: Listenable<string>, keyboardType: KeyboardType) {
    super(DOMHelper.createDivWithParent(null, "Keyboard" + keyboardType, "keyboardView"));
    this.textToSearch$ = textToSearch$;
    let alphabetLine1: string[];
    let alphabetLine2: string[];

    switch (keyboardType) {
      case KeyboardType.Lowercase:
        alphabetLine1 = "abcdefghijklm".split("");
        alphabetLine1.unshift(SpecialKey.Uppercase);
        alphabetLine1.push(SpecialKey.Delete);
        alphabetLine2 = "nopqrstuvwxyz".split("");
        alphabetLine2.unshift(SpecialKey.Digits);
        alphabetLine2.push(SpecialKey.Space);
        break;
      case KeyboardType.Digits:
        alphabetLine1 = "0123456789@!?".split("");
        alphabetLine1.unshift(SpecialKey.Lowercase);
        alphabetLine1.push(SpecialKey.Delete);
        alphabetLine2 = "#,.:_-/%&()<>".split("");
        alphabetLine2.unshift(SpecialKey.Uppercase);
        alphabetLine2.push(SpecialKey.Space);
        break;
      case KeyboardType.Uppercase:
        alphabetLine1 = "ABCDEFGHIJKLM".split("");
        alphabetLine1.unshift(SpecialKey.Lowercase);
        alphabetLine1.push(SpecialKey.Delete);
        alphabetLine2 = "NOPQRSTUVWXYZ".split("");
        alphabetLine2.unshift(SpecialKey.Digits);
        alphabetLine2.push(SpecialKey.Space);
        break;
    }
    const alphabet = alphabetLine1.concat(alphabetLine2);

    this.listOfKeysComponent = this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource(alphabet),
        viewFactory: id => {
          return new KeyView(id);
        },
        onSelect: this.onContentSelect,
        horizontal: false,
        pageSize: 2,
        visibleBefore: 1,
        visibleAfter: 1,
        crossSectionWidth: 15,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  onFocused = () => {
    this.listOfKeysComponent.setFocusOnIndex(0);
  };

  onContentSelect = (model: string | SpecialKey): boolean => {
    console.log("onContentSelect lowercaseKeyboard", model);

    switch (model) {
      case SpecialKey.Space:
        this.textToSearch$.value = this.textToSearch$.value + " ";
        return true;

      case SpecialKey.Delete:
        this.textToSearch$.value = this.textToSearch$.value.slice(0, -1);
        return true;

      case SpecialKey.Uppercase:
        keyboardsSwitch.show(KeyboardType.Uppercase);
        return true;

      case SpecialKey.Lowercase:
        keyboardsSwitch.show(KeyboardType.Lowercase);
        return true;

      case SpecialKey.Digits:
        keyboardsSwitch.show(KeyboardType.Digits);
        return true;

      default:
        this.textToSearch$.value = this.textToSearch$.value + model;
        return true;
    }
  };
}

enum TopLevelID {
  keyboardsSwitch = "keyboardsSwitch",
}

let keyboardsSwitch: ISwitchComponent<Keyboard>;

export class KeyComponent extends View {
  keyboardsSwitch: ISwitchComponent<Keyboard>;
  topVerticalList: IListComponent<TopLevelID>;
  lowercaseKeyboard?: KeyboardView;
  digitKeyboard?: KeyboardView;
  UppercaseKeyboard?: KeyboardView;
  updateFocusUnregister?: () => void;
  textToSearch$: Listenable<string>;

  constructor(textToSearch$: Listenable<string>) {
    super(DOMHelper.createDivWithParent(null, "KeyboardComponent", "KeyboardComponent"));
    this.textToSearch$ = textToSearch$;

    this.keyboardsSwitch = createSwitchComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "keyboardContainer"),
        model: keyboardList,
        initialViewId: KeyboardType.Lowercase,
        viewFactory: model => {
          switch (model.id) {
            case KeyboardType.Lowercase:
              return (this.lowercaseKeyboard = new KeyboardView(this.textToSearch$, model.id));

            case KeyboardType.Uppercase:
              return (this.UppercaseKeyboard = new KeyboardView(this.textToSearch$, model.id));

            default:
              return (this.digitKeyboard = new KeyboardView(this.textToSearch$, model.id));
          }
        },
      },
      switchComponent => {
        switchComponent.show(KeyboardType.Lowercase);
        keyboardsSwitch = switchComponent;
      }
    );

    this.topVerticalList = this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource([TopLevelID.keyboardsSwitch]),
        viewFactory: item => {
          return this.keyboardsSwitch;
        },
        horizontal: false,
      },
      list => {
        this.updateFocusUnregister = list.focusedId$.didChange(id => {
          if (id === TopLevelID.keyboardsSwitch) {
            this.lowercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
            this.digitKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
            this.UppercaseKeyboard?.listOfKeysComponent.setFocusOnIndex(1);
          }
        });
      }
    );
  }

  onRelease = (): void => {
    if (this.updateFocusUnregister) this.updateFocusUnregister();
  };
}
