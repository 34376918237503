import { Category } from "~models/category";
import { ItemCollection } from "~models/itemCollection";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { CategoryView } from "~views/categoryView";

export class CategorySwimlane extends View {
  title?: HTMLElement;

  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, null, "categorySwimlane", null));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as Category[]),
      viewFactory: model => {
        const view = new CategoryView(model);
        return view;
      },
      horizontal: false,
      pageSize: 3,
      visibleBefore: 1,
      visibleAfter: 1,
      crossSectionWidth: 4,
      spatialFocus: true,
      onSelect: onSelectTile,
    });
  }
}
