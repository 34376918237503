import "./channelSwimlane.scss";

import { Channel } from "~models/channel";
import { ItemCollection } from "~models/itemCollection";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { ChannelItem } from "~views/swimlanesItems/channelItem";

import { Plugin } from "../../datas/plugin";
import { Region } from "../../models/region";

export class ChannelSwimlane extends View {
  cacheable = true;
  title?: HTMLElement;

  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, null, "channelSwimlane", null));
    this.title = DOMHelper.createDivWithParent(this.rootElement, null, "channelSwimlaneTitleChannel", "");
    DOMHelper.createDivWithParent(this.rootElement, null, "channelSwimlaneTitle", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource<Channel | Region>(source.items),
      viewFactory: model => {
        const view = new ChannelItem(model);
        view.onFocused = () => {
          this.itemDetail(model);
        };
        return view;
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      spatialFocus: false,
      onSelect: (model, index) => {
        if (typeof model.extras?.si_id === "string") {
          Plugin.getInstance()
            .fetchCurrentDirect(model.extras?.channel_url)
            .toPromise()
            .then(json => {
              onSelectTile(json, index);
            })
            .catch(e => {
              Log.app.error("FetchCurrentDirect error :", e);
            });
          return true;
        } else {
          return onSelectTile(model, index);
        }
      },
    });
  }

  itemDetail(model: Channel) {
    if (this.title) {
      this.title.textContent = model.title;
    }
  }
}
