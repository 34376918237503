/* eslint-disable @typescript-eslint/no-explicit-any */
import { Artwork } from "~models/artwork";
import { IModel } from "~ui-lib";

import { BrowsableItem } from "./browsableItem";

export class Partner extends BrowsableItem implements IModel {
  constructor(id: string, type: string, title: string, summary: any, artworks: Array<Artwork>, extras: any) {
    super(id, type, title, summary, artworks, extras);
  }
}
