import {
  AVPlayDrmType,
  AVPlayError,
  AVPlayEvent,
  AVPlayPlaybackCallback,
  AVPlayStreamInfo,
  AVPlayStreamingPropertyType,
  AVPlaySubtitleAttribute,
  ErrorCallback,
  SuccessCallback,
} from "tizen-tv-webapis";

import { Player } from "~libs/player";
import { DOMHelper } from "~ui-lib";

import { IYouboraSamsungAdapter, YouboraManager, YouboraVideoParams } from "../../tools/analytics/youbora";

export class PlayerTizen extends Player {
  protected _youboraManager: YouboraManager<IYouboraSamsungAdapter>;
  constructor(initializeYoubora: boolean) {
    super(initializeYoubora);

    this._youboraManager = new YouboraManager<IYouboraSamsungAdapter>(this);
  }

  init(): void {
    Log.player.log("[TIZEN] init");
    this.videoElement = DOMHelper.createElementWithParent("object", this.DOMElement, "playerVideo");

    super.init();
  }

  /**
   * Init listeners
   */
  initListener = (): void => {
    const that = this;

    const listeners: AVPlayPlaybackCallback = {
      onevent: function (eventType: AVPlayEvent, eventData: string) {
        Log.player.log("[TIZEN][onevent] type: " + eventType + ", data: " + eventData);
      },
      oncurrentplaytime: function (currentTime: number) {
        Log.player.log("[TIZEN][oncurrentplaytime] " + currentTime);

        that._youboraManager.getAdapter()?.fireInit();
        that._youboraManager.getAdapter()?.oncurrentplaytime();

        const oldCurrentTime = that.currentTime$.value;
        that.currentTime$.value = ~~(currentTime / 1000);

        const duration = window.webapis.avplay.getDuration();
        that.duration$.value = ~~((duration ? duration : 0) / 1000);

        // Ad Controller update
        that.onTimeUpdate(that.position(), that.duration() - that.position(), that.url$.value);

        if (
          that.isPlaying$.value &&
          that.currentTime$.value === that.duration$.value &&
          that.currentTime$.value === oldCurrentTime
        ) {
          listeners.onstreamcompleted?.();
        }
      },
      onstreamcompleted: function () {
        that.onEventFinish();

        that._youboraManager.getAdapter()?.onstreamcompleted();
      },
      onerror: function (e: AVPlayError) {
        that.onEventError(e);

        // if (typeof window.plugin !== "undefined") {
        //   window.plugin.getAdapter().onerror();
        // }
      },
      onbufferingstart: function () {
        that.onEventBuffer();
      },
      onbufferingprogress: function () {
        Log.player.log("[TIZEN][onbufferingprogress]");
      },
      onbufferingcomplete: function () {
        Log.player.log("[TIZEN][onbufferingcomplete]");
        that._youboraManager.getAdapter()?.onbufferingcomplete();
      },
      // eslint-disable-next-line @typescript-eslint/ban-types
      ondrmevent: function (drmEvent: AVPlayDrmType, drmData: object) {
        that.onEventDRM(drmEvent, drmData);
      },
      onsubtitlechange: function (
        _duration: number,
        _subtitles: string,
        _type: number,
        _attributes: AVPlaySubtitleAttribute
      ) {},
    };

    try {
      window.webapis.avplay.setListener(listeners);
    } catch (e) {
      Log.player.error("[TIZEN][setListener] error :: " + e.message);
    }

    super.initListener();
  };

  /**
   * load source url and play
   */
  load(url: string, playready?: boolean): void {
    if (url === undefined) return;
    this.videoElement?.setAttribute("type", "application/avplayer");
    this.disableScreenSaver();

    if (playready) {
      // TODO: implement customData to get licence for DRM
    } else {
      try {
        if (["NONE"].indexOf(window.webapis.avplay.getState()) === -1) {
          window.webapis.avplay.close();
        }
        window.webapis.avplay.open(url);
      } catch (e) {
        Log.player.error("[TIZEN][avplay][open] error :: " + e.message);

        if (e.message === "PLAYER_ERROR_INVALID_OPERATION") {
          try {
            window.webapis.avplay.restore(url);
          } catch (e) {
            Log.player.error("[TIZEN][avplay][restore] error :: " + e.message);
          }
        }
      }
    }

    this.setFullscreen();
    this.launchPlay();
  }

  launchPlay(): void {
    if (this.shouldPlayVideo()) {
      const that = this;

      try {
        if (["IDLE", "READY"].indexOf(window.webapis.avplay.getState()) > -1) {
          // limit resolution test for jo stream
          this.setStreamingProperty("ADAPTIVE_INFO", "BITRATES=1150600~5605600");

          that.prepareAsync(
            function () {
              if (["PLAYING"].indexOf(window.webapis.avplay.getState()) < 0) {
                window.webapis.avplay.play();
                that.disableScreenSaver();
                that.onEventPlay();
                that.isSeeking$.value = false;
              }
            },
            function () {
              that.isSeeking$.value = false;
            }
          );
        } else {
          if (["PLAYING"].indexOf(window.webapis.avplay.getState()) < 0) {
            window.webapis.avplay.play();
            that.disableScreenSaver();
            that.onEventPlay();
            that.isSeeking$.value = false;
          }
        }
      } catch (e) {
        Log.player.error("[TIZEN][launchPlayTizen] error :: " + e.message);
      }
    }
  }

  /**
   * Play
   */
  play(): void {
    try {
      if (["PLAYING"].indexOf(window.webapis.avplay.getState()) < 0) {
        window.webapis.avplay.play();

        this._youboraManager.getAdapter()?.fireResume();

        this.onEventPlay();
      }
    } catch (e) {
      Log.player.error("[TIZEN][play] ERROR : " + e.message);
    }
  }

  /**
   * Pause
   */
  pause(): void {
    Log.player.log("[TIZEN][Pause] Enter in player.pause method, player.state", this.state$.value);
    if (this.shouldPauseVideo()) {
      try {
        if (["PLAYING"].indexOf(window.webapis.avplay.getState()) > -1) {
          Log.player.log("[TIZEN][Pause]");
          window.webapis.avplay.pause();

          this._youboraManager.getAdapter()?.firePause();

          this.onEventPause();
        }
      } catch (e) {
        Log.player.error("[TIZEN][pause] ERROR : " + e.message);
      }
    }
  }

  /**
   * Stop
   */
  stop(): void {
    try {
      if (["IDLE", "READY", "PAUSE", "PAUSED", "PLAYING"].indexOf(window.webapis.avplay.getState()) > -1) {
        this._youboraManager.getAdapter()?.fireStop();

        window.webapis.avplay.stop();
      }
    } catch (e) {
      Log.player.error("[tizen][stop] ERROR : " + e.message);
    }
    this.enableScreenSaver();
    this.onEventStop();
  }

  /**
   * Seek
   */
  seek(): void {
    if (this.shouldSeekVideo()) {
      try {
        const that = this;

        this.onEventSeeking();
        this.isSeeking$.value = true;

        that._youboraManager.getAdapter()?.fireSeekBegin();

        if (["IDLE", "READY", "PAUSE", "PAUSED", "PLAYING"].indexOf(window.webapis.avplay.getState()) > -1) {
          const posSeek = this.currentTime$.value * 1000;

          window.webapis.avplay.seekTo(
            posSeek,
            function () {
              that._youboraManager.getAdapter()?.fireSeekEnd();

              if (["PLAYING"].indexOf(window.webapis.avplay.getState()) < 0) {
                that.play();
              }
              that.isSeeking$.value = false;
            },
            function () {
              that.onEventStop();
              that.isSeeking$.value = false;
            }
          );
        } else {
          if (["PLAYING"].indexOf(window.webapis.avplay.getState()) < 0) {
            that.play();
          }
          that.isSeeking$.value = false;
        }
      } catch (e) {
        Log.player.error("[TIZEN][seekTo] ERROR : " + e.message);
      }
    }
  }

  /**
   * Deinit player
   */

  release(): void {
    super.release();

    try {
      if (["NONE", "IDLE", "READY", "PAUSED", "PAUSE", "PLAYING"].indexOf(window.webapis.avplay.getState()) > -1) {
        window.webapis.avplay.close();
      } else {
        Log.player.log("[TIZEN][avplay][close] state = " + window.webapis.avplay.getState());
      }
    } catch (e) {
      Log.player.error("[TIZEN][avplay][close] error = " + e.message);
    }

    this.videoElement?.remove();
  }

  prepareAsync = (onsuccess?: SuccessCallback, onerror?: ErrorCallback): void => {
    try {
      window.webapis.avplay.prepareAsync(onsuccess, onerror);
    } catch (e) {
      Log.player.error("[TIZEN][prepareAsync] ERROR : " + e.message);
    }
  };
  disableScreenSaver = (): void => {
    try {
      window.webapis.appcommon.setScreenSaver(window.webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_OFF);
    } catch (e) {
      Log.player.error("[TIZEN][disableScreenSaver] ERROR : " + e.message);
    }
  };

  enableScreenSaver = (): void => {
    try {
      window.webapis.appcommon.setScreenSaver(window.webapis.appcommon.AppCommonScreenSaverState.SCREEN_SAVER_ON);
    } catch (e) {
      Log.player.error("[TIZEN][enableScreenSaver] ERROR : " + e.message);
    }
  };

  setStreamingProperty = (propertyType: AVPlayStreamingPropertyType, propertyParam: string): void => {
    try {
      window.webapis.avplay.setStreamingProperty(propertyType, propertyParam);
    } catch (e) {
      Log.player.error("[TIZEN][setStreamingProperty] error = " + e.message);
    }
  };

  setFullscreen = (): void => {
    try {
      // 720p
      window.webapis.avplay.setDisplayRect(0, 0, 1920, 1080);
      window.webapis.avplay.setDisplayMethod("PLAYER_DISPLAY_MODE_FULL_SCREEN");
    } catch (e) {
      Log.player.error("[TIZEN][setFullscreen] ERROR : " + e.message);
    }
  };

  additionalYouboraParams(): Partial<YouboraVideoParams> {
    /**
     * On Samsung, we can detech some information regarding codecs
     */

    try {
      /**
       * .getCurrentStreamInfo() is typed as AVPlayStreamInfo, on Samsung TV this property is returning an array of AVPlayStreamInfo
       */
      const streamInfoArray = (webapis.avplay.getCurrentStreamInfo() as unknown) as AVPlayStreamInfo[];
      const res: Partial<YouboraVideoParams> = {};

      for (const streamInfo of streamInfoArray) {
        const extra = JSON.parse(streamInfo.extra_info);

        switch (streamInfo.type) {
          case "AUDIO": {
            if (typeof extra?.fourCC === "string") {
              res["content.encoding.audioCodec"] = extra.fourCC;
            }

            break;
          }
          case "VIDEO": {
            if (typeof extra?.fourCC === "string") {
              res["content.encoding.videoCodec"] = extra.fourCC;
            }

            break;
          }
        }
      }

      return res;
    } catch (e) {
      Log.player.error("[TIZEN][additionalYouboraParams]", e);
      return {};
    }
  }

  private _playerVersion = window?.webapis?.avplay?.getVersion?.() ?? "Tizen";

  getVersion = () => {
    return this._playerVersion;
  };
}
