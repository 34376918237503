import "./playButton.scss";

import { convertDuration } from "~tools/convert";
import { DOMHelper, View } from "~ui-lib";

export class PlayButton extends View {
  private _titleElement;
  private _subtitleElement;
  private _progressBarElement;
  private _currentProgressElement;
  private _durationElement;

  constructor() {
    super(DOMHelper.createDivWithParent(null, null, "playButton"));
    const button = DOMHelper.createDivWithParent(
      DOMHelper.createDivWithParent(this.rootElement, null, "playButtonFocusBorder"),
      null,
      "playButtonContainer"
    );
    DOMHelper.createDivImg(button, null, "playButtonIcon", require("~images/buttonProgram/play.png"));

    const textContainerElement = DOMHelper.createDivWithParent(button, null, "playButtonTextContainer");
    this._titleElement = DOMHelper.createDivWithParent(textContainerElement, null, "playButtonTitle");
    this._subtitleElement = DOMHelper.createDivWithParent(textContainerElement, null, "playButtonSubtitle");
    const progressBarAndDurationContainer = DOMHelper.createDivWithParent(
      textContainerElement,
      null,
      "progressBarAndDurationContainer"
    );

    this._progressBarElement = DOMHelper.createDivWithParent(
      progressBarAndDurationContainer,
      null,
      "playButtonProgressBar"
    );
    this._currentProgressElement = DOMHelper.createDivWithParent(
      this._progressBarElement,
      null,
      "playButtonCurrentProgression"
    );
    DOMHelper.createDivWithParent(this._currentProgressElement, null, "playButtonCurrentProgressionCursor");

    this._durationElement = DOMHelper.createDivWithParent(
      progressBarAndDurationContainer,
      null,
      "playButtonContentDuration"
    );
  }

  updateButton(
    title: string,
    subtitle: string,
    durationOrRemain: { duration: number } | { duration: undefined; remaining_duration: number; percentage: number }
  ) {
    this._subtitleElement.innerText = subtitle;
    if (durationOrRemain.duration === undefined) {
      this._titleElement.innerText = "Reprendre";
      this._progressBarElement.style.display = "inline-block";
      this._currentProgressElement.style.width = durationOrRemain.percentage * 100 + "%";
      this._durationElement.classList.add("short");
      const remainingDurationString = convertDuration(durationOrRemain.remaining_duration);
      this._durationElement.innerText =
        remainingDurationString +
        " restante" +
        (remainingDurationString === "1 h " || remainingDurationString === "1 min" ? "" : "s");
    } else {
      this._titleElement.innerText = title.charAt(0).toUpperCase() + title.slice(1);
      this._progressBarElement.style.display = "none";
      this._durationElement.classList.remove("short");
      this._durationElement.innerText = convertDuration(durationOrRemain.duration);
    }
  }
}
