import "./connexionPage.scss";

import { Player, PlayerState } from "~libs/player";
import { PlayerHTML5 } from "~libs/players/playerHTML5";
import { PlayerTizen } from "~libs/players/playerTizen";
import {
  createListComponent,
  DOMHelper,
  IListComponent,
  IPage,
  Keys,
  platform,
  PlatformType,
  StaticModelSource,
  View,
} from "~ui-lib";

import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { MainMenuItemSlug } from "../../views/mainMenu/itemMenu";
import { HomeTab } from "../home/homeTab";
import { InscriptionPage } from "../inscription/inscription";
import { pushTabWithMenu } from "../rootPage";

enum ButtonConnexionType {
  registered = "Je m’inscris",
  connect = "Je me connecte",
  notNow = "Pas maintenant",
}

class ButtonView extends View {
  constructor(title: ButtonConnexionType) {
    super(DOMHelper.createDivWithParent(null, title, "ButtonView"));
    const box = DOMHelper.createDivWithParent(
      this.rootElement,
      null,
      "ButtonBoxZoom" + (title == ButtonConnexionType.notNow ? " notNow" : "")
    );
    DOMHelper.createDivWithParent(box, null, "ButtonText", title);
  }
}

// console.log(
//   "[eStat midroll]",
//   "\nthis.eStatData",
//   this.eStatData,
//   "\nthis.player.state$",
//   this.player.state$,
//   "\nthis.player.currentTime$.value",
//   this.player.currentTime$.value,
//   "\nthis.item.id",
//   this.item.id,
//   "\nthis.player.adPlaying$",
//   this.player.adPlaying$
// );
export let currentPlayer: Player | undefined = undefined;

export class ConnexionPage extends View implements IPage {
  list: IListComponent | undefined;
  player: Player;
  playerStateUnregister: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eStatData: any;
  url: string;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "ConnexionPage", "connexionPage"));
    this.url = Plugin.getInstance().connexionURLs.background;

    // Active video defined in application background
    document.getElementById("videoBackground")?.classList.add("active");

    this.player = platform.type == PlatformType.tizen ? new PlayerTizen(false) : new PlayerHTML5(false);
    currentPlayer = this.player;

    this.playerStateUnregister = this.player.state$.didChange(state => {
      switch (state) {
        case PlayerState.BUFFERING:
          break;
        case PlayerState.ENDED:
          this.player.setSrc(this.url);
          break;
        case PlayerState.ERROR:
          break;
        case PlayerState.IDLE:
          break;
        case PlayerState.PAUSED:
          break;
        case PlayerState.PLAYING:
          break;
        case PlayerState.PREPARING:
          break;
        case PlayerState.UNSET:
          break;
        case PlayerState.SEEKING:
          break;
        default:
          console.warn("Unknown player state :: ", state);
          break;
      }
    });

    try {
      this.player.init();
      this.player.setSrc(this.url);
    } catch (error) {
      Log.player.error(`init failed: ${error}`);
    }
  }

  init = (): void => {
    this.createListPlayer();
  };

  createListPlayer() {
    const uiContainer = DOMHelper.createDivWithParent(this.rootElement, "ConnexionUiContainer", "connexionUiContainer");
    DOMHelper.createDivWithParent(
      uiContainer,
      null,
      "connexionTitle",
      "Connectez-vous pour retrouver vos contenus France.tv"
    );
    DOMHelper.createDivWithParent(uiContainer, null, "connexionSubtitle", "C’est facile et gratuit !");
    this.delegate = this.list = createListComponent(
      {
        rootElement: uiContainer,
        modelSource: new StaticModelSource([
          ButtonConnexionType.registered,
          ButtonConnexionType.connect,
          ButtonConnexionType.notNow,
        ]),
        viewFactory: model => {
          return new ButtonView(model);
        },
        pageSize: 3,
        visibleAfter: 1,
        mouseFocusInPageOnly: true,
        horizontal: false,
        onSelect: this.onSelect,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  onSelect = (model: ButtonConnexionType): boolean => {
    switch (model) {
      case ButtonConnexionType.registered:
        this.player.pause();
        navigationStack.pushPage(new InscriptionPage(false));
        return true;
      case ButtonConnexionType.connect:
        this.player.pause();
        navigationStack.pushPage(new InscriptionPage(true));
        return true;
      case ButtonConnexionType.notNow:
        this.player.pause();
        pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
        navigationStack.removePage(this);
        return true;
    }
    return false;
  };

  onShown() {
    if (!Plugin.getInstance().isLaunched) {
      Plugin.getInstance().isLaunched = true;
    }
    sendPianoAnalytic("page.display", { page: "accueil_connexion", page_type: "connexion" }, {});
  }

  loadSrc = (url: string): void => {
    setTimeout(() => {
      this.player.setSrc(url);
    }, 10);
  };

  onRelease = () => {
    this.player.release();
    this.playerStateUnregister();
    document.getElementById("videoBackground")?.classList.remove("active");
    //this.loader.onRelease();
    currentPlayer = undefined;
  };

  exitPlayer = () => {
    navigationStack.removePage(this);
  };

  onNav = (key: Keys): boolean => {
    return false;
  };
}
