/* eslint-disable @typescript-eslint/no-explicit-any */
import { Artwork } from "~models/artwork";
import { BrowsableItem } from "~models/browsableItem";
import { IModel } from "~ui-lib";

export class Tag extends BrowsableItem implements IModel {
  constructor(id: string, type: string, label: string, description: string, artworks: Array<Artwork>, extras: any) {
    super(id, type, label, description, artworks, extras);
  }
}
