import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Unit } from "~models/unit";
import { Plugin } from "~pages/../datas/plugin";
import { LiveTileView } from "~pages/lives/liveTile";
import { onSelectTile, pushPlayerPage } from "~pages/rootPage";
import { HeroLandingZone } from "~swimlaneViews/heroLandingZone";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { PlaylistVideoView } from "~views/playlistVideoView";

export class PlaylistVideoSwimlane extends View {
  cacheable = true;
  private _list: IListComponent;
  private readonly _focusedIdUnregister;
  private _heroZoneElt: HeroLandingZone;
  PlaylistVideoView: PlaylistVideoView | null = null;
  constructor(source: ItemCollection, showMeta = false) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneVideoVignette", "list"));
    this._heroZoneElt = new HeroLandingZone(this.rootElement, source);

    this.delegate = this._list = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Unit | Integrale)[]),
      viewFactory: model => {
        if (model.metadata.extras && model.metadata.extras.is_live) {
          return new LiveTileView(model, false);
        } else {
          this.PlaylistVideoView = new PlaylistVideoView(model, showMeta);
          return this.PlaylistVideoView;
        }
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: this._onItemSelect,
    });
    this._focusedIdUnregister = this._list.focusedId$.didChange(newId => {
      this._heroZoneElt.updateItem(this._list.modelFromId(newId)).then();
    });
  }

  private _onItemSelect = (item: Unit | Integrale, index: number) => {
    if (item instanceof Unit) {
      onSelectTile(item, index);
    } else {
      this._hasContent(item, index);
    }
    return true;
  };

  private _hasContent = (current: Unit | Integrale, index: number): void => {
    Plugin.getInstance()
      .fetchNextEpisodes(current)
      .subscribe(
        (value: any) => {
          // Here use it to create the UI
          Log.app.log("[hasContent] fetch from !", current);
          Log.app.log("[hasContent] Next !", value);
          if (value[0]?.items && !current.metadata.extras.is_live) {
            value[0].items.unshift(current);
          }
          let progress: number | undefined;
          const focusedId = this._list.focusedId$.value;
          if (focusedId) {
            const view = this._list.viewFromId(focusedId);
            if (view instanceof PlaylistVideoView) progress = view?.getProgressPercent();
          }
          pushPlayerPage(current, index, progress, value);
        },
        (error: any) => {
          // If fetchNextEpisodes return nothing we have a error, but we still want launch the player
          // TODO : may be fetchNextEpisodes should not raise a error, to investigate
          Log.app.error("[hasContent] Error !", error);
          let progress: number | undefined;
          const focusedId = this._list.focusedId$.value;
          if (focusedId) {
            const view = this._list.viewFromId(focusedId);
            if (view instanceof PlaylistVideoView) progress = view.getProgressPercent();
          }
          pushPlayerPage(current, index, progress);
        },
        () => {
          Log.app.log("[hasContent] Complete !");
        }
      );
  };

  onRelease = () => {
    this._focusedIdUnregister();
  };
}
