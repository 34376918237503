import "./partnerSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { Partner } from "../../models/partner";
import { PartnerItem } from "../swimlanesItems/partnerItem";

export class PartnerSwimlane extends View {
  cacheable = true;
  title?: HTMLElement;

  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, null, "partnerSwimlane", null));
    this.title = DOMHelper.createDivWithParent(this.rootElement, null, "partnerSwimlaneTitlePartner", "");
    DOMHelper.createDivWithParent(this.rootElement, null, "partnerSwimlaneTitle", source.title);
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource<Partner>(source.items),
      viewFactory: model => {
        const view = new PartnerItem(model);
        view.onFocused = () => {
          this.itemDetail(model);
        };
        return view;
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      spatialFocus: false,
      onSelect: (model, index) => {
        return onSelectTile(model, index);
      },
    });
  }

  itemDetail(model: Partner) {
    if (this.title) {
      this.title.textContent = model.title;
    }
  }
}
