import "./MEABannerView.scss";

import { onSelectTile } from "~pages/rootPage";

import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "../../bud-lite-tv/src/lib";
import { Collection } from "../models/collection";
import { Event } from "../models/event";
import { Extrait } from "../models/extrait";
import { Flux } from "../models/flux";
import { Integrale } from "../models/integrale";
import { Partner } from "../models/partner";
import { Program } from "../models/program";
import { Unit } from "../models/unit";
import { getChannelPicto } from "../tools/getChannelPics";
import { checkImage } from "../tools/tools";

class BannerButtonView extends View {
  private _buttonLabel: string;
  private _playIconElement?: HTMLElement;

  constructor(item: Integrale | Program | Unit | Collection | Event | Extrait | Flux) {
    super(DOMHelper.createDivWithParent(null, null, "bannerButton"));
    if (item instanceof Integrale || item instanceof Unit || item instanceof Extrait || item instanceof Flux) {
      if (item.metadata?.extras?.is_live) {
        this._playIconElement = DOMHelper.createDivWithParent(this.rootElement, null, "playIcon");
        this._playIconElement.style.backgroundImage = `url("${require("~images/meaBanner/playIconWhite.png")}")`;
        this._buttonLabel = "voir le direct";
        this.rootElement.classList.add("live");
      } else {
        this._playIconElement = DOMHelper.createDivWithParent(this.rootElement, null, "playIcon");
        this._playIconElement.style.backgroundImage = `url("${require("~images/meaBanner/playIcon.png")}")`;
        this._buttonLabel = "regarder";
      }
    } else if (item instanceof Collection) {
      this._buttonLabel = "découvrir la collection";
    } else {
      this._buttonLabel = "découvrir";
    }
    DOMHelper.createTextWithParent(this.rootElement, null, "buttonLabel", this._buttonLabel);
  }
}

export class MEABannerView extends View {
  private _list?: IListComponent;
  private _item: Integrale | Program | Unit | Collection | Event | Extrait;
  private _descContainerElt: HTMLElement;
  private _csaIconElt?: HTMLDivElement;
  private _descTextElt: HTMLElement;

  constructor(item: Integrale | Program | Unit | Collection | Event | Extrait | Partner, displayFtvLogo: boolean) {
    super(DOMHelper.createDivWithParent(null, null, "MEABanner"));
    this._item = item;
    this._sortImages();
    if (displayFtvLogo) {
      //sur une page partner la mea banner n'a pas de logo car le logo est géré au niveau de la page
      const ftvLogoElt = DOMHelper.createDivWithParent(this.rootElement, null, "MEABannerFtvLogo");
      ftvLogoElt.style.backgroundImage = `url("${require("~images/meaBanner/meaBannerLogo-nobg.png")}")`;
    }

    const contentDataContainer = DOMHelper.createDivWithParent(this.rootElement, null, "contentDataContainer");
    const logoImageSrc = item.getLogoImgUrl();
    const titleOrLogoElement = DOMHelper.createDivWithParent(
      contentDataContainer,
      null,
      "MEABannerTitle",
      item?.extras?.program?.title ?? item.title
    );

    if (logoImageSrc) {
      checkImage(
        logoImageSrc,
        () => {
          titleOrLogoElement.innerText = "";
          titleOrLogoElement.classList.remove("MEABannerTitle");
          titleOrLogoElement.classList.add("MEABannerProgramLogo");
          titleOrLogoElement.style.backgroundImage = `url("${logoImageSrc}")`;
        },
        () => {}
      );
    }

    if (item.extras.label_edito) {
      DOMHelper.createDivWithParent(contentDataContainer, null, "MEABannerLabelEdito", item.extras.label_edito);
    }

    this._descContainerElt = DOMHelper.createDivWithParent(contentDataContainer, null, "MEABannerDescContainer");
    const descElt = DOMHelper.createDivWithParent(this._descContainerElt, null, "MEABannerDesc");
    DOMHelper.createImg(
      descElt,
      null,
      "MEABannerDescChannelIcon",
      getChannelPicto(item) ?? require("~images/meaBanner/fallbackTVIcon.png"),
      ""
    );
    DOMHelper.createDivWithParent(descElt, null, "MEABannerDescSeparator", "|");
    this._descTextElt = DOMHelper.createDivWithParent(
      descElt,
      null,
      "MEABannerDescText",
      item.extras.headline_title ?? item.summary
    );

    if (
      (item instanceof Integrale || item instanceof Unit) &&
      item.metadata.rating &&
      item.metadata.rating.code !== "TP"
    ) {
      this._csaIconElt = DOMHelper.createDivImg(
        this._descContainerElt,
        null,
        "MEABannerDescCSA",
        require("~images/pictos_csa/-" + (item.metadata.rating.code ?? "") + ".png")
      );
    }

    this._list = this.delegate = createListComponent({
      rootElement: contentDataContainer,
      pageSize: 1,
      modelSource: new StaticModelSource(["button"]),
      viewFactory: model => {
        return new BannerButtonView(item);
      },
      onSelect: () => {
        onSelectTile(item, -1);
        return true;
      },
      horizontal: true,
    });
  }

  onShown() {
    if (this._csaIconElt) {
      window.setTimeout(() => {
        if (this._csaIconElt && this._descContainerElt.clientHeight < this._descContainerElt.scrollHeight) {
          this._descContainerElt.classList.add("MEABannerDescContainerWithOverflow");
          this._csaIconElt.style.left = `${this._descTextElt.offsetWidth}px`;
        }
      }, 100);
    }
  }

  getItem = () => {
    return this._item;
  };

  private _sortImages = () => {
    this._item.artworks.sort((a, b) => parseInt(b.key, 10) - parseInt(a.key, 10));
  };
}

export const getButtonLabel = (item: Integrale | Program | Unit | Collection | Event | Extrait | Flux | Partner) => {
  if (item instanceof Integrale || item instanceof Unit || item instanceof Extrait || item instanceof Flux) {
    if (item.metadata?.extras?.is_live) {
      return "voir le direct";
    } else {
      return "regarder";
    }
  } else if (item instanceof Collection) {
    return "découvrir la collection";
  } else {
    return "découvrir";
  }
};
