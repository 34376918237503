import "./playlistVideoPlayerSwimlane.scss";

import { Integrale } from "~models/integrale";
import { ItemCollection } from "~models/itemCollection";
import { Unit } from "~models/unit";
import { LiveTileView } from "~pages/lives/liveTile";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";

import { PlayerPage } from "../pages/player/playerPage";
import { pushPlayerPage } from "../pages/rootPage";
import { PlaylistVideoView } from "../views/playlistVideoView";

export class PlaylistVideoPlayerSwimlane extends View {
  private _list: IListComponent;
  channel?: HTMLElement;
  currentPlayerPage: PlayerPage;
  nextContents?: ItemCollection[];
  constructor(
    source: ItemCollection,
    currentID: string,
    currentPlayerPage: PlayerPage,
    isLiveSwimlane = false,
    nextContents?: ItemCollection[]
  ) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneVideoVignettePlayer", "list"));
    this.currentPlayerPage = currentPlayerPage;
    this.nextContents = nextContents;
    if (isLiveSwimlane) DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this._list = this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Unit | Integrale)[]),
      viewFactory: model => {
        if (model.metadata.extras.is_live) {
          return new LiveTileView(model, true, currentID == model.id ? true : false);
        } else {
          return new PlaylistVideoView(model, true, currentID == model.id ? true : false);
        }
      },
      arrowFactory: horizontalArrowFactory,
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: this._onItemSelect,
    });
  }
  private _onItemSelect = (item: Unit | Integrale, index: number) => {
    let progressPercent: number | undefined;
    const focusedId = this._list.focusedId$.value;
    if (focusedId) {
      progressPercent = this.getProgressPercentFromId(focusedId);
    }
    this.currentPlayerPage.exitPlayer();
    pushPlayerPage(item, index, progressPercent, this.nextContents);
    // must display element videoBackground deactivate when player is closed
    const DOMVideo = document.getElementById("videoBackground");
    if (DOMVideo) {
      DOMVideo.classList.add("active");
      DOMVideo.hidden = false;
    }
    return true;
  };
  getProgressPercentFromId = (id: string) => {
    const view = this._list.viewFromId(id);
    if (view instanceof PlaylistVideoView) return view?.getProgressPercent();
  };
}
