import "./categoryDetailTab.scss";

import { Category } from "~models/category";
import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { MEASwimlane } from "~swimlaneViews/meaSwimlane";
import { PlaylistMixedSwimlane } from "~swimlaneViews/playlistMixedSwimlane";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { verticalArrowFactory } from "../../views/arrows/verticalArrow";

const _validSwimlaneTypes = ["mise_en_avant", "playlist_video", "playlist_program", "playlist_mixed"];

export class CategoryDetailTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _scrollIndexUnregister?: () => void;
  private _source: Category;
  private _background?: DynamicBackground;

  constructor(source: Category) {
    super(DOMHelper.createDivWithParent(null, "CategoryDetailTab"));
    this._source = source;
    this._fetchSource();
  }

  onShown() {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("page.display", markerPiano.contextual_properties, markerPiano.additional_properties);
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          // Here use it to create the UI
          Log.api.log("[DETAILED CATEGORY] next !", value);
          this._source = value[0];
          this._onSourceReady(value[0].items);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED CATEGORY] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      staticSource: this._source.getBackgroundImgUrl(),
    });
    DOMHelper.createDivWithParent(this.rootElement, null, "titleCategory", this._source.title);
    const validSwimlanes: ItemCollection[] = [];
    source.forEach(sl => {
      if (_validSwimlaneTypes.includes(sl.type)) {
        validSwimlanes.push(sl);
      }
    });

    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource(validSwimlanes),
        viewFactory: model => {
          if (model.type == "mise_en_avant") {
            return new MEASwimlane(model);
          } else if (model.type == "playlist_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "playlist_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "playlist_mixed") {
            return new PlaylistMixedSwimlane(model);
          } else if (model.type == "playlist_collection") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "live") {
            return new PlaylistVideoSwimlane(model);
          } else {
            //default
            return new PlaylistMixedSwimlane(model);
          }
        },
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        arrowFactory: verticalArrowFactory,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );

    this._background.setItemCollectionList(this.listComponent);
    this._scrollIndexUnregister = this.listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.addClass(this.listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      DOMHelper.removeClass(this.listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this.listComponent?.setFocusOnIndex(newIndex);
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
  };
}
