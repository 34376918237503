import "./categoryView.scss";

import { Category } from "~models/category";
import { DOMHelper, View } from "~ui-lib";

import { checkImage } from "../tools/tools";

export class CategoryView extends View {
  constructor(item: Category) {
    super(DOMHelper.createDivWithParent(null, null, "categoryItem"));
    const imageContainer = DOMHelper.createDivWithParent(this.rootElement, null, "imageContainer");
    const elementImg = DOMHelper.createDivImg(
      imageContainer,
      null,
      "categoryImage",
      require("~images/fallbacks/fallback-vignette.png")
    );
    const backgroundSrc = item.getLandscapeTileImgUrl();
    const titleElt = DOMHelper.createDivWithParent(elementImg, "", "titleCategoryFallback", item.title);

    if (backgroundSrc) {
      checkImage(
        backgroundSrc,
        () => {
          elementImg.removeChild(titleElt);
          elementImg.style.backgroundImage = `url("${backgroundSrc}")`;
        },
        () => {}
      );
    }
  }

  onFocused = () => {};
}
