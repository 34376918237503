import "./categoriesTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { CategorySwimlane } from "~swimlaneViews/categorySwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { Plugin } from "../../datas/plugin";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { DynamicBackground, DynamicBackgroundOverlayType } from "../dynamicBackground";

export class CategoriesTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _background?: DynamicBackground;
  private _source?: ItemCollection[] = undefined;
  constructor() {
    super(DOMHelper.createDivWithParent(null, "CategoriesTab"));
    this._fetchSource();
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "accueil_categorie",
        page_type: "categorie",
      },
      {}
    );
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchCategories()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[CATEGORIES] Next !", value);
          this._onSourceReady(value);
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[CATEGORIES] Error !", error);
        },
        () => {
          console.log("[CATEGORIES] Complete !");
        }
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    this._source = source;
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.none,
    });
    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentCategoriesTabSwimlane");
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(contentRoot, "VerticalSwimlaneComponent", "list"),
        modelSource: new StaticModelSource(this._source),
        viewFactory: model => {
          return new CategorySwimlane(model);
        },
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
      }
    );
  }

  onRelease = () => {
    this._background?.onRelease();
  };
}
