import "./medalBoardView.scss";

import { DOMHelper, View } from "~ui-lib";

import { CountryMedals, Idalgo, MedalsEventEndpoint } from "../tools/idalgo/idalgo";

export class MedalBoardView extends View {
  constructor(medalsEvent: MedalsEventEndpoint) {
    super(DOMHelper.createDivWithParent(null, "MedalBoard", "medalBoard"));
    const itemDescElement = DOMHelper.createDivWithParent(this.rootElement, null, "itemDesc");
    DOMHelper.createDivWithParent(itemDescElement, null, "itemTitle", "Classements et médailles");
    DOMHelper.createDivWithParent(
      itemDescElement,
      null,
      "itemSubTitle",
      "Retrouvez notre tableau des médailles détaillé avec le classement de la France parmi les différents pays participants."
    );
    this._fetchSource(medalsEvent);
  }

  private _fetchSource(medalsEvent: MedalsEventEndpoint) {
    Idalgo.fetchMedals(medalsEvent)
      .then(data => {
        this._onSourceReady(data.france);
      })
      .catch(e => {
        Log.api.error(e);
      });
  }

  private _onSourceReady(source: CountryMedals) {
    DOMHelper.createDivWithParent(this.rootElement, null, "title", "Tableau des médailles");
    const focusBox = DOMHelper.createDivWithParent(this.rootElement, null, "focusBox");
    const boardContainer = DOMHelper.createDivWithParent(focusBox, null, "boardContainer");

    const textContainer = DOMHelper.createDivWithParent(boardContainer, null, "textContainer");
    const formatRanking =
      typeof source.ranking === "number" && source.ranking < 10 ? "0" + source.ranking : source.ranking + "";
    DOMHelper.createDivWithParent(textContainer, null, "ranking", formatRanking);
    DOMHelper.createDivImg(textContainer, null, "flag", source.flag?.png ?? "");
    DOMHelper.createDivWithParent(textContainer, null, "country", source.country);

    const medalsContainer = DOMHelper.createDivWithParent(boardContainer, null, "medalsContainer");
    DOMHelper.createDivImg(medalsContainer, null, "medalImg", require("~images/medals/gold.png"));
    DOMHelper.createDivWithParent(medalsContainer, null, "medal", `${source.gold}`);
    DOMHelper.createDivImg(medalsContainer, null, "medalImg", require("~images/medals/silver.png"));
    DOMHelper.createDivWithParent(medalsContainer, null, "medal", `${source.silver}`);
    DOMHelper.createDivImg(medalsContainer, null, "medalImg", require("~images/medals/bronze.png"));
    DOMHelper.createDivWithParent(medalsContainer, null, "medal", `${source.bronze}`);

    const allMedalsContainer = DOMHelper.createDivWithParent(boardContainer, null, "allMedalsContainer");
    DOMHelper.createDivImg(allMedalsContainer, null, "medalsImg", require("~images/medals/medals.png"));
    const medalsTotal = source.gold + source.silver + source.bronze;
    DOMHelper.createDivWithParent(allMedalsContainer, null, "medal", `${medalsTotal}`);
  }
}
