import "./parametersList.scss";

import {
  createListComponent,
  DOMHelper,
  IDelegate,
  Keys,
  StaticModelSource,
  View,
} from "../../../../bud-lite-tv/src/lib";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { Position } from "../../../tools/cmp/didomi";
import { PurposeId } from "../../../tools/cmp/didomiStub";
import { CheckboxParametersWithButton } from "./checkBoxParameters";

export interface Parameters {
  id: PurposeId;
  state: Position;
  title: string;
  text: string;
}

class DetailParameters extends View {
  list: IDelegate | undefined;

  constructor(list: IDelegate | undefined) {
    super(DOMHelper.createDivWithParent(null, "DetailParameters"));
    this.list = list;
    DOMHelper.createDivWithParent(this.rootElement, "TitleParameters", null, "Paramétrez vos consentements");
    DOMHelper.createDivWithParent(
      this.rootElement,
      "TextParameters",
      null,
      "Vous pouvez contrôler le dépôt et la lecture de traceurs sur votre terminal indépendamment pour chaque finalité présentée ci-dessous en cliquant sur l'interrupteur correspondant. Ce paramétrage s'applique à cet appareil et pour ce navigateur internet uniquement.\n\nPour chaque finalité, la position grise exprime le refus de consentement (les traceurs sont désactivés), la position verte exprime le consentement (les traceurs sont activés). Sans action de votre part tous les traceurs sont désactivés.\n\nCliquez sur les finalités ci-dessous pour en obtenir une description détaillée. Une fois votre paramétrage terminé, cliquez sur «\u00a0Enregistrer mes choix\u00a0»."
    );
    const arrowParameters = DOMHelper.createDivWithParent(this.rootElement, "ArrowParameters");
    arrowParameters.onmousedown = this.goDown;
  }

  private goDown = () => {
    if (this.list && this.list.onNav) {
      this.list.onNav(Keys.down);
    }
  };

  public onNav(key: Keys) {
    switch (key) {
      case Keys.select:
        this.goDown();
        return true;
      default:
        return false;
    }
  }
}

type ParameterListModel =
  | {
      id: string;
      type: "Detail";
    }
  | {
      id: string;
      type: "PurposeParameters";
      parameters: Parameters;
    };

export class ParametersList extends View {
  constructor(parametersList: Parameters[]) {
    super(DOMHelper.createDivWithParent(null, "ParametersList"));
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource<ParameterListModel>([
          {
            id: "Detail",
            type: "Detail",
          },
          ...parametersList.map((purpose, index) => {
            return {
              id: `PurposeParameters-${index}`,
              type: "PurposeParameters" as const,
              parameters: purpose,
            };
          }),
        ]),
        viewFactory: model => {
          switch (model.type) {
            case "Detail":
              return new DetailParameters(this.delegate);
            case "PurposeParameters":
              return new CheckboxParametersWithButton(model.parameters);
          }
        },
        horizontal: false,
        pageSize: 1,
        mouseFocusInPageOnly: true,
        visibleBefore: 1,
        visibleAfter: 1,
        spatialFocus: false,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  onShown() {
    sendPianoAnalytic(
      "publisher.impression",
      {
        feature: "cmp_parametrer",
      },
      {}
    );
  }
}
