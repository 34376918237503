import "./subscriptionSwimlane.scss";

import { Plugin } from "~pages/../datas/plugin";
import { listAccount } from "~pages/account/accountTab";
import { InscriptionPage } from "~pages/inscription/inscription";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { navigationStack } from "../../main";
import { SubscriptionView } from "../subscriptionView";

export enum subscription {
  logIn = "Je me connecte",
  signUp = "Je m'inscris",
  disconnect = "se déconnecter",
}

export class SubscriptionSwimlane extends View {
  constructor(isConnected: boolean, listComponent: IListComponent<listAccount>) {
    super(DOMHelper.createDivWithParent(null, "SubscriptionSwimlane", "listContainer"));
    const source = isConnected ? [subscription.disconnect] : [subscription.signUp, subscription.logIn];
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource(source),
        viewFactory: model => new SubscriptionView(model, isConnected),
        horizontal: true,
        pageSize: 2,
        onSelect: button => {
          switch (button) {
            case subscription.disconnect:
              this._fetchLogout();
              listComponent.resetContent();
              break;
            case subscription.logIn:
              navigationStack.pushPage(new InscriptionPage(true));
              break;
            case subscription.signUp:
              navigationStack.pushPage(new InscriptionPage(false));
              break;
          }
          return true;
        },
      },
      list => list.setFocusOnId(isConnected ? subscription.disconnect : subscription.logIn, { focus: false })
    );
  }

  private _fetchLogout = () => {
    Plugin.getInstance()
      .fetchLogout()
      .subscribe(
        value => {
          Log.app.log("[SubscriptionSwimlane._fetchLogout] ok !", value);
        },
        error => {
          Log.app.error("[SubscriptionSwimlane._fetchLogout] error !", error);
        },
        () => {
          Log.app.log("[SubscriptionSwimlane._fetchLogout] Complete !");
        }
      );
  };
}
