import "./cguSwimlane.scss";

import { CGU } from "~models/cgu";
import { ItemCollection } from "~models/itemCollection";
import { CGUPage } from "~pages/cgu/cguPage";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";
import { CGUView } from "~views/cguView";

import { navigationStack } from "../main";

export class CGUSwimlane extends View {
  cacheable = true;
  private _list: IListComponent<CGU>;
  private _itemTitleElt: HTMLElement;
  private _focusedIdUnregister;
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneCGUVignette", "list"));
    this._itemTitleElt = DOMHelper.createDivWithParent(this.rootElement, null, "itemTitle");
    DOMHelper.createDivWithParent(this.rootElement, null, "titleSwimlane", source.title);
    this.delegate = this._list = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as CGU[]),
      viewFactory: model => {
        return new CGUView(model);
      },
      horizontal: true,
      pageSize: 4,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: this.onSelect,
    });
    this._focusedIdUnregister = this._list.focusedId$.didChange(newId => {
      const model = this._list.modelFromId(newId);
      this._itemTitleElt.innerText = model ? model.title : "";
    });
  }

  onRelease = () => {
    this._focusedIdUnregister();
  };

  onSelect = (model: CGU): boolean => {
    navigationStack.pushPage(new CGUPage(model));
    return true;
  };
}
