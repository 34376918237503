import "./favoriteButton.scss";

import { DOMHelper, View } from "~ui-lib";

export type FavoriteButtonType = "favorite" | "likeThumb" | "notLikeThumb";

const _labelMap: Record<FavoriteButtonType, string> = {
  favorite: "ma liste",
  likeThumb: "j'aime",
  notLikeThumb: "je n'aime pas",
};

export class FavoriteButton extends View {
  private _imageElement: HTMLElement;
  private _containerElement: HTMLElement;
  private _isChecked: boolean;
  private _images;
  private _type: FavoriteButtonType;

  constructor(isChecked: boolean, type: FavoriteButtonType) {
    super(DOMHelper.createDivWithParent(null, null, "favoriteButton"));

    this._isChecked = isChecked;
    this._type = type;
    const images = {
      checked: require("~images/buttonProgram/checked" + this._type + ".png"),
      notChecked: require("~images/buttonProgram/notChecked" + this._type + ".png"),
    };
    this._images = images;

    this._containerElement = DOMHelper.createDivWithParent(
      DOMHelper.createDivWithParent(this.rootElement, null, "favoriteFocusBorder"),
      null,
      "favoriteContainer"
    );
    this._imageElement = DOMHelper.createDivImg(this._containerElement, null, "favoriteButtonIcon");
    this.updateIsFavorite(this._isChecked);

    DOMHelper.createDivWithParent(this.rootElement, null, "favoriteButtonLabel", _labelMap[this._type]);
  }

  updateIsFavorite(isChecked: boolean) {
    this._isChecked = isChecked;
    this._imageElement.style.background = `url("${
      this._isChecked ? this._images.checked : this._images.notChecked
    }") no-repeat`;
    this._imageElement.style.backgroundSize = "100%";
  }
}
