import { z } from "zod";

import { Config } from "../../config";
import { Plugin } from "../../datas/plugin";
import { ILoggedInUser } from "../../models/user";
import { Didomi } from "../cmp/didomi";
import { LikeOrDislike } from "../ratingsHelper";

const _urlApiSpideo = (() => {
  if (__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy") {
    return Config.spideo.prod;
  } else {
    return Config.spideo.preprod;
  }
})();

const passwdTokenSpideo = (() => {
  if (__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy") {
    return "uKl1WrQL4yGCWVm2C2DTHCmL8";
  } else {
    return "rai7eezaithaiTi";
  }
})();

const SpideoAccessTokenResponse = z.object({
  token: z.string(),
  refresh_token: z.string(),
  maxAge: z.number(),
});
type SpideoAccessTokenResponse = z.infer<typeof SpideoAccessTokenResponse>;

const SpideoRefreshTokenResponse = z.object({
  token: z.string(),
  maxAge: z.number(),
});
type SpideoRefreshTokenResponse = z.infer<typeof SpideoRefreshTokenResponse>;

type SpideoEventParam =
  | {
      eventName: "playing" | "stop" | "pause";
      timeViewed: number; // !!! should be integer in s
      completion: number; // !!! should be integer 0 to 100
      videoId: string;
    }
  | {
      eventName: "startPlaying" | "seek" | "play" | "favorite" | "unfavorite";
      videoId: string;
    };

class SpideoImplementation {
  private _tokenData?: {
    token: string;
    refresh_token: string;
    expireAtMs: number;
  } = undefined;
  private static _instance: SpideoImplementation | undefined = undefined;
  private static _username = "iptvsmarttvftv";

  public static create() {
    if (SpideoImplementation._instance === undefined) {
      SpideoImplementation._instance = new SpideoImplementation();
    }
    return SpideoImplementation._instance;
  }

  private constructor() {}

  private _genericSpideoFetch = async <T>(callback: (accessToken: string) => Promise<T>): Promise<T | undefined> => {
    if (Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L") === false) {
      return undefined;
    }
    try {
      if (this._tokenData === undefined) {
        const spideoAccessToken = await this._generateSpideoAccessToken();
        this._tokenData = {
          token: spideoAccessToken.token,
          refresh_token: spideoAccessToken.refresh_token,
          expireAtMs: Date.now() + spideoAccessToken.maxAge * 1000,
        };
      } else {
        if (Date.now() > this._tokenData.expireAtMs) {
          const spideoAccessToken = await this._refreshSpideoAccessToken(this._tokenData.refresh_token);
          this._tokenData = {
            token: spideoAccessToken.token,
            refresh_token: this._tokenData.refresh_token,
            expireAtMs: Date.now() + spideoAccessToken.maxAge * 1000,
          };
        }
      }
      return callback(this._tokenData.token);
    } catch (e) {
      Log.app.error(e);
      this._tokenData = undefined;
    }
  };

  private async _generateSpideoAccessToken(): Promise<SpideoAccessTokenResponse> {
    const headers = { "Content-Type": "application/json" };
    const body = { username: SpideoImplementation._username, password: passwdTokenSpideo };
    const json = await Plugin.getInstance()
      .fetchURL(`${_urlApiSpideo}/v1/auth/login`, "POST", body, headers, false)
      .toPromise();
    return SpideoAccessTokenResponse.parse(json);
  }

  private async _refreshSpideoAccessToken(refreshToken: string): Promise<SpideoRefreshTokenResponse> {
    const headers = { "Content-Type": "application/json" };
    const body = {
      username: SpideoImplementation._username,
      password: passwdTokenSpideo,
      token: refreshToken,
    };
    const json = await Plugin.getInstance()
      .fetchURL(`${_urlApiSpideo}/v1/auth/refresh`, "POST", body, headers, false)
      .toPromise();
    return SpideoRefreshTokenResponse.parse(json);
  }

  public sendSpideoEvent = (paramSpideo: SpideoEventParam) => {
    const userId = Plugin.getInstance().user?.infos?.publicId;
    if (userId !== undefined /* && allowedToSend*/) {
      return this._genericSpideoFetch(
        async (accessToken: string): Promise<void> => {
          const params = { ...paramSpideo, userId };
          const headers = { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` };
          await Plugin.getInstance().fetchURL(`${_urlApiSpideo}/v1/events`, "POST", params, headers, false).toPromise();
        }
      );
    }
  };

  public sendRating = (user: ILoggedInUser, id: string, rating: LikeOrDislike) => {
    return this._genericSpideoFetch(
      async (accessToken: string): Promise<void> => {
        const params = { value: rating };
        const headers = { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` };

        Plugin.getInstance()
          .fetchURL(
            `${_urlApiSpideo}/v1/recommendations/users/` + user.infos.publicId + `/ratings/` + id,
            rating === 0 ? "DELETE" : "POST",
            params,
            headers,
            false
          )
          .toPromise();
      }
    );
  };

  public startSpideo() {
    this._genericSpideoFetch(async _ => {
      return;
    });
  }
}

export const Spideo = SpideoImplementation.create();
