import "./unitTab.scss";

import { Unit } from "~models/unit";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { parseMarkerPianoPageDisplay } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { UnitDesc } from "./unitDesc";

export class UnitTab extends View {
  private _source: Unit;
  private _background?: DynamicBackground;
  /**
   * Source has been fetched.
   * Meaning that source data is not partial.
   */
  private _isSourceReady = false;

  constructor(source: Unit) {
    super(DOMHelper.createDivWithParent(null, "UnitTab"));

    this._source = source;

    this._fetchSource();
  }

  onShown() {
    if (this._isSourceReady) {
      this._sendAnalyticsData();
    }
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDetailed(this._source)
      .subscribe(
        value => {
          // Here use it to create the UI
          Log.api.log("[DETAILED UNIT] Next !", value);
          this._source = value[0];
          this._onSourceReady(value[0]);
        },
        error => {
          // Here use it to trigger and display an error
          Log.api.error("[DETAILED UNIT] Error !", error);
        },
        () => {}
      );
  }

  private _onSourceReady = (items: Unit) => {
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.unitGradient,
      source: this._source.getBackgroundImgUrl(),
    });

    this._isSourceReady = true;
    this._sendAnalyticsData();

    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentHomeTabSwimlane");
    this.delegate = createListComponent({
      rootElement: DOMHelper.createDivWithParent(contentRoot, "VerticalSwimlaneComponent", "list"),
      modelSource: new StaticModelSource([items]),
      horizontal: false,
      pageSize: 1,
      visibleAfter: 1,
      spatialFocus: true,
      viewFactory: model => {
        if (model instanceof Unit) {
          return new UnitDesc(model);
        }
        return new UnitDesc(this._source);
      },
    });
  };

  private _sendAnalyticsData = () => {
    const markerPiano = parseMarkerPianoPageDisplay(this._source.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic(
        "page.display",
        {
          ...markerPiano.contextual_properties,
          /**
           * Unitaire marker piano has `page_type="video"`
           * We are overriding this value to set it to unitaire page type
           */
          page_type: "unitaire",
        },
        markerPiano.additional_properties
      );
    } else {
      Log.analytics.error("Failed to send piano analytics");
    }
  };
}
