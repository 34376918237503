"use strict";

import "./loaderPage.scss";

import { DOMHelper, View } from "~ui-lib";

const anim_start = require("./../../../static/images/loader/tv-loader.gif");
const anim_end = require("./../../../static/images/loader/LOADER_logo.gif");

export class LoaderView extends View {
  private DOMAnim: HTMLImageElement;
  private opacity: number;
  private intervalID: number;
  private animationEndDuration = 1000;
  private fadeDurationInterval = 100;
  private timerEndAnimID = -1;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "loader-overlay"));

    this.opacity = 1;
    this.intervalID = -1;

    const loader = DOMHelper.createDivWithParent(this.rootElement, "loader-container", null);
    // use image src in order to reload gif animation from start
    this.DOMAnim = DOMHelper.createImg(loader, "loader-anim", undefined, undefined, "transparent");
  }
  start(): void {
    this.DOMAnim.src = "";
    this.DOMAnim.src = anim_start;
  }
  end(withLogo = false, onComplete?: () => void): void {
    if (withLogo) {
      // Change gif from infinite loop to loop+logo
      this.DOMAnim.src = "";
      this.DOMAnim.style.backgroundColor = "#444";
      this.DOMAnim.src = anim_end;

      // We need to let time to play animation before removing the loader/logo (1s is ok for animation duration, for now)
      this.timerEndAnimID = window.setTimeout(() => {
        this.fadeOut(() => {
          onComplete?.();
        });
      }, this.endDuration());
    } else {
      this.DOMAnim.src = "";
      onComplete?.();
    }
  }
  validate(onComplete?: () => void): void {
    this.DOMAnim.src = "";
    this.DOMAnim.src = anim_start;
    this.timerEndAnimID = window.setTimeout(() => {
      this.fadeOut(() => {
        onComplete?.();
      });
    }, this.endDuration());
  }
  private fadeOut(onSuccess?: () => void): void {
    this.intervalID = window.setInterval(() => {
      this.hide(onSuccess);
    }, this.fadeDurationInterval);
  }
  private hide(onSuccess?: () => void): void {
    this.opacity = Number(window.getComputedStyle(this.DOMAnim).getPropertyValue("opacity"));
    if (this.opacity > 0) {
      this.opacity = Math.max(0, this.opacity - 0.1);
      this.DOMAnim.style.opacity = String(this.opacity);
    } else {
      clearInterval(this.intervalID);
      onSuccess?.();
    }
  }
  private endDuration(): number {
    return this.animationEndDuration;
  }
  onRelease(): void {
    clearTimeout(this.timerEndAnimID);
    clearInterval(this.intervalID);
    this.DOMAnim.src = "";
    this.opacity = 1;
    this.intervalID = -1;
  }
}
