import "./channelItem.scss";

import { Channel } from "~models/channel";
import { DOMHelper, View } from "~ui-lib";

import { Region } from "../../models/region";

export class ChannelItem extends View {
  constructor(item: Channel | Region) {
    super(DOMHelper.createDivWithParent(null, null, "channelItem"));
    const imageContainer = DOMHelper.createDivWithParent(this.rootElement, null, "imageContainer");

    const channelImgUrl = ((): string => {
      if (item instanceof Channel) {
        if (item.extras.channel_path === "serie") {
          return require("~images/channels/carre/serie.png");
        } else if (item.extras.channel_path === "documentaire") {
          return require("~images/channels/carre/documentaire.png");
        } else if (item.extras.channel_path === "paris-h24") {
          return require("~images/channels/carre/paris-h24.png");
        } else {
          return item.getSquareImgUrl();
        }
      }
      if (item instanceof Region) {
        return item.getHeroImgUrl();
      }
      return "";
    })();

    if (channelImgUrl.length) {
      DOMHelper.createDivImg(imageContainer, null, "channelImage", channelImgUrl);
    }
    if (item instanceof Region) {
      DOMHelper.createDivWithParent(this.rootElement, null, "regionLabel", item.title);
    }
  }

  onFocused = () => {};
}
