import { z } from "zod";

import { apiMobilePlatformParam, Plugin } from "../../datas/plugin";

export type MedalsEventEndpoint = "jo" | "jp";

export const mappingMedalsEvent = (urlComplete: string): MedalsEventEndpoint | undefined => {
  if (urlComplete === "sport_les-jeux-olympiques") {
    return "jo";
  }
  if (urlComplete === "sport_handisport_jeux-paralympiques") {
    return "jp";
  }
};

const CountryMedals = z.object({
  country: z.string(),
  ranking: z.union([z.string(), z.number()]),
  flag: z
    .object({
      png: z.string().nullish(),
    })
    .nullish(),
  gold: z.number(),
  silver: z.number(),
  bronze: z.number(),
});
export type CountryMedals = z.infer<typeof CountryMedals>;

const MedalsEvent = z.object({
  competition: z.string().nullish(),
  year: z.string().nullish(),
  medals: z.array(CountryMedals),
  france: CountryMedals,
});
export type MedalsEvent = z.infer<typeof MedalsEvent>;

const _apiMobileURL = Plugin.getInstance().apiMobileURL;
const _apiMobilePlatformParam = apiMobilePlatformParam;

export abstract class Idalgo {
  static readonly fetchMedals = async (medalsEvent: MedalsEventEndpoint): Promise<MedalsEvent> => {
    const endPoint = _apiMobileURL + "/generic/medals/" + medalsEvent + "?platform=" + _apiMobilePlatformParam.platform;
    try {
      const response = await fetch(endPoint);
      const json = await response.json();
      const parseResult = MedalsEvent.safeParse(json);
      if (parseResult.success) {
        return parseResult.data;
      } else {
        Log.api.error(parseResult.error.issues);
        throw "Parsing error";
      }
    } catch (e) {
      Log.api.error("fetchMedals ", endPoint, e);
      throw e;
    }
  };
}
