import "./consentManagementPage.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { Plugin } from "../../../datas/plugin";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { Didomi } from "../../../tools/cmp/didomi";
import { ButtonCmp } from "../../../views/cmp/cmpButton/buttonCmp";
import { MainMenuItemSlug } from "../../../views/mainMenu/itemMenu";
import { HomeTab } from "../../home/homeTab";
import { pushConnexionPage, pushTabWithMenu } from "../../rootPage";
import { ParametersTab } from "../parameters/parametersPage";
import { PartnersTab } from "../partners/partnersPage";
import { PrivatePolicyTab } from "../privacyPolicy/privacyPolicy";
import { MenuHorizontal, TextHorizontalMenu } from "./menuHorizontal";
import { MenuVertical, TextVerticalMenu } from "./menuVertical";

export class ConsentManagementTab extends View {
  private readonly itemMenuVertical: ButtonCmp[] = [
    {
      id: 0,
      width: 347,
      text: TextVerticalMenu.policyOf0,
      onPress: () => {
        navigationStack.pushPage(new PrivatePolicyTab());
      },
    },
    {
      id: 1,
      width: 347,
      text: TextVerticalMenu.ourPartners,
      onPress: () => {
        navigationStack.pushPage(new PartnersTab());
      },
    },
    {
      id: 2,
      width: 280,
      text: TextVerticalMenu.setUp,
      onPress: () => {
        navigationStack.pushPage(new ParametersTab());
      },
    },
  ];

  private readonly itemMenuHorizontal: ButtonCmp[] = [
    {
      id: 0,
      width: 288,
      text: TextHorizontalMenu.refuseAll,
      onPress: () => {
        Didomi.disableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_notice",
            click: "continuer_sans_accepter",
            cmp_analytics: "false",
            cmp_custom_content: "false",
          },
          {}
        );
        this._exit();
      },
    },
    { id: 1, width: 90, text: TextHorizontalMenu.arrow, onPress: () => {} },
    {
      id: 2,
      width: 316,
      text: TextHorizontalMenu.acceptAll,
      onPress: () => {
        Didomi.enableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_notice",
            click: "accepter_et_fermer",
            cmp_analytics: "true",
            cmp_custom_content: "true",
          },
          {}
        );
        this._exit();
      },
    },
  ];

  constructor() {
    super(DOMHelper.createDivWithParent(null, "ConsentManagementTab"));
    DOMHelper.createDivWithParent(
      this.rootElement,
      "TitleCMP",
      null,
      "Pour France Télévisions, le respect de votre vie privée est une priorité"
    );
    const text = [
      "Nous utilisons des traceurs afin de mesurer l'audience de nos contenus.",
      "Vous pouvez exprimer votre consentement à ces traceurs en cliquant sur le bouton «\u00a0tout accepter\u00a0», les refuser en cliquant sur «\u00a0tout refuser\u00a0», ou vous informer sur le détail de la finalité et exprimer votre choix en cliquant sur «\u00a0paramétrer\u00a0».",
      "Vous pouvez modifier vos choix à tout moment en cliquant sur «\u00a0mon compte\u00a0» dans la rubrique «\u00a0gérer mes consentements\u00a0». Vous pouvez aussi consulter notre politique de gestion des cookies et traceurs pour en savoir plus.",
    ];
    DOMHelper.createDivWithParent(this.rootElement, "TextCMP", null, text.join("\n\n"));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(["VerticalMenu", "HorizontalMenu"]),
      viewFactory: model => {
        switch (model) {
          case "VerticalMenu":
            return new MenuVertical(this.itemMenuVertical, 2);
          default:
            return new MenuHorizontal(this.itemMenuHorizontal);
        }
      },
      horizontal: false,
      pageSize: 3,
      visibleBefore: 1,
      visibleAfter: 1,
      spatialFocus: false,
    });
  }

  onShown() {
    sendPianoAnalytic(
      "publisher.impression",
      {
        feature: "cmp_notice",
      },
      {}
    );
  }

  private _exit = () => {
    navigationStack.destroyStack();
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
    } else {
      pushConnexionPage();
    }
  };
}
