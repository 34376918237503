import "./recoConsentBannerView.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../bud-lite-tv/src/lib";
import { navigationStack } from "../main";
import { ParametersTab } from "../pages/cmp/parameters/parametersPage";
import { sendPianoAnalytic } from "../tools/analytics/piano";

enum RecoConsentBannerButtonId {
  settings = "settings",
  ignore = "ignore",
}

type RecoConsentBannerButtonType = {
  id: RecoConsentBannerButtonId;
  label: string;
};

const _staticSource: RecoConsentBannerButtonType[] = [
  {
    id: RecoConsentBannerButtonId.settings,
    label: "paramétrer mes consentements",
  },
  {
    id: RecoConsentBannerButtonId.ignore,
    label: "ignorer",
  },
];

export class RecoConsentBannerView extends View {
  constructor(onIgnoreButton: () => void, buttonToFocus?: "ignore") {
    super(DOMHelper.createDivWithParent(null, null, "recoConsentBanner", null));
    DOMHelper.createDivWithParent(
      this.rootElement,
      null,
      "recoConsentBannerTitle",
      "Gérez vos consentements et obtenez des recommandations sur mesure"
    );
    DOMHelper.createDivWithParent(
      this.rootElement,
      null,
      "recoConsentBannerDescription",
      "Ici, retrouvez des recommandations de vidéos personnalisées. Pour les voir, il est nécessaire de consentir aux traceurs à des fins de personnalisation de nos contenus et services. Vous pouvez revenir sur votre choix en cliquant sur « paramétrer mes consentements »."
    );
    DOMHelper.createDivImg(
      this.rootElement,
      null,
      "recoConsentBannerImage",
      require("../../static/images/cmp/recoconsentbanner.png")
    );
    this.delegate = createListComponent(
      {
        horizontal: true,
        pageSize: 2,
        rootElement: this.rootElement,
        visibleBefore: 0,
        visibleAfter: 1,
        modelSource: new StaticModelSource<RecoConsentBannerButtonType>(_staticSource),
        noTransform: true,
        viewFactory: model => {
          return new RecoConsentBannerButtonView(model.label);
        },
        onSelect: model => {
          if (model.id === RecoConsentBannerButtonId.settings) {
            sendPianoAnalytic(
              "click.action",
              {
                click: "parametrer_mes_consentements",
              },
              {}
            );
            navigationStack.pushPage(new ParametersTab(true));
          } else {
            sendPianoAnalytic(
              "click.action",
              {
                click: "ignorer",
              },
              {}
            );
            onIgnoreButton();
          }
          return true;
        },
      },
      mainList => {
        mainList.setFocusOnId(
          buttonToFocus === "ignore" ? RecoConsentBannerButtonId.ignore : RecoConsentBannerButtonId.settings
        );
      }
    );
  }
}

class RecoConsentBannerButtonView extends View {
  constructor(label: string) {
    super(DOMHelper.createDivWithParent(null, null, "recoConsentBannerButton", label));
  }
}
