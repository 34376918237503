import { navigationStack } from "../main";
import { InscriptionPage } from "../pages/inscription/inscription";
import { PopupPage } from "../pages/popup/popupPage";

export function pushPopUpLogin(title: string) {
  navigationStack.pushPage(
    new PopupPage(
      {
        title: title,
        description: "",
        button1: {
          label: "Je m'inscris",
          action: () => {
            navigationStack.pushPage(new InscriptionPage(false));
          },
        },
        button2: {
          label: "Je me connecte",
          action: () => {
            navigationStack.pushPage(new InscriptionPage(true));
          },
        },
        button3: {
          label: "Pas maintenant",
          action: () => {},
        },
      },
      true
    )
  );
}

export function popUpUserNeedConnectToReview() {
  pushPopUpLogin("Vous souhaitez donner votre avis sur ce programme ? Connectez-vous !");
}
