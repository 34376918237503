import { currentPlayer, PlayerPage } from "~pages/player/playerPage";
import { platform, PlatformType } from "~ui-lib";

import { navigationStack } from "../main";
import { HomeTab } from "../pages/home/homeTab";
import { pushTabWithMenu } from "../pages/rootPage";
import { MainMenuItemSlug } from "../views/mainMenu/itemMenu";

export const startMultitaskingManager = () => {
  Log.app.info("startMultitaskingManager START");
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      // Behavior when application is hidden
      switch (platform.type) {
        case PlatformType.tizen:
          if (currentPlayer) {
            Log.app.log("onVisibilityHide() isTizen true :: exiting player");
            if (navigationStack.topPage instanceof PlayerPage) {
              navigationStack.topPage.exitPlayer();

              /**
               * Since we are removing top page, we can encounter cases when the navigationStack is empty
               * To prevent app to be stuck without any page, we are pushing the home page.
               */
              if (navigationStack.pages$.value.length <= 0) {
                pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
              }
            }
          }
          break;
        default:
          Log.app.log("onVisibilityHide :: do nothing");
      }
    } else {
      // Behavior when application is resumed
      switch (platform.type) {
        case PlatformType.tizen:
          // We don't need to do anything specific here
          break;
        default:
          Log.app.log("onVisibilityShow :: do nothing");
      }
    }
  });
};
