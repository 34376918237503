import "./meaView.scss";

import { checkImage } from "~tools/tools";
import { DOMHelper, View } from "~ui-lib";

import { Flux } from "../models/flux";
import { Integrale } from "../models/integrale";
import { Unit } from "../models/unit";
import { getChannelPicto, hasPartner } from "../tools/getChannelPics";

export class MEAView extends View {
  item: any;
  constructor(item: any) {
    super(DOMHelper.createDivWithParent(null, item.id, "ItemEnAvantVignette"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ItemEnAvantViewBoxZoom");
    this.item = item;

    /**
     * We will show `Live` label only for:
     * - Flux
     * - Unit
     * - Integral
     * if `is_live` is true;
     */
    if (item instanceof Flux || item instanceof Unit || item instanceof Integrale) {
      if (this.item.metadata?.extras?.is_live) {
        DOMHelper.createDivWithParent(box, null, "labelLive", "en direct");
      }
    }

    const elementImg = DOMHelper.createDivImg(box, null, "elementImg", require("~images/fallbacks/fallback-mea.png"));
    const titleElt = DOMHelper.createDivWithParent(elementImg, null, "titleMEAFallback", item.title);
    const summaryElt = DOMHelper.createDivWithParent(elementImg, null, "summaryMEAFallback", item.summary);

    const backgroundSrc = this.item.getPosterTileImgUrl();
    if (backgroundSrc.length) {
      checkImage(
        backgroundSrc,
        () => {
          elementImg.removeChild(titleElt);
          elementImg.removeChild(summaryElt);
          elementImg.style.backgroundImage = `url("${backgroundSrc}")`;
        },
        () => {}
      );
    }

    if (hasPartner(item)) {
      const channelPicto = getChannelPicto(this.item, true);
      if (channelPicto) {
        DOMHelper.createImg(elementImg, null, "infoChannelIcon", channelPicto, "");
      }
    }

    if (this.item.metadata && this.item.metadata.rating && this.item.metadata.rating.code) {
      const picto = this.item.metadata.rating.picto(this.item.metadata.rating.code);
      if (picto) DOMHelper.createDivImg(elementImg, null, "pictosCsa", picto);
    }
  }

  onFocused = () => {};
}
