import { Storage } from "../storage";

const CONSENT_BANNER_LAST_IGNORE_DATE_KEY = "consentBannerLastIgnoreDate";

export class RecoConsentBannerHandler {
  private static _getLastIgnoreDate = () => {
    const lastIgnoreDate = Storage.getItem(CONSENT_BANNER_LAST_IGNORE_DATE_KEY);
    if (lastIgnoreDate != null) {
      return parseInt(lastIgnoreDate, 10);
    }
  };

  static clearLastIgnoreDate = () => {
    Storage.removeItem(CONSENT_BANNER_LAST_IGNORE_DATE_KEY);
  };

  static updateLastIgnoreDate = () => {
    Storage.setItem(CONSENT_BANNER_LAST_IGNORE_DATE_KEY, Date.now() + "");
  };

  private static _compareDays = () => {
    // This will return the days that passed between the previous and current date
    const lastIgnoreDate = RecoConsentBannerHandler._getLastIgnoreDate();
    if (lastIgnoreDate) {
      return (Date.now() - lastIgnoreDate) / (24 * 3600 * 1000);
    }
    return undefined;
  };

  static shouldDisplayConsentBanner = () => {
    const daysPassed = RecoConsentBannerHandler._compareDays();
    if (daysPassed != undefined) {
      return daysPassed >= 30;
    }
    return true;
  };
}
