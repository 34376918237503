/* jshint sub: true */
//
// DOTSCREEN AdController
// (c) DOTSCREEN 2012-2017
// 25/03/21 @author: Sonia Morlan: DOTSCREEN AdController v1.4 convert to ts

import { Player } from "./player";

export type AdControllerEvent = "creativeView" | "start" | "firstQuartile" | "midpoint" | "thirdQuartile" | "complete";

export interface IAdConfig {
  noAdsDataCallback?: () => void;
  prerollAdBreak?: unknown[];
  midrollAdBreak?: Record<string, unknown>;
  postrollAdBreak?: unknown[];
  preRollURL?: string | Array<string>;
  midRollURL?: string | Array<string>;
  postRollURL?: string | Array<string>;
  logger?: (msg: string) => void;
  delegate: {
    shouldGetUrl: (_adController: AdController, callback: () => void) => boolean;
    shouldPlayPreRoll: (_adController: AdController) => boolean;
    shouldPlayMidRoll: (_adController: AdController) => boolean;
    shouldPlayPostRoll: (_adController: AdController) => boolean;
    shouldStopAdVideo: (_adController: AdController) => boolean;
  };
  listener: {
    adControllerDidStartVideoAd: (_adController: AdController, _type: string) => void;
    adControllerDidEndVideoAd: (_adController: AdController, _type: string) => void;
    adControllerDidUpdateProgress: (
      _adController: AdController,
      theElapsedSeconds: number,
      theRemainingSeconds: number
    ) => void;
    adControllerOnEvent: (_adController: AdController, event: AdControllerEvent) => void;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supportedVideoTypes?: any;
  maxWrapper?: number;
  maxAllowedBitrate?: number | null;
  maxAdsPerVast?: number | null;
  supportedImagesTypes?: { [key: string]: boolean };
  companionDiv?: HTMLElement;
  defaultComapanionImg?: string | null;
  customImpressionTracker?: () => void;
  beaconSender?: (url: string) => void;
}

interface IAdCompanionStaticResource {
  isTracked: boolean;
  src: string;
  width: number;
  height: number;
}

export interface IAdBreak {
  impressionURLs: Array<string>;
  id: string;
  index: number;
  duration: number;
  count: number;
  name: string;
  videoURL: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventTrackers: any;
  companionHTMLResource?: HTMLElement;
  companionIFrameResource?: HTMLElement;
  companionStaticResource?: IAdCompanionStaticResource;
  timeOffset: "pre-roll" | "mid-roll" | "post-roll";
}

interface IAdPlayerListener {
  onTimeUpdate?: (theElapsedSeconds: number, theRemainingSeconds: number, url: string) => void | boolean; // Called every second by the player
  onVideoEnd: () => void | boolean; // Called by the player when the video ended
  onVideoError: () => void | boolean; // Called by the player when the video was forced to end
  onVideoAbort: () => void | boolean; // Called by the player when the video was forced to end
}

interface IAdPlayerDelegate {
  shouldPlayVideo: (aPlayer: Player, url: string) => boolean; // Called when player want to play a video
  shouldPlayVideoWithReloadContentURL: (aPlayer: Player, url: string, callback: () => void) => boolean; // Called when player wants to play a video with reload url of content (to avoid timeout token)
  shouldStopVideo: (aPlayer: Player) => boolean; // Called when the user want to stop a video
  shouldPauseVideo: (aPlayer: Player) => boolean; // Called when the user want to pause a video
  shouldSeekVideo: (aPlayer: Player) => boolean; // Called when the user want to seek a video
  isAdVideoPlaying: () => boolean; // Called when the player wants to check if ad is playing
  isPreRollPlaying: () => boolean;
  isMidRollPlaying: () => boolean;
  isPostRollPlaying: () => boolean;
  isPreRollPreparing: () => boolean;
  isMidRollPreparing: () => boolean;
  isPostRollPreparing: () => boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getCurrentAdInfo: () => { [key: string]: any } | undefined;
}

export class AdController {
  adStarted: boolean;
  isActive: () => void;
  getStatistics: () => void;
  VideoPlayerListener: IAdPlayerListener;
  VideoPlayerDelegate: IAdPlayerDelegate;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(initConfig: IAdConfig, playContent: (callback: (url: string) => void) => void) {
    this.adStarted = false;

    const that = this;

    const logger =
      initConfig["logger"] ||
      function (msg: string) {
        Log.ads.log(msg);
      };

    Log.ads.log("[AdController] Creating initConfig :: ", initConfig);

    /** @private */
    const config: IAdConfig = {
      delegate: {
        shouldPlayPreRoll: function (_adController: AdController) {
          return true;
        },
        shouldPlayMidRoll: function (_adController: AdController) {
          return true;
        },
        shouldPlayPostRoll: function (_adController: AdController) {
          return true;
        },
        shouldStopAdVideo: function (_adController: AdController) {
          return false;
        },
        shouldGetUrl: function (_adController: AdController, callback: () => void) {
          if (callback) callback();
          return false;
        },
      },
      listener: {
        adControllerDidStartVideoAd: function (_adController: AdController, _type: string) {
          return false;
        },
        adControllerDidEndVideoAd: function (_adController: AdController, _type: string) {
          return false;
        },
        adControllerDidUpdateProgress: function (
          _adController: AdController,
          _theElapsedSeconds: number,
          _theRemainingSeconds: number
        ) {
          return false;
        },
        adControllerOnEvent: function (_adController: AdController, event: AdControllerEvent) {},
      },

      preRollURL:
        !Array.isArray(initConfig["preRollURL"]) && typeof initConfig["preRollURL"] === "string"
          ? [initConfig["preRollURL"]]
          : initConfig["preRollURL"],
      midRollURL:
        !Array.isArray(initConfig["midRollURL"]) && typeof initConfig["midRollURL"] === "string"
          ? [initConfig["midRollURL"]]
          : initConfig["midRollURL"],
      postRollURL:
        !Array.isArray(initConfig["postRollURL"]) && typeof initConfig["postRollURL"] === "string"
          ? [initConfig["postRollURL"]]
          : initConfig["postRollURL"],

      prerollAdBreak: initConfig["prerollAdBreak"],
      midrollAdBreak: initConfig["midrollAdBreak"],
      postrollAdBreak: initConfig["postrollAdBreak"],

      supportedVideoTypes: {
        "video/mp4": true,
      },

      maxWrapper: 6,
      maxAllowedBitrate: null,

      supportedImagesTypes: {
        "image/png": true,
        "image/jpeg": true,
        "image/gif": true,
      },

      companionDiv: initConfig["companionDiv"],
      defaultComapanionImg: null,
    };

    if (initConfig["delegate"]) {
      config.delegate.shouldPlayPreRoll =
        initConfig["delegate"]["shouldPlayPreRoll"] || config.delegate.shouldPlayPreRoll;
      config.delegate.shouldPlayMidRoll =
        initConfig["delegate"]["shouldPlayMidRoll"] || config.delegate.shouldPlayMidRoll;
      config.delegate.shouldPlayPostRoll =
        initConfig["delegate"]["shouldPlayPostRoll"] || config.delegate.shouldPlayPostRoll;
      config.delegate.shouldStopAdVideo =
        initConfig["delegate"]["shouldStopAdVideo"] || config.delegate.shouldStopAdVideo;
      config.delegate.shouldGetUrl = initConfig["delegate"]["shouldGetUrl"] || config.delegate.shouldGetUrl;
    }

    if (initConfig["listener"]) {
      config.listener.adControllerDidUpdateProgress = initConfig["listener"]["adControllerDidUpdateProgress"];
      config.listener.adControllerDidStartVideoAd = initConfig["listener"]["adControllerDidStartVideoAd"];
      config.listener.adControllerDidEndVideoAd = initConfig["listener"]["adControllerDidEndVideoAd"];
      config.listener.adControllerOnEvent = initConfig["listener"]["adControllerOnEvent"];
    }

    config.maxAdsPerVast = initConfig["maxAdsPerVast"] || null;

    if (initConfig["supportedVideoTypes"]) {
      if (!Array.isArray(initConfig["supportedVideoTypes"]) && typeof initConfig["supportedVideoTypes"] === "string") {
        initConfig["supportedVideoTypes"] = [initConfig["supportedVideoTypes"]];
      }
      config.supportedVideoTypes = {};
      for (let i = 0; i < initConfig["supportedVideoTypes"].length; i++) {
        config.supportedVideoTypes[initConfig["supportedVideoTypes"][i]] = true;
      }
    }

    const ajaxGet = (vastURL: string, callback: (xml?: Document | null) => void): void => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        logger("[AdController] ajaxGet getting xml");
        callback(xhr.responseXML);
      };

      xhr.onerror = error => {
        logger("[AdController] Error while getting VAST: " + error);
        callback();
      };

      xhr.open("GET", vastURL);
      xhr.responseType = "document";
      xhr.send();
    };

    /**
     ** AdController state machine
     ** @private
     */
    const States = {
      //--- Waiting to be called by player
      WAITING_FOR_PLAY: {
        _description: "Idle",
        name: "WAITING_FOR_PLAY",
        isAdBreak: false,

        onEnter: function () {
          videoPlayer = undefined;
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          videoPlayer = aPlayer;
          logger("[AdController] [WAITING_FOR_PLAY] " + url + "shouldPlayVideo :: " + vastEmpty);
          if (config.delegate.shouldPlayPreRoll(that) && config.prerollAdBreak) {
            // Time to prepare preroll
            contentURL = url;
            transitionToState(States.PREPARING_PREROLL);
            return false;
          } else {
            // No preroll
            transitionToState(States.PLAYING_CONTENT);
            return true;
          }
        },

        onVideoEnd: function () {
          // Fix play an ads playlist
          return false;
        },

        onExit: function () {
          // Fix play an ads playlist
          return false;
        },
      },

      //--- Preparing the preroll (requesting VAST..)
      PREPARING_PREROLL: {
        _description: "Preparing preroll",
        name: "PREPARING_PREROLL",
        isAdBreak: true,

        onEnter: function () {
          if (config.prerollAdBreak) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            readVASTFromJson(config.prerollAdBreak, function (adBreak: any) {
              logger("[AdController] adBreak " + !!adBreak);
              currentAd = {
                ...adBreak,
                timeOffset: "pre-roll",
              };

              if (adBreak) {
                logger("[AdController] adBreak.videoURL " + !!adBreak.videoURL);
                //currentAd = adBreak;
                sendImpressions();
                transitionToState(currentAd?.videoURL ? States.PLAYING_PREROLL : States.PLAYING_CONTENT);
              } else {
                if (config.preRollURL && indexPreRoll < config.preRollURL.length - 1) {
                  logger("************************************************************************");
                  logger("[AdController] no data for this vast trying to launch next vast!!! ");
                  logger("************************************************************************");
                  indexPreRoll++;
                  currentState.onEnter();
                } else {
                  // Could not prepare preroll
                  transitionToState(States.PLAYING_CONTENT);
                }
              }
            });
          } else if (config.preRollURL) {
            let preRollVastUrl = "";
            preRollVastUrl = config.preRollURL ? config.preRollURL[indexPreRoll] : "";
            preRollVastUrl = preRollVastUrl.replace("{{timestamp}}", Date());
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loadVAST(preRollVastUrl, function (adBreak: any) {
              logger("[AdController] adBreak " + !!adBreak);
              currentAd = {
                ...adBreak,
                timeOffset: "pre-roll",
              };

              if (adBreak) {
                logger("[AdController] adBreak.videoURL " + !!adBreak.videoURL);
                //currentAd = adBreak;
                sendImpressions();
                transitionToState(currentAd?.videoURL ? States.PLAYING_PREROLL : States.PLAYING_CONTENT);
              } else if (config.preRollURL) {
                if (indexPreRoll < config.preRollURL.length - 1) {
                  logger("************************************************************************");
                  logger("[AdController] no data for this vast trying to launch next vast!!! ");
                  logger("************************************************************************");
                  indexPreRoll++;
                  currentState.onEnter();
                } else {
                  // Could not prepare preroll
                  transitionToState(States.PLAYING_CONTENT);
                }
              }
            });
          }
        },

        onVideoEnd: function () {
          // Fix play an ads playlist
          return false;
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          // Keep content URL for playing after ad break
          contentURL = url;
          return false;
        },
      },

      //--- Playing the preroll video
      PLAYING_PREROLL: {
        _description: "Playing preroll",
        name: "PLAYING_PREROLL",
        isAdBreak: true,

        onEnter: function () {
          logger("ADCONTROLLER: on Enter");
          playAdVideo(currentAd?.videoURL ?? "");
          if (config.listener.adControllerDidStartVideoAd) {
            config.listener.adControllerDidStartVideoAd(that, "pre-roll");
          }
        },

        onExit: function () {
          logger("ADCONTROLLER: on Exit");
          statistics.totalDuration += elapsedSeconds;
          statistics.preRollDuration += elapsedSeconds;
          currentVideoDuration = 0;
          currentAd = undefined;
        },

        onVideoEnd: function () {
          onAdBreakTimeUpdate(elapsedSeconds + remainingSeconds, 0);

          statistics.preRollVideoCount++;

          if (config.listener.adControllerDidEndVideoAd) {
            config.listener.adControllerDidEndVideoAd(that, "pre-roll");
          }

          // Next ad in vast
          if (iCurrentAd < iMaxAd - 1) {
            logger("ADCONTROLLER PLAYING_PREROLL : Next ad in vast");
            iCurrentAd++;
            indexPreRoll++;
            transitionToState(States.PREPARING_PREROLL);
          } else {
            logger("ADCONTROLLER PLAYING_PREROLL : Launch content");
            // Launch content
            iCurrentAd = 0;
            indexPreRoll = 0;
            that.adStarted = false;
            transitionToState(States.PLAYING_CONTENT);
            // iCurrentAd = 0;
            // if (config.preRollURL && indexPreRoll < config.preRollURL.length - 1) {
            //   indexPreRoll++;
            //   transitionToState(States.PREPARING_PREROLL);
            // } else {
            //   // Launch content
            //   indexPreRoll = 0;
            //   that.adStarted = false;
            //   transitionToState(States.PLAYING_CONTENT);
            // }
          }

          return false;
        },

        shouldPlayVideo: function (_aPlayer: Player, _url: string) {
          return true;
        },
      },

      //--- Preparing the midroll (requesting VAST..)
      PREPARING_MIDROLL: {
        _description: "Preparing midroll",
        name: "PREPARING_MIDROLL",
        isAdBreak: true,

        onEnter: function () {
          if (config.midrollAdBreak && timeOffset && config.midrollAdBreak[timeOffset]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            readVASTFromJson(config.midrollAdBreak, function (adBreak: any) {
              logger("[AdController] adBreak " + !!adBreak);
              currentAd = {
                ...adBreak,
                timeOffset: "mid-roll",
              };

              if (adBreak) {
                logger("[AdController] adBreak.videoURL " + !!adBreak.videoURL);
                //currentAd = adBreak;
                sendImpressions();
                if (currentAd?.videoURL) {
                  transitionToState(States.PLAYING_MIDROLL);
                } else {
                  if (timeOffset) {
                    delete config.midrollAdBreak?.[timeOffset];
                  }
                  transitionToState(States.PLAYING_CONTENT);
                }
              } else {
                if (midRollURL.length && indexMidRoll < midRollURL.length - 1) {
                  logger("************************************************************************");
                  logger("[AdController] no data for this vast trying to launch next vast!!! ");
                  logger("************************************************************************");
                  indexMidRoll++;
                  currentState.onEnter();
                } else {
                  // Could not prepare preroll
                  if (timeOffset) {
                    delete config.midrollAdBreak?.[timeOffset];
                  }
                  transitionToState(States.PLAYING_CONTENT);
                }
              }
            });
          } else if (config.midRollURL) {
            // TODO : GL remove this code, config.preRollURL/midRollURL/postRollURL always undefined
            let midRollVastUrl = "";
            midRollVastUrl = config.midRollURL ? config.midRollURL[indexMidRoll] : "";
            midRollVastUrl = midRollVastUrl.replace("{{timestamp}}", Date());

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            loadVAST(midRollVastUrl, function (adBreak: any) {
              logger("[AdController] adBreak " + !!adBreak);
              currentAd = {
                ...adBreak,
                timeOffset: "mid-roll",
              };

              if (adBreak) {
                logger("[AdController] adBreak.videoURL " + !!adBreak.videoURL);
                //currentAd = adBreak;
                sendImpressions();
                transitionToState(currentAd?.videoURL ? States.PLAYING_MIDROLL : States.PLAYING_CONTENT);
              } else {
                if (indexMidRoll < midRollURL.length - 1) {
                  logger("************************************************************************");
                  logger("[AdController] no data for this vast trying to launch next vast!!! ");
                  logger("************************************************************************");
                  indexMidRoll++;
                  currentState.onEnter();
                } else {
                  // Could not prepare midroll
                  if (timeOffset) {
                    delete config.midrollAdBreak?.[timeOffset];
                  }
                  transitionToState(States.PLAYING_CONTENT);
                }
              }
            });
          } else {
            // Security : we should not reach this part of code, but by security we resume the CONTENT
            if (timeOffset) {
              delete config.midrollAdBreak?.[timeOffset];
            }
            transitionToState(States.PLAYING_CONTENT);
          }
        },

        onVideoEnd: function () {
          // Fix play an ads playlist
          transitionToState(States.PLAYING_CONTENT);
          return false;
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          // Keep content URL for playing after ad break
          contentURL = url;
          return false;
        },
      },

      //--- Playing the midroll video
      PLAYING_MIDROLL: {
        _description: "Playing midroll",
        name: "PLAYING_MIDROLL",
        isAdBreak: true,

        onEnter: function () {
          playAdVideo(currentAd?.videoURL ?? "");
          if (config.listener.adControllerDidStartVideoAd) {
            config.listener.adControllerDidStartVideoAd(that, "mid-roll");
          }
        },

        onExit: function () {
          statistics.totalDuration += elapsedSeconds;
          statistics.preRollDuration += elapsedSeconds;
          currentVideoDuration = 0;
          currentAd = undefined;
        },

        onVideoEnd: function () {
          onAdBreakTimeUpdate(elapsedSeconds + remainingSeconds, 0);

          statistics.midRollVideoCount++;

          if (config.listener.adControllerDidEndVideoAd) {
            config.listener.adControllerDidEndVideoAd(that, "mid-roll");
          }

          // Next ad in vast
          if (iCurrentAd < iMaxAd - 1) {
            iCurrentAd++;
            indexMidRoll++;
            logger("ADCONTROLLER transitionToState: PREPARING_MIDROLL");
            transitionToState(States.PREPARING_MIDROLL);
          } else {
            indexMidRoll = 0;
            that.adStarted = false;
            logger(
              "ADCONTROLLER transitionToState: PLAYING_CONTENT we played all midRoll of timeoffset : " + timeOffset
            );
            if (timeOffset) {
              delete config.midrollAdBreak?.[timeOffset];
            }

            const callbackPlayContent = (url: string) => {
              contentURL = url;
              transitionToState(States.PLAYING_CONTENT);
            };

            playContent(callbackPlayContent);
          }

          return false;
        },

        shouldPlayVideo: function (_aPlayer: Player, _url: string) {
          return true;
        },
      },

      //--- Playing the content video
      PLAYING_CONTENT: {
        _description: "Playing content",
        name: "PLAYING_CONTENT",

        onEnter: function () {
          indexPreRoll = 0;
          iCurrentAd = 0;

          if (videoPlayer && contentURL) {
            const player = videoPlayer;
            logger("[AdController] onEnter contentURL :: " + contentURL);
            deferOp(function () {
              if (player.url$ && contentURL) {
                logger("[AdController] onEnter setSrc :: " + contentURL);

                if (contentPausePosition > 0) {
                  logger("[AdController] onEnter setSeekTime :: " + contentPausePosition);
                  player.setSeekTime(contentPausePosition);
                }

                player.setSrc(contentURL);
              }

              logger("[AdController] onEnter currentAd :: " + currentAd);
            });
          }
        },

        onExit: function () {
          statistics.totalDuration += elapsedSeconds;
          statistics.videoContentDuration += elapsedSeconds;
          currentVideoDuration = 0;
        },

        onVideoEnd: function () {
          statistics.contentVideoCount++;

          if (config.postRollURL && config.postRollURL[indexPostRoll] && config.delegate.shouldPlayPostRoll(that)) {
            // Time to prepare postroll
            transitionToState(States.PREPARING_POSTROLL);
            return false;
          } else {
            // No postroll
            transitionToState(States.FINISHING);
            return true;
          }
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          if (url === contentURL) {
            contentURL = undefined;
            return true;
          }

          // Handling interruptions
          if (contentURL === undefined) {
            const that = this;
            deferOp(function () {
              videoPlayer = aPlayer;
              contentURL = url;
              that.onVideoEnd();
            });
          }
          return false;
        },
      },

      //--- Preparing the postroll (requesting VAST..)
      PREPARING_POSTROLL: {
        _description: "Preparing postroll",
        name: "PREPARING_POSTROLL",
        isAdBreak: true,

        onEnter: function () {
          let postRollVastUrl = "";
          postRollVastUrl = config.postRollURL ? config.postRollURL[indexPostRoll] : "";
          postRollVastUrl = postRollVastUrl.replace("{{timestamp}}", Date());
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          loadVAST(postRollVastUrl, function (adBreak: any) {
            currentAd = {
              ...adBreak,
              timeOffset: "post-roll",
            };

            if (adBreak) {
              //currentAd = adBreak;
              sendImpressions();
              transitionToState(currentAd?.videoURL ? States.PLAYING_POSTROLL : States.FINISHING);
            } else if (config.postRollURL) {
              if (indexPostRoll < config.postRollURL.length - 1) {
                logger("************************************************************************");
                logger("[AdController] no data for this vast trying to launch next vast!!! ");
                logger("************************************************************************");
                indexPostRoll++;
                currentState.onEnter();
              } else {
                // Could not prepare postroll
                transitionToState(States.FINISHING);
              }
            }
          });
        },

        onVideoEnd: function () {
          // Fix play an ads playlist
          return false;
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          // Keep content URL for playing after ad break
          contentURL = url;
          return false;
        },
      },

      //--- Playing the postroll video
      PLAYING_POSTROLL: {
        _description: "Playing postroll",
        name: "PLAYING_POSTROLL",
        isAdBreak: true,

        onEnter: function () {
          playAdVideo(currentAd?.videoURL ?? "");
          if (config.listener.adControllerDidStartVideoAd) {
            config.listener.adControllerDidStartVideoAd(that, "post-roll");
          }
        },

        onExit: function () {
          statistics.totalDuration += elapsedSeconds;
          statistics.postRollDuration += elapsedSeconds;
          currentVideoDuration = 0;
          currentAd = undefined;
        },

        onVideoEnd: function () {
          onAdBreakTimeUpdate(elapsedSeconds + remainingSeconds, 0);
          statistics.postRollVideoCount++;

          if (config.listener.adControllerDidEndVideoAd) {
            config.listener.adControllerDidEndVideoAd(that, "post-roll");
          }

          // Next ad in vast
          if (iCurrentAd < iMaxAd - 1) {
            iCurrentAd++;
            transitionToState(States.PREPARING_POSTROLL);
          } else {
            // Next vast in postroll playlist
            iCurrentAd = 0;
            if (config.postRollURL && indexPostRoll < config.postRollURL.length - 1) {
              indexPostRoll++;
              transitionToState(States.PREPARING_POSTROLL);
              return false;
            } else {
              // Launch content
              indexPostRoll = 0;
              that.adStarted = false;
              transitionToState(States.FINISHING);
              return true;
            }
          }
        },

        shouldPlayVideo: function (aPlayer: Player, url: string) {
          if (url !== currentAd?.videoURL) {
            // Keep content URL for playing after ad break
            logger("ADCONTROLLER: currentAd?.videoURL " + currentAd?.videoURL);
            videoPlayer = aPlayer;
            contentURL = url;
            return false;
          }

          return true;
        },
      },

      //--- Clean up and decide what to do next
      FINISHING: {
        _description: "Finishing",
        name: "FINISHING",

        onEnter: function () {},

        onVideoEnd: function () {
          // Fix play an ads playlist
          return false;
        },

        shouldPlayVideo: function (_aPlayer: Player, _url: string) {
          return true;
        },
      },
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const transitionToState = function (newState: any) {
      if (currentState && currentState.onExit) currentState.onExit();
      logger(
        "[AdController] Transitioning from '" + currentState._description + "' to '" + newState._description + "'"
      );
      currentState = newState;
      if (currentState.onEnter) currentState.onEnter();
    };

    let currentState = States.WAITING_FOR_PLAY;
    currentState.onEnter();

    /** @protected */
    this.isActive = function () {
      return currentState !== States.WAITING_FOR_PLAY;
    };

    const statistics: { [key: string]: number } = {
      contentVideoCount: 0,
      preRollVideoCount: 0,
      postRollVideoCount: 0,
      totalDuration: 0,
      preRollDuration: 0,
      postRollDuration: 0,
      videoContentDuration: 0,
    };

    // Variables
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentAd: IAdBreak | undefined;

    let indexPreRoll = 0;
    let indexMidRoll = 0;
    let timeOffset = 0;
    let indexPostRoll = 0;
    let iCurrentAd = 0;
    let iMaxAd = 0;
    let jsonSaved: string[] | null | undefined = null;
    let contentPausePosition = 0;
    const midRollURL: string | string[] = [];

    /** @type{DOTSCREEN.Player} */
    let videoPlayer: Player | undefined;
    let contentURL: string | undefined;
    let adBreakProgression: number;

    let currentVideoDuration = 0;
    let elapsedSeconds: number;
    let remainingSeconds: number;

    let vastEmpty = false;

    /** @protected */
    this.getStatistics = function () {
      return {
        contentVideoCount: statistics.contentVideoCount,
        preRollVideoCount: statistics.preRollVideoCount,
        postRollVideoCount: statistics.postRollVideoCount,
        totalDuration: statistics.totalDuration,
        preRollDuration: statistics.preRollDuration,
        postRollDuration: statistics.postRollDuration,
        videoContentDuration: statistics.videoContentDuration,
      };
    };

    const onAdBreakTimeUpdate = function (theElapsedSeconds: number, theRemainingSeconds: number) {
      if (config.listener.adControllerDidUpdateProgress) {
        config.listener.adControllerDidUpdateProgress(
          that,
          Math.ceil(theElapsedSeconds),
          Math.ceil(theRemainingSeconds)
        );
      }

      if (adBreakProgression < 0) {
        triggerTrackingEvent("start");
        adBreakProgression = 0;
      }

      if (adBreakProgression < 25 && theElapsedSeconds >= theRemainingSeconds / 3) {
        triggerTrackingEvent("firstQuartile");
        adBreakProgression = 25;
      }

      if (adBreakProgression < 50 && theElapsedSeconds >= theRemainingSeconds) {
        triggerTrackingEvent("midpoint");
        adBreakProgression = 50;
      }

      if (adBreakProgression < 75 && theElapsedSeconds >= theRemainingSeconds * 3) {
        triggerTrackingEvent("thirdQuartile");
        adBreakProgression = 75;
      }

      if (adBreakProgression < 100 && theRemainingSeconds === 0) {
        triggerTrackingEvent("complete");
        adBreakProgression = 100;
      }
    };

    /**
     ** The video player listener
     ** @protected @expose
     */
    this.VideoPlayerListener = {
      // Called every second by the player
      onTimeUpdate: function (theElapsedSeconds: number, theRemainingSeconds: number, url: string) {
        currentVideoDuration = theElapsedSeconds + theRemainingSeconds;
        remainingSeconds = theRemainingSeconds;
        elapsedSeconds = theElapsedSeconds;

        if (currentState.isAdBreak && currentVideoDuration > 0) {
          onAdBreakTimeUpdate(theElapsedSeconds, theRemainingSeconds);
          return false;
        } else if (config.midrollAdBreak && config.delegate.shouldPlayMidRoll(that)) {
          // Check midroll
          const _timeOffset = Math.floor(theElapsedSeconds);

          for (const key in config.midrollAdBreak) {
            if (parseInt(key) <= _timeOffset) {
              contentPausePosition = theElapsedSeconds;
              contentURL = url;
              timeOffset = parseInt(key);
              transitionToState(States.PREPARING_MIDROLL);
              return true;
            }
          }
          return true;
        }
        return true;
      },

      // Called by the player when the video ended
      onVideoEnd: function () {
        logger("ADCONTROLLER " + currentState.name + " : on Video End");
        elapsedSeconds += remainingSeconds;
        remainingSeconds = 0;
        return currentState.onVideoEnd ? currentState.onVideoEnd() : true;
      },

      // Called by the player when the video was forced to end
      onVideoError: function () {
        logger("[AdController] " + currentState.name + " : onVideoError");
        return currentState.onVideoEnd ? currentState.onVideoEnd() : true;
      },

      // Called by the player when the video was forced to end
      onVideoAbort: function () {
        logger("[AdController] " + currentState.name + " : onVideoAbort");
        if (currentState.isAdBreak != undefined) {
          currentState.isAdBreak = false;
        }

        indexPreRoll = 0;
        indexPostRoll = 0;
        iCurrentAd = 0;
        transitionToState(States.WAITING_FOR_PLAY);
        return true;
      },
    };

    /**
     ** The video player delegate
     ** @protected
     */
    this.VideoPlayerDelegate = {
      // Called when player want to play a video
      shouldPlayVideo: function (player, url) {
        const result = currentState.shouldPlayVideo(player, url);
        logger("[AdController] " + currentState.name + " shouldPlayVideo " + url + ": " + result);
        return result;
      },

      // Called when player want to play a video with reload url of content (to avoid timeout token)
      shouldPlayVideoWithReloadContentURL: function (player, url, callback) {
        const result = currentState.shouldPlayVideo(player, url);
        logger("[AdController] shouldPlayVideoWithReloadContentURL " + url + ": " + result);
        if (result) {
          if (!currentState.isAdBreak) {
            config.delegate.shouldGetUrl(that, callback);
          } else if (callback) {
            callback();
          }
        }
        return result;
      },

      // Called when the user want to stop a video
      shouldStopVideo: function (_player) {
        logger(
          "[AdController] shouldStopVideo? " +
            String(!currentState.isAdBreak || config.delegate.shouldStopAdVideo(that))
        );
        // By default only allow stop for content video
        return !currentState.isAdBreak || config.delegate.shouldStopAdVideo(that);
      },

      // Called when the user want to pause a video
      shouldPauseVideo: function (_player) {
        logger("[AdController] shouldPauseVideo? " + !currentState.isAdBreak);
        // Only allow pause for content
        return !currentState.isAdBreak;
      },

      // Called when the user want to seek a video
      shouldSeekVideo: function (_player) {
        logger("[AdController] shouldSeekVideo? " + !currentState.isAdBreak);
        // Only allow seek for content
        return !currentState.isAdBreak;
      },

      // Called when the player want to check if ad is playing
      isAdVideoPlaying: function () {
        return currentState.isAdBreak != undefined && currentState.isAdBreak == true;
      },

      isPreRollPlaying: function () {
        return currentState.name === "PLAYING_PREROLL";
      },

      isMidRollPlaying: function () {
        return currentState.name === "PLAYING_MIDROLL";
      },

      isPostRollPlaying: function () {
        return currentState.name === "PLAYING_POSTROLL";
      },

      isPreRollPreparing: function () {
        return currentState.name === "PREPARING_PREROLL";
      },

      isMidRollPreparing: function () {
        return currentState.name === "PREPARING_MIDROLL";
      },

      isPostRollPreparing: function () {
        return currentState.name === "PREPARING_POSTROLL";
      },

      getCurrentAdInfo: function (): IAdBreak | undefined {
        return currentAd;
      },
    };

    /*
     ** Display companion ad if available
     */
    const playCompanionAd = function () {
      if (currentAd?.companionStaticResource && config.companionDiv) {
        config.companionDiv.id = "AdFrame";

        if (!currentAd.companionStaticResource.isTracked && currentAd.companionStaticResource.src) {
          config.companionDiv.innerHTML = "";
          const img = document.createElement("img");
          img.src = currentAd.companionStaticResource.src;
          currentAd.companionStaticResource.isTracked = true;
          //img.width = this.companionStaticResource.width;
          //img.height = this.companionStaticResource.height;
          config.companionDiv.appendChild(img);
        }
      }
    };

    let wrapperCount = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let wrapperAdBreak: { impressionURLs: Array<string>; eventTrackers: any };

    /*
     ** Load VAST
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadVAST = function (adUrl: string, callback: (adBreak: any) => void) {
      const sm_random = Math.ceil(10000000 * Math.random());
      const rndAdUrl = adUrl.replace(/#RANDOM#|\{RANDOM\}|\[RANDOM\]/i, String(sm_random));

      logger("[AdController] Loading VAST from " + rndAdUrl);

      // If several ads in vast, do not reload the file

      if (iCurrentAd > 0) {
        let adBreak;
        try {
          adBreak = processVAST(jsonSaved, iCurrentAd);
        } catch (e) {
          logger("[AdController] [adBreak] Exception while loading VAST: " + e);
        }
        callback(adBreak);
        return;
      }

      ajaxGet(rndAdUrl, function (xmlDoc) {
        let adBreak: Omit<IAdBreak, "timeOffset"> | null = null;

        if (xmlDoc) {
          try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let jsonFinal: any = processXML(xmlDoc.documentElement);

            if (jsonFinal["vmap:AdBreak"] && jsonFinal["vmap:AdBreak"].length) {
              jsonFinal = jsonFinal["vmap:AdBreak"][0];
            }

            if (jsonFinal["vmap:AdSource"] && jsonFinal["vmap:AdSource"].length) {
              jsonFinal = jsonFinal["vmap:AdSource"][0];
            }

            if (jsonFinal["vmap:VASTAdData"] && jsonFinal["vmap:VASTAdData"].length) {
              jsonFinal = jsonFinal["vmap:VASTAdData"][0];
            }

            if (jsonFinal["VAST"] && jsonFinal["VAST"].length) {
              jsonFinal = jsonFinal["VAST"][0];
            }

            if (jsonFinal && jsonFinal["Ad"] && config.maxAdsPerVast) {
              iMaxAd =
                config.maxAdsPerVast !== null && config.maxAdsPerVast < jsonFinal["Ad"].length
                  ? config.maxAdsPerVast
                  : jsonFinal["Ad"].length;
            } else {
              iMaxAd = 0;
            }

            const wrapperExist = checkWrapper(jsonFinal);
            if (wrapperExist) {
              loadVAST(wrapperExist, callback);
              return;
            }

            adBreak = processVAST(jsonFinal, iCurrentAd);

            if (adBreak && wrapperAdBreak) {
              adBreak.impressionURLs = adBreak.impressionURLs.concat(wrapperAdBreak.impressionURLs);
              adBreak.eventTrackers["creativeView"] = adBreak.eventTrackers["creativeView"].concat(
                wrapperAdBreak.eventTrackers["creativeView"]
              );
              adBreak.eventTrackers["start"] = adBreak.eventTrackers["start"].concat(
                wrapperAdBreak.eventTrackers["start"]
              );
              adBreak.eventTrackers["midpoint"] = adBreak.eventTrackers["midpoint"].concat(
                wrapperAdBreak.eventTrackers["midpoint"]
              );
              adBreak.eventTrackers["firstQuartile"] = adBreak.eventTrackers["firstQuartile"].concat(
                wrapperAdBreak.eventTrackers["firstQuartile"]
              );
              adBreak.eventTrackers["thirdQuartile"] = adBreak.eventTrackers["thirdQuartile"].concat(
                wrapperAdBreak.eventTrackers["thirdQuartile"]
              );
              adBreak.eventTrackers["complete"] = adBreak.eventTrackers["complete"].concat(
                wrapperAdBreak.eventTrackers["complete"]
              );
            }

            jsonSaved = jsonFinal;
          } catch (e) {
            logger("[AdController] [ajaxGet] Exception while loading VAST: " + e);
          }
        }

        callback(adBreak);
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const readVASTFromJson = function (json: any, callback: (adBreak: any) => void) {
      // If several ads in vast, do not reload the file

      if (iCurrentAd > 0) {
        let adBreak;
        try {
          adBreak = processVAST(jsonSaved, iCurrentAd);
        } catch (e) {
          logger("[AdController] [adBreak] Exception while loading VAST: " + e);
        }
        callback(adBreak);
        return;
      }

      let adBreak: Omit<IAdBreak, "timeOffset"> | null = null;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let jsonFinal: any = json[timeOffset];

      if (jsonFinal["vmap:AdBreak"] && jsonFinal["vmap:AdBreak"].length) {
        jsonFinal = jsonFinal["vmap:AdBreak"][0];
      }

      if (jsonFinal["vmap:AdSource"] && jsonFinal["vmap:AdSource"].length) {
        jsonFinal = jsonFinal["vmap:AdSource"][0];
      }

      if (jsonFinal["vmap:VASTAdData"] && jsonFinal["vmap:VASTAdData"].length) {
        jsonFinal = jsonFinal["vmap:VASTAdData"][0];
      }

      if (jsonFinal["VAST"] && jsonFinal["VAST"].length) {
        jsonFinal = jsonFinal["VAST"][0];
      }

      //console.log("[AdController][VAST]", jsonFinal);

      if (jsonFinal && jsonFinal["Ad"] && config.maxAdsPerVast) {
        iMaxAd =
          config.maxAdsPerVast !== null && config.maxAdsPerVast < jsonFinal["Ad"].length
            ? config.maxAdsPerVast
            : jsonFinal["Ad"].length;
      } else {
        iMaxAd = 0;
      }

      const wrapperExist = checkWrapper(jsonFinal);
      if (wrapperExist) {
        loadVAST(wrapperExist, callback);
        return;
      }

      adBreak = processVAST(jsonFinal, iCurrentAd);

      if (adBreak && wrapperAdBreak) {
        adBreak.impressionURLs = adBreak.impressionURLs.concat(wrapperAdBreak.impressionURLs);
        adBreak.eventTrackers["creativeView"] = adBreak.eventTrackers["creativeView"].concat(
          wrapperAdBreak.eventTrackers["creativeView"]
        );
        adBreak.eventTrackers["start"] = adBreak.eventTrackers["start"].concat(wrapperAdBreak.eventTrackers["start"]);
        adBreak.eventTrackers["midpoint"] = adBreak.eventTrackers["midpoint"].concat(
          wrapperAdBreak.eventTrackers["midpoint"]
        );
        adBreak.eventTrackers["firstQuartile"] = adBreak.eventTrackers["firstQuartile"].concat(
          wrapperAdBreak.eventTrackers["firstQuartile"]
        );
        adBreak.eventTrackers["thirdQuartile"] = adBreak.eventTrackers["thirdQuartile"].concat(
          wrapperAdBreak.eventTrackers["thirdQuartile"]
        );
        adBreak.eventTrackers["complete"] = adBreak.eventTrackers["complete"].concat(
          wrapperAdBreak.eventTrackers["complete"]
        );
      }

      jsonSaved = jsonFinal;

      callback(adBreak);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const checkWrapper = function (vastDoc: any) {
      if (config.maxWrapper && wrapperCount > config.maxWrapper) {
        wrapperAdBreak = {
          impressionURLs: [],
          eventTrackers: {
            creativeView: [],
            start: [],
            midpoint: [],
            firstQuartile: [],
            thirdQuartile: [],
            complete: [],
          },
        };

        return false;
      }
      if (vastDoc && "Ad" in vastDoc && vastDoc["Ad"][0] && "Wrapper" in vastDoc["Ad"][0]) {
        if (wrapperCount == 0) {
          wrapperAdBreak = {
            impressionURLs: [],
            eventTrackers: {
              creativeView: [],
              start: [],
              midpoint: [],
              firstQuartile: [],
              thirdQuartile: [],
              complete: [],
            },
          };
        }

        wrapperCount++;

        const wrapperAd = vastDoc["Ad"][0]["Wrapper"][0];

        const wrapperVastURL = wrapperAd ? wrapperAd["VASTAdTagURI"][0].$ : false;

        // Impressions
        if ("Impression" in wrapperAd) {
          const impressionsSource = wrapperAd["Impression"];
          for (let idx = 0; idx < impressionsSource.length; idx++) {
            wrapperAdBreak.impressionURLs.push(impressionsSource[idx].$);
          }
        }

        const creatives = wrapperAd["Creatives"][0];
        const creative = creatives !== undefined ? creatives["Creative"][0] : undefined;
        if (creative !== undefined && "Linear" in creative) {
          const linearAd = creative["Linear"][0];

          // Event trackers
          //var trackingEvents = linearAd['TrackingEvents'][0]['Tracking'] ? linearAd['TrackingEvents'][0]['Tracking'] : [];
          const trackingEvents =
            linearAd["TrackingEvents"] && linearAd["TrackingEvents"][0] && linearAd["TrackingEvents"][0]["Tracking"]
              ? linearAd["TrackingEvents"][0]["Tracking"]
              : [];

          if (trackingEvents.length > 0) {
            for (let i = 0; i < trackingEvents.length; ++i) {
              const tracker = wrapperAdBreak.eventTrackers[trackingEvents[i]["event"]];
              if (tracker) {
                tracker.push(trackingEvents[i].$);
              } else {
                logger("[AdController] Ignoring tracker type '" + trackingEvents[i]["event"] + "'");
              }
            }
          }
        }
        return wrapperVastURL;
      }
    };

    /*
     ** Extract URLs from VAST
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const processVAST = (vastDoc: any, iAd: number): Omit<IAdBreak, "timeOffset"> | null => {
      wrapperCount = 0;

      if (!vastDoc || !("Ad" in vastDoc) || !("InLine" in vastDoc["Ad"][iAd])) {
        logger("[AdController] Got empty VAST");
        vastEmpty = true;
        return null;
      }

      vastEmpty = false;

      const inlineAd = vastDoc["Ad"][iAd]["InLine"][0];
      const countAd = vastDoc["Ad"].length;

      const adBreak: Omit<IAdBreak, "timeOffset"> = {
        impressionURLs: [],

        id: vastDoc["Ad"][iAd]["id"],
        duration: 0,
        name: inlineAd["AdTitle"][0].$,
        index: iAd,
        count: countAd > iMaxAd ? iMaxAd : countAd,
        videoURL: null,
        eventTrackers: {
          creativeView: [],
          start: [],
          midpoint: [],
          firstQuartile: [],
          thirdQuartile: [],
          complete: [],
        },
      };

      // Impressions
      if ("Impression" in inlineAd) {
        const impressionsSource = inlineAd["Impression"];
        for (let idx = 0; idx < impressionsSource.length; idx++) {
          const impression = impressionsSource[idx].$;
          adBreak.impressionURLs.push(impression);
        }
      }

      const creatives = inlineAd["Creatives"][0];
      let creative = creatives !== undefined ? creatives["Creative"][0] : undefined;

      if (creative !== undefined && "Linear" in creative) {
        const linearAd = creative["Linear"][0];
        adBreak.duration = durationToSeconds(linearAd["Duration"][0].$);

        // Event trackers
        const trackingEvents =
          linearAd["TrackingEvents"] && linearAd["TrackingEvents"][0] && linearAd["TrackingEvents"][0]["Tracking"]
            ? linearAd["TrackingEvents"][0]["Tracking"]
            : [];
        if (adBreak.eventTrackers && trackingEvents.length > 0) {
          for (let i = 0; i < trackingEvents.length; ++i) {
            const event = trackingEvents[i]["event"];
            const tracker = adBreak.eventTrackers[event];
            if (tracker) {
              tracker.push(trackingEvents[i].$);
            } else {
              logger("[AdController] Ignoring tracker type '" + trackingEvents[i]["event"] + "'");
            }
          }
        }

        // Videos
        const mediaFiles = linearAd["MediaFiles"][0]["MediaFile"];
        let mediaFile,
          bestBitrate = 0,
          bestSurface = 0,
          bitrate,
          surface;
        for (let ia = 0; ia < mediaFiles.length; ia++) {
          mediaFile = mediaFiles[ia];

          if (config.supportedVideoTypes && config.supportedVideoTypes[mediaFile["type"]]) {
            surface = parseInt(mediaFile["width"], 10) * parseInt(mediaFile["height"], 10);
            bitrate = parseInt(mediaFile["bitrate"], 10);
            logger("[AdController] Found supported media: " + mediaFile.$ + " (bitrate=" + bitrate + ")");

            /*if (surface > bestSurface || bitrate > bestBitrate || (isNaN(surface) && isNaN(bitrate) && !bestBitrate && !bestSurface)) {
              bestBitrate = bitrate;
              bestSurface = surface;
              adBreak.videoURL = mediaFile.$;
            }
            */
            if (
              /*surface > bestSurface || bitrate > bestBitrate*/ (!config.maxAllowedBitrate && bitrate > bestBitrate) ||
              (config.maxAllowedBitrate && bitrate <= config.maxAllowedBitrate) ||
              (isNaN(surface) && isNaN(bitrate) && !bestBitrate && !bestSurface)
            ) {
              bestBitrate = bitrate;
              bestSurface = surface;
              adBreak.videoURL = mediaFile.$;
            }
          } else {
            logger("[AdController] Found unsupported media: " + mediaFile.$);
          }
        }
        logger("[AdController] selected media: " + adBreak.videoURL);
      }

      // Companion Ad
      creative = creatives.length > 1 ? creatives["Creative"][1] : undefined;
      const companionAds = creative && "CompanionAds" in creative ? creative["CompanionAds"][0]["Companion"] : [];

      for (let ib = 0; ib < companionAds.length; ib++) {
        if ("HTMLResource" in companionAds[ib]) {
          if (companionAds[ib]["HTMLResource"][0]["creativeType"] == "text/html") {
            adBreak.companionHTMLResource = companionAds[ib]["HTMLResource"][0].$;
          }
        }

        if ("IFrameResource" in companionAds[ib]) {
          if (companionAds[ib]["IFrameResource"][0]["creativeType"] == "text/html") {
            adBreak.companionIFrameResource = companionAds[ib]["IFrameResource"][0].$;
          }
        }

        if ("StaticResource" in companionAds[ib] && config.supportedImagesTypes) {
          if (config.supportedImagesTypes[companionAds[ib]["StaticResource"][0]["creativeType"]]) {
            adBreak.companionStaticResource = {
              isTracked: false,
              src: companionAds[ib]["StaticResource"][0].$,
              width: companionAds[ib].width,
              height: companionAds[ib].height,
            };
          }

          logger("[AdController] Found supported companion: " + adBreak.companionStaticResource?.src);
        }
      }

      //console.log("[AdController][adBreak] :: ", adBreak);
      return adBreak;
    };

    const durationToSeconds = function (duration: string) {
      let timeInSeconds = 0;
      const time = duration.split(":");

      timeInSeconds = parseInt(time[2]);
      timeInSeconds += parseInt(time[1]) * 60;
      timeInSeconds += parseInt(time[0]) * 60 * 60;

      return timeInSeconds;
    };

    /*
     ** Send all tracked event
     ** @param eventName the name of the event (creativeView, start, midpoint, firstQuartile, thirdQuartile, complete)
     ** Unsuported events: mute, unmute, pause, rewind, resume, fullscreen, expand, collapse, acceptInvitation, close
     */
    const triggerTrackingEvent = function (eventName: AdControllerEvent) {
      if (currentAd?.eventTrackers[eventName]) {
        logger("[AdController] Sending tracking event '" + eventName + "'");
        sendAllBeacon(currentAd.eventTrackers[eventName]);
      } else {
        logger("[AdController] Unsupported tracking event '" + eventName + "'");
      }

      config.listener.adControllerOnEvent(that, eventName);
    };

    /*
     ** Send all impressions
     */
    const sendImpressions = function () {
      if (currentAd?.impressionURLs) {
        logger("[AdController] Sending impressions");
        sendAllBeacon(currentAd?.impressionURLs);
      }

      if (config.customImpressionTracker) {
        config.customImpressionTracker();
      }
    };

    /*
     ** Send a beacon (a simple URL to a 1x1 GIF)
     */
    const sendAllBeacon = function (urls: Array<string>) {
      for (let i = 0; i < urls.length; ++i) {
        sendBeacon(urls[i]);
      }
    };

    /*
     ** Send a beacon (a simple URL to a 1x1 GIF)
     */
    const sendBeacon =
      initConfig["beaconSender"] ||
      function (url: string) {
        logger("[AdController] Sending beacon: " + url);
        try {
          const beacon = new Image(1, 1);
          beacon.src = url;

          beacon.onload = function () {
            beacon.onload = null;
            beacon.onerror = null;
          };

          beacon.onerror = function () {
            //logger("[AdController] Got error from beacon: " + url);
            beacon.onload = null;
            beacon.onerror = null;
          };
        } catch (e) {
          logger("[AdController] Error while sending " + url);
        }
      };

    const playAdVideo = function (url: string) {
      adBreakProgression = -1;
      //nous sommes dans le contexte du video player
      //Pour eviter de perturber le player, possibilit�e de partir en boucke r�cursive infinie
      //aussi pour laisser le player terminer son action correctement, initilaisation de son workflow
      //donc, on l'appelera � partir d'une fonction anonyme plus tard
      const player = videoPlayer;
      logger("ADCONTROLLER: playAdVideo url" + url);
      deferOp(function () {
        playCompanionAd();
        if (player) {
          if (player.setSrcAd) {
            player.setSrcAd(url);
          }
        }
        triggerTrackingEvent("creativeView");
      });
    };

    const deferOp = function (op: () => void) {
      setTimeout(op, 10);
    };

    //===================================== XML HELPERS ==================================================

    // L'objet Node est inconnu pour samsung !
    /** @const @enum */
    const XMLNodeType =
      window.Node && 1 === Node.ELEMENT_NODE
        ? Node
        : {
            ELEMENT_NODE: 1,
            ATTRIBUTE_NODE: 2,
            TEXT_NODE: 3,
            CDATA_SECTION_NODE: 4,
            //		ENTITY_REFERENCE_NODE : 5,
            //		ENTITY_NODE : 6,
            //		PROCESSING_INSTRUCTION_NODE : 7,
            //		COMMENT_NODE : 8,
            //		DOCUMENT_NODE : 9,
            //		DOCUMENT_TYPE_NODE : 10,
            //		DOCUMENT_FRAGMENT_NODE : 11,
            //		NOTATION_NODE : 12
          };

    /*
     ** Convert XML DOM to object
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const processXML = function (xmlNode: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nodes: any = {};

      if (xmlNode.attributes) {
        for (let i = 0; i < xmlNode.attributes.length; i++) {
          const attribute = xmlNode.attributes[i];
          nodes[attribute.nodeName] = [];
          nodes[attribute.nodeName] = attribute.nodeValue;
        }
      }

      if (xmlNode.childNodes) {
        const max = xmlNode.childNodes.length;
        if (xmlNode.hasChildNodes()) {
          for (let j = 0; j < max; j++) {
            const anode = xmlNode.childNodes[j];
            let index = 0;

            switch (anode.nodeType) {
              case XMLNodeType.ELEMENT_NODE:
                if (Object.prototype.hasOwnProperty.call(nodes, anode.nodeName)) {
                  index = nodes[anode.nodeName].length;
                } else {
                  nodes[anode.nodeName] = [];
                }

                nodes[anode.nodeName][index] = processXML(anode);
                break;

              case XMLNodeType.ATTRIBUTE_NODE:
                break;

              case XMLNodeType.CDATA_SECTION_NODE:
              case XMLNodeType.TEXT_NODE:
                // eslint-disable-next-line no-case-declarations
                const value = anode.nodeValue ? anode.nodeValue.replace(/^\s+$/, "") : "";
                if (value !== "") {
                  nodes.$ = value;
                }
                break;
            }
          }
        }
      }

      return nodes;
    };
  }
}
