import { Plugin } from "../datas/plugin";
import { ILoggedInUser } from "../models/user";
import { Spideo } from "./analytics/spideo";

export type LikeOrDislike = 1 | 0 | -1;

export const UpdateContentRating = (
  user: ILoggedInUser,
  videoId: string,
  endDateInS: number | undefined,
  rating: LikeOrDislike
) => {
  ContentRatingsHelper.set(videoId, rating);
  void Spideo.sendRating(user, videoId, rating);
  if (endDateInS !== undefined) {
    Plugin.getInstance()
      .sendRating(user, { type: "content", content: Number(videoId), rating, end_date: endDateInS })
      .then()
      .catch(e => {
        Log.api.error("Transaxtion sendContentRating error", e);
      });
  }
};

export const UpdateTaxonomyRating = (user: ILoggedInUser, id: string, programPath: string, rating: LikeOrDislike) => {
  TaxonomyRatingsHelper.set(id, rating);
  void Spideo.sendRating(user, programPath, rating);
  Plugin.getInstance()
    .sendRating(user, { type: "taxonomy", content: Number(id), rating })
    .then()
    .catch(e => {
      Log.api.error("Transaction sendRating error", e);
    });
};

class RatingsManager {
  private _map: Map<string, LikeOrDislike> = new Map();

  /**
   * Szt rating for id
   * @param id
   * @param rating
   */
  set(id: string, rating: LikeOrDislike) {
    this._map.set(id, rating);
  }

  /**
   * Get rating for id
   * @param id id
   * @returns likeOrDislike
   */
  get(id: string): LikeOrDislike | undefined {
    return this._map.get(id);
  }

  /**
   * clear the ratings list
   */
  clear() {
    this._map.clear();
  }
}

export const ContentRatingsHelper = new RatingsManager();
export const TaxonomyRatingsHelper = new RatingsManager();
