import "./pictoContainerRowView.scss";

import { DOMHelper, View } from "../../bud-lite-tv/src/lib";
import { PlayableItem } from "../models/playableItem";

export const createPlayablePictos = (item: PlayableItem, container: HTMLElement) => {
  //ajout sponsor
  if (item?.sponsored === true) {
    const sponsorBoxElement = DOMHelper.createDivWithParent(container, null, "itemInfoSponsoredBox");
    DOMHelper.createDivWithParent(sponsorBoxElement, null, "sponsoredText", "sponsorisé");
  }

  //ajoute icon csa
  //ajoute icon tout public
  if (item?.metadata.rating?.code !== null && item?.metadata.rating?.code !== undefined) {
    switch (item.metadata.rating.code) {
      case "TP": {
        const pictoTP = DOMHelper.createDivWithParent(container, null, "itemInfoPictoTPBox");
        DOMHelper.createDivWithParent(pictoTP, null, "csaTexte", "tous publics");
        break;
      }
      case "10":
      case "12":
      case "16":
      case "18": {
        const picto = DOMHelper.createDivWithParent(container, null, "itemInfoPictoBox");
        DOMHelper.createDivImg(
          picto,

          null,
          "itemInfoPictoIcon",
          require("~images/pictos_csa/-" + item.metadata.rating.code + ".png")
        );
        break;
      }
      default:
        break;
    }
  }

  //ajout icon audioDescription
  if (item?.metadata?.extras?.is_audio_descripted === true) {
    const audioDescriptionIcon = DOMHelper.createDivWithParent(container, null, "itemInfoAudiaDescriptionBox");
    DOMHelper.createDivImg(
      audioDescriptionIcon,
      null,
      "itemInfoAudioDescriptionIcon",
      require("~images/pictos_options/ad.png")
    );
  }

  //ajout multilangue
  if (item?.metadata?.extras?.is_subtitled === true) {
    const subtitleIcon = DOMHelper.createDivWithParent(container, null, "itemInfoSubtitleBox");
    DOMHelper.createDivImg(
      subtitleIcon,
      null,
      "itemInfoAudioSubtitleIcon",
      require("~images/pictos_options/hearing_impaired.png")
    );
  }

  //ajout multilangue
  if (item?.metadata?.extras?.is_multi_lingual === true) {
    const multilangueIcon = DOMHelper.createDivWithParent(container, null, "itemInfoMultilangueBox");
    DOMHelper.createDivWithParent(multilangueIcon, null, "itemInfoMultilangueText", " vo | vost ");
  }
};

export class PictoContainerRowView extends View {
  constructor(item: PlayableItem) {
    super(DOMHelper.createDivWithParent(null, null, "pictoContainerRow"));
    this.updateItem(item);
  }

  updateItem = (item: PlayableItem) => {
    this.rootElement.innerHTML = "";
    createPlayablePictos(item, this.rootElement);
  };
}
