import "./parametersPage.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "../../../../bud-lite-tv/src/lib";
import { Plugin } from "../../../datas/plugin";
import { navigationStack } from "../../../main";
import { sendPianoAnalytic } from "../../../tools/analytics/piano";
import { Position } from "../../../tools/cmp/didomi";
import { Didomi } from "../../../tools/cmp/didomi";
import { DidomiStub } from "../../../tools/cmp/didomiStub";
import { ButtonCmp } from "../../../views/cmp/cmpButton/buttonCmp";
import { CmpMenu, CmpMenuText } from "../../../views/cmp/cmpMenu/cmpMenu";
import { MainMenuItemSlug } from "../../../views/mainMenu/itemMenu";
import { HomeTab, RecoFocusType } from "../../home/homeTab";
import { pushConnexionPage, pushTabWithMenu } from "../../rootPage";
import { PartnersTab } from "../partners/partnersPage";
import { PrivatePolicyTab } from "../privacyPolicy/privacyPolicy";
import { Parameters, ParametersList } from "./parametersList";

export class ParametersTab extends View {
  private readonly itemCmpMenu: ButtonCmp[] = [
    {
      id: 0,
      width: 371,
      text: CmpMenuText.saveChoice,
      onPress: () => {
        Didomi.updateUserConsents({
          purposes: this._parametersList.map(purpose => ({
            id: purpose.id,
            enabled: purpose.state === Position.allow,
          })),
        });
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_parametrer",
            click: "enregistrer",
            cmp_analytics: Didomi.isPurposeEnabled("mesureda-LjJ4eyi4") ? "true" : "false",
            cmp_custom_content: Didomi.isPurposeEnabled("personnali-2G8aCWAB") ? "true" : "false",
          },
          {}
        );
        this._exit(
          this._fromReco
            ? Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L")
              ? RecoFocusType.recoSwimlane
              : RecoFocusType.ignoreButton
            : undefined
        );
      },
    },
    {
      id: 1,
      width: 316,
      text: CmpMenuText.acceptAll,
      onPress: () => {
        Didomi.enableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_parametrer",
            click: "tout_accepter",
            cmp_analytics: "true",
            cmp_custom_content: "true",
          },
          {}
        );
        this._exit(this._fromReco ? RecoFocusType.recoSwimlane : undefined);
      },
    },
    {
      id: 2,
      width: 288,
      text: CmpMenuText.rejectAll,
      onPress: () => {
        Didomi.disableAll();
        sendPianoAnalytic(
          "click.action",
          {
            feature: "cmp_parametrer",
            click: "tout_refuser",
            cmp_analytics: "false",
            cmp_custom_content: "false",
          },
          {}
        );
        this._exit(this._fromReco ? RecoFocusType.ignoreButton : undefined);
      },
    },
    {
      id: 3,
      width: 513,
      text: CmpMenuText.privacyPolicy,
      onPress: () => navigationStack.pushPage(new PrivatePolicyTab()),
    },
    {
      id: 4,
      width: 347,
      text: CmpMenuText.ourPartners,
      onPress: () => {
        navigationStack.pushPage(new PartnersTab(this._fromReco));
      },
    },
  ];
  private _parametersList: Parameters[];
  private _fromReco?: true;

  constructor(fromReco?: true) {
    super(DOMHelper.createDivWithParent(null, "ParametersPage"));

    this._fromReco = fromReco;
    this._parametersList = this.createParametersList();
    this.delegate = createListComponent(
      {
        rootElement: this.rootElement,
        modelSource: new StaticModelSource<
          | {
              id: "parameters";
              data: Parameters[];
            }
          | { id: "menu"; data: ButtonCmp[] }
        >([
          { id: "parameters", data: this._parametersList },
          { id: "menu", data: this.itemCmpMenu },
        ]),
        viewFactory: model => {
          if (model.id === "parameters") {
            return new ParametersList(this._parametersList);
          } else {
            return new CmpMenu(model.data);
          }
        },
        horizontal: true,
        pageSize: 2,
        visibleBefore: 0,
        visibleAfter: 0,
        spatialFocus: true,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }

  onShown() {
    sendPianoAnalytic("page.display", { page: "gerer_mes_consentements", page_type: "parametre" }, {});
  }

  private createParametersList = () => {
    const purposeList: Parameters[] = [];

    for (const purpose of DidomiStub.cmp.purposes) {
      const isPurposeEnabled = Didomi.isPurposeEnabled(purpose.id);

      purposeList.push({
        id: purpose.id,
        state: isPurposeEnabled ? Position.allow : Position.denied,
        // enabled: isPurposeEnabled,
        title: purpose.title,
        text: purpose.text,
      });
    }

    return purposeList;
  };

  private _exit = (recoFocus?: RecoFocusType) => {
    navigationStack.destroyStack();
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      pushTabWithMenu(new HomeTab(recoFocus), "homePage", MainMenuItemSlug.home);
    } else {
      pushConnexionPage();
    }
  };
}
