import "./medalsBoardPage.scss";

import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { CountryMedals, Idalgo, MedalsEvent, MedalsEventEndpoint } from "../../tools/idalgo/idalgo";
import { DynamicBackground, DynamicBackgroundOverlayType } from "../dynamicBackground";

export class MedalsBoardPage extends View {
  constructor(medalsEvent: MedalsEventEndpoint) {
    super(DOMHelper.createDivWithParent(null, "MedalsBoardPage", "medalsBoardPage"));
    this._fetchSource(medalsEvent);
  }

  private _fetchSource(medalsEvent: MedalsEventEndpoint) {
    Idalgo.fetchMedals(medalsEvent)
      .then(data => {
        this._onSourceReady(data);
      })
      .catch(e => {
        Log.api.error(e);
      });
  }

  private _onSourceReady = (medalsEvent: MedalsEvent) => {
    new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
      source: require("~images/background/jo_background.png"),
    });

    DOMHelper.createImg(this.rootElement, null, "medalsBoardPageLogo", require("~images/logo/jo_logo_white.png"), "");

    DOMHelper.createDivWithParent(this.rootElement, null, "medalsBoardPageTitle", "Tous les résultats de Paris 2024");

    const medalTypesContainer = DOMHelper.createDivWithParent(this.rootElement, null, "medalsTypesContainer");
    const goldContainer = DOMHelper.createDivWithParent(medalTypesContainer, null, "medalTypeContainer");
    DOMHelper.createDivImg(goldContainer, null, "medalTypeImg", require("~images/medals/gold.png"));
    DOMHelper.createDivWithParent(goldContainer, null, "medalTypeLabel", "Or");
    const silverContainer = DOMHelper.createDivWithParent(medalTypesContainer, null, "medalTypeContainer");
    DOMHelper.createDivImg(silverContainer, null, "medalTypeImg", require("~images/medals/silver.png"));
    DOMHelper.createDivWithParent(silverContainer, null, "medalTypeLabel", "Argent");
    const bronzeContainer = DOMHelper.createDivWithParent(medalTypesContainer, null, "medalTypeContainer");
    DOMHelper.createDivImg(bronzeContainer, null, "medalTypeImg", require("~images/medals/bronze.png"));
    DOMHelper.createDivWithParent(bronzeContainer, null, "medalTypeLabel", "Bronze");
    DOMHelper.createDivWithParent(medalTypesContainer, null, "totalLabel", "Total");

    const franceMedalView = new MedalContainerView(medalsEvent.france, this.rootElement);
    franceMedalView.rootElement.classList.add("franceMedalsContainerView");

    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "list"),
        modelSource: new StaticModelSource(
          medalsEvent.medals.map((medal, index) => {
            return { ...medal, id: index };
          })
        ),
        horizontal: false,
        pageSize: 3,
        visibleAfter: 1,
        spatialFocus: true,
        viewFactory: model => {
          return new MedalContainerView(model);
        },
        mouseFocusInPageOnly: true,
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  };

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "tableau_medailles",
        page_type: "unitaire",
      },
      {}
    );
  }
}

class MedalContainerView extends View {
  constructor(model: CountryMedals, parent?: HTMLElement) {
    super(DOMHelper.createDivWithParent(parent ?? null, null, "medalsContainerFocusBox"));
    const containerElement = DOMHelper.createDivWithParent(this.rootElement, null, "medalsContainerView");

    const metadataContainer = DOMHelper.createDivWithParent(containerElement, null, "metadataContainer");
    const formatRanking =
      typeof model.ranking === "number" && model.ranking < 10 ? "0" + model.ranking : model.ranking + "";
    DOMHelper.createDivWithParent(metadataContainer, null, "ranking", formatRanking);
    if (typeof model.flag?.png === "string") {
      DOMHelper.createDivImg(metadataContainer, null, "flag", model.flag.png);
    }
    DOMHelper.createDivWithParent(metadataContainer, null, "country", model.country);

    const resultsContainer = DOMHelper.createDivWithParent(containerElement, null, "resultsContainer");
    DOMHelper.createDivWithParent(resultsContainer, null, "resultNumber", `${model.gold}`);
    DOMHelper.createDivWithParent(resultsContainer, null, "resultNumber", `${model.silver}`);
    DOMHelper.createDivWithParent(resultsContainer, null, "resultNumber", `${model.bronze}`);
    if (model.gold !== null && model.silver !== null && model.bronze !== null) {
      const medalsTotalNumber = model.gold + model.silver + model.bronze;
      DOMHelper.createDivWithParent(resultsContainer, null, "resultNumber", `${medalsTotalNumber}`);
    }
  }
}
