import "./inscription.scss";

import { Code } from "~models/code";
import { Plugin } from "~pages/../datas/plugin";
import { DOMHelper, IPage, Keys, View } from "~ui-lib";

import { navigationStack } from "../../main";
import { LoaderView } from "../loader/loaderPage";
import { PopupValidate } from "../popup/popupValidate";

class CodeInfo extends View {
  span1: HTMLElement;
  span2: HTMLElement;
  span3: HTMLElement;
  span4: HTMLElement;
  span5: HTMLElement;
  span6: HTMLElement;

  constructor(parent: HTMLElement) {
    super(DOMHelper.createDivWithParent(parent, null, "codeZone"));
    const input1 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    const input2 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    const input3 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    const input4 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    const input5 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    const input6 = DOMHelper.createDivWithParent(this.rootElement, null, "codeText");
    this.span1 = DOMHelper.createSpanWithParent(input1, null, "span");
    this.span2 = DOMHelper.createSpanWithParent(input2, null, "span");
    this.span3 = DOMHelper.createSpanWithParent(input3, null, "span");
    this.span4 = DOMHelper.createSpanWithParent(input4, null, "span");
    this.span5 = DOMHelper.createSpanWithParent(input5, null, "span");
    this.span6 = DOMHelper.createSpanWithParent(input6, null, "span");
  }

  updateCode(entry: string) {
    // const temporaire = entry.toString();
    this.span1.innerText = entry[0];
    this.span2.innerText = entry[1];
    this.span3.innerText = entry[2];
    this.span4.innerText = entry[3];
    this.span5.innerText = entry[4];
    this.span6.innerText = entry[5];
  }
}

export class InscriptionPage extends View implements IPage {
  apiCode?: Code;
  codeInfo: CodeInfo;
  codeSide: HTMLElement;
  loader?: LoaderView;
  private _checkCode?: number;
  private _intervalCode?: number;
  private _isLoader = false;
  private isConnect: boolean;
  time = 6;
  timerText: HTMLElement;

  constructor(isConnect: boolean) {
    super(DOMHelper.createDivWithParent(null, "InscriptionPage"));
    this.isConnect = isConnect;
    Log.app.log("InscriptionPage isConnect? " + isConnect);
    DOMHelper.createDivWithParent(
      this.rootElement,
      null,
      "inscriptionTitle",
      isConnect ? "je me connecte" : "je m'inscris"
    );
    const qrSide = DOMHelper.createDivWithParent(this.rootElement, null, "qrSide");
    const plugin = Plugin.getInstance();
    const infoURL = isConnect ? plugin.connexionURLs.connexion : plugin.connexionURLs.inscription;

    DOMHelper.createDivImg(qrSide, null, "qrSideImage", require("~images/connection/connect1.png"));
    DOMHelper.createDivWithParent(qrSide, null, "webText", "Rendez vous sur\n" + infoURL);
    DOMHelper.createDivWithParent(qrSide, null, "qrText", "Ou scannez ce QR code :");
    DOMHelper.createDivImg(
      qrSide,
      null,
      "qrImg",
      isConnect ? plugin.connexionURLs.qrCodeConnexionSrc : plugin.connexionURLs.qrCodeSubscribeSrc
    );

    this.codeSide = DOMHelper.createDivWithParent(this.rootElement, null, "codeSide");
    DOMHelper.createDivImg(this.codeSide, null, "codeSideImage", require("~images/connection/connect2.png"));
    DOMHelper.createDivWithParent(
      this.codeSide,
      null,
      "codeText",
      "Saisissez le code suivant pour accèder à l’application sur votre TV"
    );

    this.codeInfo = new CodeInfo(this.codeSide);

    this.timerText = DOMHelper.createDivWithParent(this.codeSide, null, "timerText");
    this._fetchCode();
  }

  private _fetchCode = () => {
    Plugin.getInstance()
      .fetchCode()
      .subscribe(
        value => {
          this.apiCode = value[0];
          if (this.apiCode) {
            this.codeInfo.updateCode(this.apiCode.user_code);
            this.time = Math.round(this.apiCode.expires_in / 60);
            this.timerText.innerText =
              "Ce code est valable " + this.time + " minutes.\nIl se mettra à jour automatiquement.";
            window.clearInterval(this._intervalCode);
            this._intervalCode = window.setInterval(() => {
              if (this.time <= 1) {
                this._fetchCode();
              } else {
                this.time--;
                this.timerText.innerText =
                  "Ce code est valable " + this.time + " minutes.\nIl se mettra à jour automatiquement.";
              }
            }, 60000);
            window.clearInterval(this._checkCode);
            this._checkCode = window.setInterval(() => {
              this._fetchCheckCode();
            }, this.apiCode.interval * 1000);
          }
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[MENU] Error !", error);
        },
        () => {
          console.log("[MENU] Complete !");
        }
      );
  };

  private _fetchCheckCode = () => {
    Plugin.getInstance()
      .fetchCheckCode(this.apiCode!.code)
      .subscribe(
        value => {
          this.loader = new LoaderView();
          this.rootElement.appendChild(this.loader.rootElement);
          this._isLoader = true;
          this.loader?.validate(() => {
            const topPage = navigationStack.topPage;
            navigationStack.pushPage(new PopupValidate(topPage));
            this.rootElement.removeChild(this.loader!.rootElement);
          });
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[MENU] Error !", error);
        },
        () => {
          console.log("[MENU] Complete !");
        }
      );
  };

  onRelease(): void {
    clearInterval(this._intervalCode);
    clearInterval(this._checkCode);
    this.loader?.onRelease();
  }

  onNav = (key: Keys) => {
    if (this._isLoader) return true;
    return false;
  };
}
