import "./playerButtons.scss";

import { Player } from "~libs/player";
import { PlayerControlButton } from "~pages/player/playerControlButton";
import {
  createListComponent,
  debounce,
  DOMHelper,
  IListComponent,
  Keys,
  Listenable,
  ListenableSource,
  platform,
  PlatformType,
  View,
} from "~ui-lib";

import { PlayerState } from "../../libs/player";
import { SkipButtonTimecodesObjectType } from "./playerPage";
import { PlayerSkipButton, SkipButtonStateValue } from "./playerSkipButton";

export enum ButtonType {
  // live
  replay = "replay",
  stopPlay = "stopPlay",
  reload = "reload",

  // vod
  jumprewind = "jumpRewind",
  play_pause = "play_pause",
  jumpforward = "jumpForward",
  nextVideo = "nextVideo",
  skipRecap = "skipRecap",
  skipIntro = "skipIntro",
}

const liveButtons = [ButtonType.replay, ButtonType.stopPlay, ButtonType.reload];

const vodButtons = [
  ButtonType.jumprewind,
  ButtonType.play_pause,
  ButtonType.jumpforward,
  ButtonType.nextVideo,
  ButtonType.skipRecap,
  ButtonType.skipIntro,
];

class PlayPauseButton extends View {
  player: Player;
  playPauseButtonStateUnregister: () => void;

  constructor(player: Player) {
    super(DOMHelper.createDivWithParent(null, ButtonType.play_pause, "playerControlButton playPause"));

    this.player = player;
    this.playPauseButtonStateUnregister = this.player.state$.didChange(state => {
      switch (state) {
        case PlayerState.ERROR:
        case PlayerState.PAUSED:
          // replace play with pause
          this.rootElement.classList.remove("playing");
          break;
        case PlayerState.PLAYING:
          // replace pause with play
          this.rootElement.classList.add("playing");
          break;
      }
    });
  }

  onRelease() {
    this.playPauseButtonStateUnregister();
  }
}

class StopPlayButton extends View {
  player: Player;

  //playPauseButtonStateUnregister: () => void;
  constructor(player: Player) {
    super(DOMHelper.createDivWithParent(null, ButtonType.stopPlay, "playerControlButton stopPlay"));

    this.player = player;
    /*this.playPauseButtonStateUnregister = this.player.state$.didChange(state => {
      switch (state) {
        case PlayerState.IDLE:
          this.rootElement.classList.remove("playing");
          break;
        case PlayerState.PLAYING:
          this.rootElement.classList.add("playing");
          break;
      }
    });*/
  }

  onRelease() {
    //this.playPauseButtonStateUnregister();
  }
}

export class PlayerButtonList extends View {
  buttons$ = new Listenable<ButtonType[]>([]);
  buttonList?: IListComponent;
  defaultFocusOn: ButtonType = ButtonType.play_pause;
  player: Player;
  isLive$: Listenable<boolean>;
  endVideo: () => void;
  skipButtonsTimecodes: SkipButtonTimecodesObjectType;
  skipIntroButton?: PlayerSkipButton;
  skipRecapButton?: PlayerSkipButton;
  nextEpisodeButton?: PlayerSkipButton;
  playerListener: () => void;

  TIME_INCREMENT_FW = 10;
  TIME_INCREMENT_RW = 10;

  constructor(
    isLive$: Listenable<boolean>,
    player: Player,
    endVideo: () => void,
    skipButtonsTimecodes: SkipButtonTimecodesObjectType
  ) {
    super(DOMHelper.createDivWithParent(null, null, "PlayerButtonListContainer"));

    this.player = player;
    this.isLive$ = isLive$;
    this.endVideo = endVideo;
    this.skipButtonsTimecodes = skipButtonsTimecodes;
    this.buttons$.value = isLive$.value ? liveButtons : vodButtons;
    if (isLive$.value) {
      this.defaultFocusOn = ButtonType.stopPlay;
    }

    this.delegate = this.buttonList = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "PlayerButtonList", "PlayerButtonList"),
        modelSource: new ListenableSource(this.buttons$, true), // we want to keep the selection where it is when list updates
        viewFactory: button => {
          switch (button) {
            case ButtonType.play_pause:
              return new PlayPauseButton(this.player);

            case ButtonType.stopPlay:
              return new StopPlayButton(this.player);

            case ButtonType.nextVideo:
              this.nextEpisodeButton = new PlayerSkipButton(button, "vidéo suivante", this.delegate as IListComponent);
              return this.nextEpisodeButton;

            case ButtonType.skipRecap:
              this.skipRecapButton = new PlayerSkipButton(button, "passer le récap", this.delegate as IListComponent);
              return this.skipRecapButton;

            case ButtonType.skipIntro:
              this.skipIntroButton = new PlayerSkipButton(
                button,
                "passer le générique",
                this.delegate as IListComponent
              );
              return this.skipIntroButton;

            default:
              return new PlayerControlButton(button);
          }
        },
        horizontal: true,
        defaultFocusId: this.defaultFocusOn,
        onSelect: button => {
          console.log("[XITI] button", this.player);
          switch (button) {
            case ButtonType.play_pause:
              this.player.sendEStat(
                this.player.state$.value === PlayerState.PLAYING ? PlayerState.PAUSED : PlayerState.PLAYING
              );
              this.player.playPause();
              break;
            case ButtonType.jumpforward:
              this._launchSeek(Keys.forward);
              break;
            case ButtonType.jumprewind:
              this._launchSeek(Keys.rewind);
              break;
            case ButtonType.nextVideo:
              this.endVideo();
              break;
            case ButtonType.skipRecap:
              this.skipToValue(ButtonType.skipRecap);
              break;
            case ButtonType.skipIntro:
              this.skipToValue(ButtonType.skipIntro);
              break;
          }
          return true;
        },
      },
      list => {
        // always provide focus to the content
        list.setFocusOnId(this.defaultFocusOn);
      }
    );

    this.playerListener = this.player.currentTime$.didChange(value => {
      if (!this.player.isAdVideoPlaying()) {
        if (this.skipButtonsTimecodes.previously && this.skipRecapButton) {
          this._setSkipButtonState(value, this.skipButtonsTimecodes.previously, this.skipRecapButton);
        }

        if (this.skipButtonsTimecodes.coming_next && this.nextEpisodeButton) {
          this._setSkipButtonState(value, this.skipButtonsTimecodes.coming_next, this.nextEpisodeButton);
        }

        if (this.skipButtonsTimecodes.skip_intro && this.skipIntroButton) {
          this._setSkipButtonState(value, this.skipButtonsTimecodes.skip_intro, this.skipIntroButton);
        }
      }
    });
  }

  private _getPlayerPosition = () => {
    return this.player.seekTime$.value === undefined ? this.player.position() : this.player.seekTime$.value;
  };

  private _seek = debounce((callback?: () => void) => {
    callback?.();
  }, 800);

  private _launchSeek = (key: Keys.forward | Keys.rewind) => {
    if (
      [PlayerState.PLAYING, PlayerState.PAUSED, PlayerState.BUFFERING].indexOf(this.player.state$.value) !== -1 &&
      !this.isLive$.value
    ) {
      if (this.player.state$.value !== PlayerState.PAUSED) {
        this.player.sendEStat(PlayerState.PAUSED);
      }
      this.player.pause();
      let position =
        key === Keys.rewind
          ? Math.max(0, this._getPlayerPosition() - this.TIME_INCREMENT_RW)
          : Math.min(this._getPlayerPosition() + this.TIME_INCREMENT_FW, this.player.duration());

      // FIX from SAMSUNG : try not to jump to last possible second and limit it to jumping to video.duration-5
      if (platform.type === PlatformType.tizen && key === Keys.forward) {
        if (this._getPlayerPosition() <= this.player.duration() - 5) {
          position = Math.min(position, this.player.duration() - 5);
        } else {
          // if the current postion is between duration-5 and duration, we don't jump because it will cause a rewind instead of forward
          position = this._getPlayerPosition();
        }
      }
      // END FIX
      this.player.setSeekTime(~~position);
      this._seek(() => {
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
      });
    }
  };

  private _setSkipButtonState = (time: number, skipData: { [key: string]: number }, button: PlayerSkipButton) => {
    if (time >= skipData.timecode && time < skipData.time_before_dismiss) {
      button.buttonState$.value = SkipButtonStateValue.visible;
    }
    if (time >= skipData.time_before_dismiss && time < skipData.duration) {
      button.buttonState$.value = SkipButtonStateValue.hidden;
    }
    if (time < skipData.timecode || time >= skipData.duration) {
      button.buttonState$.value = SkipButtonStateValue.removed;
    }
  };

  removeAllButtons = () => {
    if (this.skipRecapButton) this.skipRecapButton.buttonState$.value = SkipButtonStateValue.removed;

    if (this.skipIntroButton) this.skipIntroButton.buttonState$.value = SkipButtonStateValue.removed;

    if (this.nextEpisodeButton) this.nextEpisodeButton.buttonState$.value = SkipButtonStateValue.removed;
  };

  skipToValue = (button: ButtonType) => {
    const skipToTime =
      button == ButtonType.skipRecap
        ? this.skipButtonsTimecodes.previously.duration
        : this.skipButtonsTimecodes.skip_intro.duration;
    this.player.pause();
    this.player.setSeekTime(skipToTime);
    this._seek(() => {
      this.player.sendEStat(PlayerState.PLAYING);
      this.player.play();
      (this.delegate as IListComponent).setFocusOnId(this.defaultFocusOn);
    });
  };

  onHidden = () => {
    // reset focus to playpause when overlay is hidden
    (this.delegate as IListComponent).setFocusOnId(this.defaultFocusOn);
  };

  onNav = (key: Keys): boolean => {
    switch (key) {
      case Keys.back:
        /*if (this.player.state$.value == PlayerState.SEEKING) {
          this.player.cancelSeek();
          this.player.play();
          return true;
        }*/
        return false;

      case Keys.play:
        this.player.sendEStat(PlayerState.PLAYING);
        this.player.play();
        return true;

      case Keys.pause:
        this.player.sendEStat(PlayerState.PAUSED);
        this.player.pause();
        return true;

      case Keys.playPause:
        this.player.sendEStat(
          this.player.state$.value === PlayerState.PLAYING ? PlayerState.PAUSED : PlayerState.PLAYING
        );
        this.player.playPause();
        return true;

      case Keys.forward:
      case Keys.rewind:
        this._launchSeek(key);
        return true;

      default:
        return false;
    }
  };
}
