import "./partnerItem.scss";

import { DOMHelper, View } from "~ui-lib";

import { Partner } from "../../models/partner";

export class PartnerItem extends View {
  constructor(item: Partner) {
    super(DOMHelper.createDivWithParent(null, null, "partnerItem"));
    const imageContainer = DOMHelper.createDivWithParent(this.rootElement, null, "imageContainer");

    const partnerImgUrl = ((): string => {
      if (item instanceof Partner) {
        return item.getSquareImgUrl();
      }
      return "";
    })();

    if (partnerImgUrl.length) {
      DOMHelper.createDivImg(imageContainer, null, "partnerImage", partnerImgUrl);
    }
  }

  onFocused = () => {};
}
