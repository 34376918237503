import "./favoritesTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { PlaylistProgramSwimlane } from "~swimlaneViews/playlistProgramSwimlane";
import { PlaylistVideoSwimlane } from "~swimlaneViews/playlistVideoSwimlane";
import {
  createListComponent,
  DOMHelper,
  IListComponent,
  Listenable,
  ListenableSource,
  StaticModelSource,
  View,
} from "~ui-lib";
import { verticalArrowFactory } from "~views/arrows/verticalArrow";

import { Plugin } from "../../datas/plugin";
import { navigationStack } from "../../main";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { Didomi } from "../../tools/cmp/didomi";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";
import { InscriptionPage } from "../inscription/inscription";

const _validSwimlaneTypes = ["bookmarks_video", "bookmarks_program", "seeks", "recommendations"];
enum NotConnectedButton {
  connect = "connect",
  subscribe = "subscribe",
}

class NotConnectedButtonView extends View {
  constructor(title: string) {
    super(
      DOMHelper.createDivWithParent(
        null,
        title + "_" + Math.random().toString(36).substr(2, 9),
        "ButtonFavoritesNotConnected"
      )
    );
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "ButtonFavoritesNotConnectedBoxZoom");
    DOMHelper.createDivWithParent(box, null, "ButtonFavoritesNotConnectedText", title);
  }
}

export class FavoritesTab extends View {
  private _listComponent?: IListComponent<ItemCollection>;
  private _listButtonComponent?: IListComponent<NotConnectedButton>;
  private _validSwimlanes$ = new Listenable<ItemCollection[]>([]);
  private _scrollIndexUnregister?: () => void;
  private _background?: DynamicBackground;
  private _sourceMyVideos?: ItemCollection[] = undefined;
  private _recommendations?: ItemCollection[] = undefined;
  private _noContentElt?: HTMLElement = undefined;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "FavoritesTab"));

    if (Plugin.getInstance().user.isActive()) {
      this._fetchSource(true);
    } else {
      this._favoritesNotConnected();
    }
  }

  onShown() {
    if (this._listComponent) {
      // we reload all swimlanes
      // on first "onShown" the listComponent doesn't exists so there is no double load
      this._sourceMyVideos = undefined;
      this._recommendations = undefined;
      this._fetchSource(false);
      this._listComponent.onContentReady().then(() => {
        this._listComponent?.setFocusOnIndex(0);
        DOMHelper.addClass(this._listComponent?.viewFromIndex(0)?.rootElement, "onTop");
      });
    }

    sendPianoAnalytic("page.display", { page: "mes_videos", page_type: "mes_videos" }, {});
  }

  private _fetchSource(isInit: boolean) {
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      const aFetchIsDone = () => {
        if (isInit) {
          this._onSourceReady();
        } else {
          this.updateSourceFavoriteTab();
        }
      };
      Plugin.getInstance()
        .fetchMyVideos(user)
        .subscribe(
          value => {
            // Here use it to create the UI
            Log.app.log("[fetchMyVideos] next !", value);
            this._sourceMyVideos = value[0]?.items ?? [];
            aFetchIsDone();
          },
          error => {
            // Here use it to trigger and display an error
            Log.app.error("[fetchMyVideos] Error !", error);
            this._sourceMyVideos = [];
            aFetchIsDone();
          }
        );
      if (Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L")) {
        Plugin.getInstance()
          .fetchRecommendations(user)
          .subscribe(
            value => {
              // Here use it to create the UI
              Log.app.log("[fetchRecommendations] next !", value);
              this._recommendations = value;
              aFetchIsDone();
            },
            error => {
              // Here use it to trigger and display an error
              Log.app.error("[fetchRecommendations] Error !", error);
              this._recommendations = [];
              aFetchIsDone();
            }
          );
      } else {
        this._recommendations = [];
        aFetchIsDone();
      }
    }
  }

  private _favoritesNotConnected() {
    DOMHelper.createDivWithParent(this.rootElement, null, "favoritesNotConnectedTitle", "commencez, reprenez, suivez");
    DOMHelper.createDivWithParent(
      this.rootElement,
      null,
      "favoritesNotConnectedSubtitle",
      "Grâce à votre compte, retrouvez tous vos favoris et reprenez vos vidéos là où vous les avez arrêtées. Il suffit de se connecter !"
    );

    this._listButtonComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new StaticModelSource([NotConnectedButton.subscribe, NotConnectedButton.connect]),
        viewFactory: model => {
          switch (model) {
            case NotConnectedButton.connect:
              return new NotConnectedButtonView("Je me connecte");
            case NotConnectedButton.subscribe:
              return new NotConnectedButtonView("Je m'inscris");
          }
        },
        pageSize: 2,
        horizontal: true,
        spatialFocus: true,
        onSelect: button => {
          switch (button) {
            case NotConnectedButton.connect:
              navigationStack.pushPage(new InscriptionPage(true));
              break;
            case NotConnectedButton.subscribe:
              navigationStack.pushPage(new InscriptionPage(false));
              break;
          }
          return true;
        },
      },
      mainList => {
        mainList.setFocusOnId(NotConnectedButton.connect);
      }
    );
  }

  private _onSourceReady() {
    if (!this._recommendations || !this._sourceMyVideos) {
      return;
    }
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
    });

    this.updateSourceFavoriteTab();

    this._listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, null, "listContainer"),
        modelSource: new ListenableSource(this._validSwimlanes$),
        viewFactory: model => {
          if (model.type == "bookmarks_video") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "bookmarks_program") {
            return new PlaylistProgramSwimlane(model);
          } else if (model.type == "seeks") {
            return new PlaylistVideoSwimlane(model);
          } else if (model.type == "recommendations") {
            return new PlaylistVideoSwimlane(model);
          } else {
            //default
            return new PlaylistVideoSwimlane(model);
          }
        },
        arrowFactory: verticalArrowFactory,
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        const defaultIndex = 0;
        mainList.setFocusOnIndex(defaultIndex);
        DOMHelper.addClass(mainList.viewFromIndex(defaultIndex)?.rootElement, "onTop");
      }
    );
    this._background.setItemCollectionList(this._listComponent);

    this._scrollIndexUnregister = this._listComponent.scrollIndex$.didChange((newIndex, oldIndex) => {
      DOMHelper.removeClass(this._listComponent?.viewFromIndex(oldIndex)?.rootElement, "onTop");
      DOMHelper.addClass(this._listComponent?.viewFromIndex(newIndex)?.rootElement, "onTop");
      if (newIndex !== undefined) {
        this._listComponent?.setFocusOnIndex(newIndex);
      }
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._scrollIndexUnregister?.();
  };

  updateSourceFavoriteTab() {
    if (!this._recommendations || !this._sourceMyVideos) {
      return;
    }
    this._validSwimlanes$.value = [];
    const source: ItemCollection[] = [];
    for (let ic = 0; ic < this._sourceMyVideos.length; ic++) {
      if (this._sourceMyVideos[ic].items) source.push(this._sourceMyVideos[ic]);
    }
    for (const coll of this._recommendations) {
      source.push(coll);
    }
    source.forEach(sl => {
      // If a swimlane is empty we don't display it
      if (_validSwimlaneTypes.includes(sl.type) && sl.items && sl.items.length) {
        this._validSwimlanes$.value.push(sl);
        if (sl.type === "seeks") {
          PlayHistoryHelper.clearlist();
          for (const item of sl.items) {
            if (typeof item?.extras?.transactions?.progress === "number") {
              PlayHistoryHelper.updateOffset(item, item.extras.transactions.progress);
            }
          }
        }
      }
    });
    if (this._validSwimlanes$.value.length == 0) {
      // if there is no swimlane we display the noContent message
      this.showNoContent();
    } else {
      this.hideNoContent();
    }
  }

  showNoContent() {
    this._background?.animSrc("");
    if (this._noContentElt == undefined) {
      this._noContentElt = DOMHelper.createDivWithParent(this.rootElement, null, "favoritesNoContent", "");
      DOMHelper.createDivWithParent(this._noContentElt, null, "favoritesNoContentTitle", "commencez, reprenez, suivez");
      DOMHelper.createDivWithParent(
        this._noContentElt,
        null,
        "favoritesNoContentDesc",
        "Ici, vous pourrez reprendre les vidéos que vous avez commencées et retrouver vos programmes favoris."
      );
    } else {
      this._noContentElt.hidden = false;
    }
  }

  hideNoContent() {
    this._noContentElt && (this._noContentElt.hidden = true);
  }
}
