import { ByteStreamDataItemValue, StringDataItemValue } from "tizen-common-web";

import { platform, PlatformType } from "~ui-lib";

import { PlayableItem } from "../models/playableItem";
import { CurrentOffSet } from "../tools/playerHistoryHelper";
import { isEpisode } from "../tools/tools";
import { Plugin } from "./plugin";

type CWData = {
  field: string;
  app_id: string;
  app_name: string;
  app_icon: string;
  content_id: string;
  payload: string;
  image_url: string;
  content_title: string;
  sub_title?: string;
  description?: string;
  rate: string;
  release: string;
  duration?: number;
  playback?: number;
  timestamp: number;
  season?: number;
  episode?: number;
};

// Workaround to get app path to which to add path to logo
const getAbsoluteUrl = (function () {
  let a: HTMLAnchorElement;
  return function (url: string) {
    if (a !== undefined && a !== null) {
      // we do nothing here
    } else {
      a = document.createElement("a");
    }
    a.href = url;
    return a.href;
  };
})();

const logoPath = require("~images/logo/cw-icon.png");
const fullLogoPath = getAbsoluteUrl(logoPath);

const vignetteFallback = getAbsoluteUrl(require("~images/fallbacks/fallback-vignette.png"));

class TizenCWManager {
  private static _instance?: TizenCWManager;

  private _appId = "QY16tZ64E3.ContinueWatchingService"; // default prod ID

  private constructor() {
    if (platform.type === PlatformType.tizen) {
      // To get app id of current widget
      const app = tizen.application.getCurrentApplication();
      const appId = app.appInfo.id;
      if (appId !== undefined) {
        this._appId = appId.split(".")[0] + ".ContinueWatchingService";
      }
    }
  }

  static get instance() {
    return (TizenCWManager._instance = TizenCWManager._instance ?? new TizenCWManager());
  }

  public getItems() {
    this._launchAppControl("get", [JSON.stringify(null)]);
  }

  public currentItems(items: StringDataItemValue | ByteStreamDataItemValue | undefined) {
    console.log("Current items in Tizen CW", items);
  }

  /**
   * Adds one item to Tizen Continue Watching list
   * @param video - video user is currently watching
   * @param progress - playback progress to store bookmark
   */
  public addItem(video: PlayableItem, progress: number) {
    const data = this.parseTizenCWdata(video, progress, this._appId);
    this._launchAppControl("add", [JSON.stringify(data)]);
  }

  /**
   * Updates Tizen CW list of current user bookmarks
   * @param bookmarks - Array of bookmarks of current user
   */
  public addItems(bookmarks: CurrentOffSet[]) {
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      const bookmarkIds = bookmarks.map(item => {
        return item.id;
      });

      Plugin.getInstance()
        .fetchContentByIds(bookmarkIds)
        .subscribe(
          value => {
            const bookmarkItemData = value;
            const itemsToAdd: string[] = [];
            bookmarks.forEach(bookmark => {
              const data = bookmarkItemData.find(item => {
                return item.id === bookmark.id;
              });
              if (data !== undefined) {
                // Don't push more than 5 items
                if (itemsToAdd.length < 5) {
                  itemsToAdd.push(JSON.stringify(this.parseTizenCWdata(data, bookmark.currentOffset, this._appId)));
                }
              }
            });
            if (itemsToAdd !== undefined && itemsToAdd.length > 0) {
              this._launchAppControl("adds", itemsToAdd);
            }
          },
          error => {
            // Here use it to trigger and display an error
            Log.app.log("[RESUME TizenCWManager] Error !", error);
          }
        );
    }
  }

  public deleteItem(video: PlayableItem) {
    const data = {
      field: "0",
      app_id: this._appId,
      content_id: video.id, // id samsung,
    };
    this._launchAppControl("delete", [JSON.stringify(data)]);
  }

  public deleteAllItems() {
    const data = {
      field: "0",
      app_id: this._appId,
      content_id: "ALL",
    };
    this._launchAppControl("delete", [JSON.stringify(data)]);
  }

  private parseTizenCWdata(video: PlayableItem, progress: number, appId: string): CWData {
    let startDate = "";
    if (video.media?.start_date) {
      startDate = video.media?.start_date.toDateString();
    }
    // Get the image of right format
    const vignette16x9 = video.artworks.filter(image => {
      return image.format === "vignette_16x9";
    });

    const smallVignette = vignette16x9.find(image => {
      return image.key === "400";
    });

    const vignetteAt0 = vignette16x9.find((_, index) => {
      return index === 0;
    });

    const releaseYear = video.metadata.productionYear || Math.round(new Date(startDate).getFullYear());

    return {
      field: "0",
      app_id: appId,
      app_name: "france.tv",
      app_icon: fullLogoPath,
      content_id: video.id, // Tizen content id
      // Use small image if it exists, otherwise take first one of 16x9
      image_url: smallVignette?.src ?? vignetteAt0?.src ?? vignetteFallback,
      rate: video.metadata.rating?.code === "TP" ? "Tous" : video.metadata.rating?.code || "Tous",
      playback: progress,
      timestamp: Math.round(Date.now() / 1000),
      release: releaseYear.toString(),
      content_title: isEpisode(video) && video.extras.program !== undefined ? video.extras.program.title : video.title,
      sub_title: isEpisode(video) ? video.title : "",
      season: isEpisode(video) ? video.extras.season : null,
      episode: isEpisode(video) ? video.extras.episode : null,
      payload: `{"contentId": "#player/${video.type === "integrale" ? "integrale" : "unit"}/${video.id}"}`,
      duration: video.metadata.duration,
    };
  }

  private _launchAppControl(key: string, data: string[]) {
    const appData = new tizen.ApplicationControlData(key, data); //Data we want to sent to BG service app with a custom KEY as identifier

    const appControl = new tizen.ApplicationControl(
      "http://tizen.org/appcontrol/operation/francetv",
      null,
      null,
      null,
      [appData]
    );

    /*
     * Launch ApplicationControl
     */
    tizen.application.launchAppControl(
      appControl,
      this._appId,
      () => {
        Log.app.log("[launchAppControl] Launch BGservice with Data Success", key);
      },
      (error: unknown) => {
        Log.app.error("[launchAppControl] Launch BGservice with Data Error : ", key, JSON.stringify(error));
      }
    );
  }
}

export const TizenCWHelper = TizenCWManager.instance;
