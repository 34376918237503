/* eslint-disable @typescript-eslint/no-explicit-any */
import { Artwork } from "~models/artwork";
import { BrowsableItem } from "~models/browsableItem";
import { IModel } from "~ui-lib";

export class ItemCollection extends BrowsableItem implements IModel {
  items: any[];
  sponsored: boolean;
  constructor(
    id: string,
    type: string,
    title: string,
    summary: any,
    items: any[],
    artworks: Array<Artwork>,
    extras: any,
    sponsored: boolean
  ) {
    super(id, type, title, summary, artworks, extras, sponsored);

    this.items = items;
    this.sponsored = sponsored;
  }
}
