import { z } from "zod";

const Rate = z.object({
  content: z.number(),
  rating: z.union([z.literal(1), z.literal(0), z.literal(-1)]),
});

type Rate = z.infer<typeof Rate>;

export const parseRatings = (json: unknown) => {
  return z
    .object({
      contents: z.array(Rate),
      taxonomies: z.array(Rate),
    })
    .parse(json);
};
