import { z } from "zod";

export const AppPurposeList = ["mesureda-LjJ4eyi4", "personnali-2G8aCWAB"] as const;
export const PurposeId = z.enum(AppPurposeList);
export type PurposeId = z.infer<typeof PurposeId>;

export const AppVendorList = ["youbora-YdPrzZLh", "atinterne-D22mcTNf", "estat-P2GUgJcz", "spideo-TcYnKH8L"] as const;
export const VendorId = z.enum(AppVendorList);
export type VendorId = z.infer<typeof VendorId>;

const DidomiConfig = z.object({
  cmp: z.object({
    vendors: z.array(
      z.object({
        vendor_id: VendorId,
        vendor_title: z.string(),
        vendor_purpose: PurposeId,
      })
    ),
    purposes: z.array(
      z.object({
        id: PurposeId,
        title: z.string(),
        text: z.string(),
      })
    ),
  }),
});
type DidomiConfig = z.infer<typeof DidomiConfig>;

export const DidomiStub: DidomiConfig = {
  cmp: {
    vendors: [
      {
        vendor_id: "atinterne-D22mcTNf",
        vendor_title: "AT Internet",
        vendor_purpose: "mesureda-LjJ4eyi4",
      },
      {
        vendor_id: "estat-P2GUgJcz",
        vendor_title: "eStat",
        vendor_purpose: "mesureda-LjJ4eyi4",
      },
      {
        vendor_id: "spideo-TcYnKH8L",
        vendor_title: "Spidéo",
        vendor_purpose: "personnali-2G8aCWAB",
      },
      {
        vendor_id: "youbora-YdPrzZLh",
        vendor_title: "Youbora",
        vendor_purpose: "mesureda-LjJ4eyi4",
      },
    ],
    purposes: [
      {
        id: "mesureda-LjJ4eyi4",
        title: "Mesure d'audience",
        text:
          "Activer ces traceurs nous permet de mesurer l'audience des contenus présents sur le site internet et d'en améliorer le fonctionnement. Le fait de désactiver ces traceurs n’empêche pas la mesure d'audience mais en réduit la précision et donc la compréhension de vos usages. Vous pouvez vous opposer aux traceurs permettant cette mesure d'audience moins précise et obtenir plus d'informations en cliquant ici.",
      },
      {
        id: "personnali-2G8aCWAB",
        title: "Personnalisation des contenus et des services",
        text:
          "Activer ces traceurs nous permet de comprendre vos habitudes d'usage, vos interactions et vos préférences afin de vous proposer des contenus et services personnalisés et améliorer votre expérience utilisateur. Vous empêchez ces actions en les désactivant.",
      },
    ],
  },
};

export const getVendorPurpose = (vendorId: VendorId): PurposeId | null => {
  const vendor = DidomiStub.cmp.vendors.find(vendor => vendor.vendor_id === vendorId);

  if (vendor !== undefined) {
    return vendor.vendor_purpose;
  } else {
    return null;
  }
};
