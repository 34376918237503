import { z } from "zod";

/**
 * Edgescape expected response format:
 *   "reponse": {
 *     "geo_info": {
 *         "country_code": string,
 *         "city": string,
 *         "lat": string,
 *         "long": string,
 *         "timezone": string,
 *         "continent": string,
 *         "bw": string,
 *         "generate-date": string
 *     }
 *   }
 */

const EdgescapeResponse = z.object({
  reponse: z.object({
    geo_info: z.object({
      country_code: z.string(),
    }),
  }),
});

export function parseEdgescapeCountry(json: unknown): string | undefined {
  const parseResult = EdgescapeResponse.safeParse(json);

  if (parseResult.success) {
    return parseResult.data.reponse.geo_info.country_code;
  } else {
    Log.app.error(parseResult.error.message);
    return undefined;
  }
}
