import "./emptySearchView.scss";

import { DOMHelper, View } from "~ui-lib";

export class EmptySearchView extends View {
  constructor() {
    super(DOMHelper.createDivWithParent(null, "EmptySearchView", "emptySearchView"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "emptySearchTextBox");
    DOMHelper.createDivWithParent(
      box,
      null,
      "emptySearchText",
      "Nous n’avons pas ce que vous cherchez. \n On peut vous proposer :"
    );
  }
}
