import { Storage } from "~libs/storage";

import { Didomi } from "../tools/cmp/didomi";
import { UserInfo } from "./userInfo";

export const USER_ID_STORAGE_KEY = "userId" as const;
export const PUBLIC_ID_STORAGE_KEY = "publicId" as const;

export interface ILoggedInUser {
  id: string;
  infos: UserInfo;
}

export class User implements Partial<ILoggedInUser> {
  id?: string;
  infos?: UserInfo;

  constructor() {}

  connect(userId: string, userInfo: UserInfo) {
    Storage.setItem(USER_ID_STORAGE_KEY, userId);
    Storage.setItem(PUBLIC_ID_STORAGE_KEY, userInfo.publicId);
    this.id = userId;
    this.infos = userInfo;

    /**
     * User id & infos are filed
     * We want to update newly logged in user consents
     * with current user consents (identified by its device id)
     */
    void Didomi.sendCurrentUserConsents();
  }

  deconnect() {
    Storage.removeItem(USER_ID_STORAGE_KEY);
    Storage.removeItem(PUBLIC_ID_STORAGE_KEY);
    this.id = undefined;
    this.infos = undefined;

    Storage.removeItem("access_token");
    Storage.removeItem("refresh_token");

    /**
     * User id & infos are undefined
     * We want to update anonymous user consents
     * with logged-in user consents (identified by its user id)
     */
    void Didomi.sendCurrentUserConsents();
  }

  isActive(): this is ILoggedInUser {
    return this.id !== undefined && this.infos !== undefined;
  }
}
