import "./playerErrorPage.scss";

import { createListComponent, DOMHelper, IPage, Keys, StaticModelSource, View } from "~ui-lib";

class PlayerErrorButton extends View {
  constructor(label: string) {
    super(DOMHelper.createDivWithParent(null, null, "playerErrorButton"));
    const buttonZoom = DOMHelper.createDivWithParent(this.rootElement, null, "playerErrorButtonZoomBox");
    DOMHelper.createDivWithParent(buttonZoom, null, "playerErrorButtonText", label);
  }
}

interface IButton {
  id: string;
  label: string;
  action: () => void;
}

export class PlayerErrorPage extends View implements IPage {
  private _onBack: () => void;
  constructor(message: string, onBack: () => void, onRetry: (() => void) | false) {
    super(DOMHelper.createDivWithParent(null, "PlayerErrorPage"));
    this._onBack = onBack;
    DOMHelper.createDivWithParent(this.rootElement, null, "playerErrorMessage", message);

    const buttons: IButton[] = [
      {
        id: "back",
        label: "retour",
        action: onBack,
      },
    ];
    if (onRetry !== false) {
      buttons.push({
        id: "retry",
        label: "réessayer",
        action: onRetry,
      });
    }

    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(this.rootElement, "PlayerErrorPageButtonsList"),
        // hack: add the index as an id to please the list
        modelSource: new StaticModelSource(buttons),
        viewFactory: button => {
          return new PlayerErrorButton(button.label);
        },
        horizontal: true,
        fitToChildren: true,
        onSelect: button => {
          button.action();
          return true;
        },
      },
      list => {
        list.setFocusOnIndex(0).then();
      }
    );
  }

  onNav(key: Keys) {
    switch (key) {
      case Keys.back:
        this._onBack();
        return true;
      default:
        return false;
    }
  }
}
