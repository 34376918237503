import "./itemMenuView.scss";

import { DOMHelper, IFocusable, View } from "~ui-lib";

import { ItemImages, ItemMenu } from "./itemMenu";

export class ItemMenuView extends View implements IFocusable {
  private data: ItemMenu;
  private images: ItemImages;
  private divImage: HTMLDivElement;
  private selected = false;

  constructor(data: ItemMenu) {
    super(DOMHelper.createDivWithParent(null, null, "itemMenu"));
    const images: ItemImages = { iconOn: null, iconOff: null, focusOn: null, focusOff: null };
    this.data = data;

    try {
      images.iconOn = require(`~images/mainMenu/iconOn/${data.slug}.png`);
      images.iconOff = require(`~images/mainMenu/iconOff/${data.slug}.png`);
      images.focusOn = require(`~images/mainMenu/focusOn/${data.slug}.png`);
      images.focusOff = require(`~images/mainMenu/focusOff/${data.slug}.png`);
    } catch (error) {
      console.error("[itemMenu] Error !", error);
    }
    this.images = images;
    this.divImage = DOMHelper.createDivImg(this.rootElement, null, "itemMenuImage", images.iconOff);
    DOMHelper.createDivWithParent(this.rootElement, null, "itemMenuTitle", data.label);
    if (data.slug === "custom") {
      this.rootElement.classList.add("custom");
    }
  }

  onFocused() {
    if (this.selected) {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.focusOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }

  onUnfocused() {
    if (this.selected) {
      this.divImage.style.background = `url(${this.images.iconOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  }

  select = () => {
    this.selected = true;
    DOMHelper.addClass(this.rootElement, "selected");
    if (DOMHelper.hasClass(this.rootElement, "focused")) {
      this.divImage.style.background = `url(${this.images.focusOn})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOn})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  };

  unSelect = () => {
    this.selected = false;
    DOMHelper.removeClass(this.rootElement, "selected");
    if (DOMHelper.hasClass(this.rootElement, "focused")) {
      this.divImage.style.background = `url(${this.images.focusOff})`;
    } else {
      this.divImage.style.background = `url(${this.images.iconOff})`;
    }
    this.divImage.style.backgroundRepeat = "no-repeat";
    this.divImage.style.backgroundSize = "100%";
    this.divImage.style.backgroundPosition = "center";
  };
}
