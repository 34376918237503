import "./subscriptionView.scss";

import { DOMHelper, View } from "~ui-lib";

export class SubscriptionView extends View {
  constructor(item: string, isConnected = false) {
    super(DOMHelper.createDivWithParent(null, null, isConnected ? "CardviewConnected" : "Cardview"));
    const box = DOMHelper.createDivWithParent(this.rootElement, null, "cguBox");
    DOMHelper.createDivWithParent(box, null, "cguText", item.toString());
  }
}
