import "./livesTab.scss";

import { ItemCollection } from "~models/itemCollection";
import { DynamicBackground, DynamicBackgroundOverlayType } from "~pages/dynamicBackground";
import { LiveTilesSwimlane } from "~pages/lives/liveTilesSwimlane";
import { createListComponent, DOMHelper, IListComponent, StaticModelSource, View } from "~ui-lib";

import { Plugin } from "../../datas/plugin";
import { sendPianoAnalytic } from "../../tools/analytics/piano";

export class LivesTab extends View {
  listComponent?: IListComponent<ItemCollection>;
  private _focusedViewUnregister?: () => void;
  private _swimlaneFocusedIdUnregister?: () => void;
  private _source?: ItemCollection[] = undefined;
  private _background?: DynamicBackground;

  constructor() {
    super(DOMHelper.createDivWithParent(null, "LivesTab"));
    this._fetchSource();
  }

  onShown() {
    sendPianoAnalytic(
      "page.display",
      {
        page: "accueil_directs",
        page_type: "directs",
      },
      {}
    );
  }

  private _fetchSource() {
    Plugin.getInstance()
      .fetchDirects()
      .subscribe(
        value => {
          // Here use it to create the UI
          console.log("[DIRECTS] Next !", value);
          this._onSourceReady(value);
        },
        error => {
          // Here use it to trigger and display an error
          console.log("[DIRECTS] Error !", error);
        },
        () => {
          console.log("[DIRECTS] Complete !");
        }
      );
  }

  private _onSourceReady(source: ItemCollection[]) {
    this._source = source;
    this._background = new DynamicBackground(this.rootElement, {
      overlay: DynamicBackgroundOverlayType.gradient,
    });
    const contentRoot = DOMHelper.createDivWithParent(this.rootElement, null, "contentLivesTabSwimlane");
    this.listComponent = this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(contentRoot, "VerticalSwimlaneComponent", "list"),
        modelSource: new StaticModelSource(this._source),
        viewFactory: model => {
          const title =
            model.type === "playlist_channel" ? "En ce moment sur nos chaînes" : "En ce moment sur france TV";
          return new LiveTilesSwimlane(model, title);
        },
        pageSize: 1,
        visibleAfter: 1,
        horizontal: false,
        spatialFocus: true,
        mouseFocusInPageOnly: true,
      },
      mainList => {
        mainList.setFocusOnIndex(0);
      }
    );
    this._background.setItemCollectionList(this.listComponent);

    this._focusedViewUnregister = this.listComponent.focusedView$.didChange((newV, oldV) => {
      DOMHelper.addClass(newV?.rootElement, "onTop");
      DOMHelper.removeClass(oldV?.rootElement, "onTop");
    });
  }

  onRelease = () => {
    this._background?.onRelease();
    this._focusedViewUnregister?.();
  };
}
