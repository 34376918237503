import "./unitDesc.scss";

import { Unit } from "~models/unit";
import { Plugin } from "~pages/../datas/plugin";
import { getChannelPicto } from "~tools/getChannelPics";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";

import { parseMarkerPiano } from "../../datas/parser";
import { ILoggedInUser } from "../../models/user";
import { sendPianoAnalytic } from "../../tools/analytics/piano";
import { Spideo } from "../../tools/analytics/spideo";
import { Didomi } from "../../tools/cmp/didomi";
import { PlayHistoryHelper } from "../../tools/playerHistoryHelper";
import { popUpUserNeedConnectToReview, pushPopUpLogin } from "../../tools/pushPopUpLogin";
import { ContentRatingsHelper, LikeOrDislike, UpdateContentRating } from "../../tools/ratingsHelper";
import { dateToTimestampInS } from "../../tools/tools";
import { FavoriteButton, FavoriteButtonType } from "../../views/buttons/favoriteButton";
import { PlayButton } from "../../views/buttons/playButton";
import { PictoContainerRowView } from "../../views/pictoContainerRowView";
import { pushPlayerPage } from "../rootPage";

export class UnitDesc extends View {
  private _unit: Unit;
  private _isFavorites = false;
  private _isLiked: LikeOrDislike = 0;
  private _playButtonView = new PlayButton();
  private _favoriteButtonView?: FavoriteButton;
  private _likeThumbButtonView?: FavoriteButton;
  private _notLikeThumbButtonView?: FavoriteButton;

  constructor(_unit: Unit) {
    super(DOMHelper.createDivWithParent(null, null, "unitDesc"));
    this._unit = _unit;
    DOMHelper.createDivWithParent(this.rootElement, null, "unitDescTitle", this._unit.title);
    // DOMHelper.createDivWithParent(this.rootElement, null, "logoChannel").style.backgroundImage = `url("${
    //   getChannelPicto(this._unit, true) || require("~images/channels/picto/france-tv.png")
    // }")`;
    DOMHelper.createImg(
      this.rootElement,
      null,
      "logoChannel",
      getChannelPicto(this._unit, true) || require("~images/channels/picto/france-tv.png"),
      ""
    );

    //insert row picto
    this.rootElement.appendChild(new PictoContainerRowView(this._unit).rootElement);
    DOMHelper.createDivWithParent(this.rootElement, null, "summary", this._unit.summary ? this._unit.summary : "");
    this._fetchIsFavorite();
    this._fetchIsReviewContent();
    this._playButtonView.updateButton("regarder", "", { duration: this._unit.metadata.duration });
    this._fetchResume();
  }
  private _fetchIsReviewContent() {
    this._isLiked = ContentRatingsHelper.get(this._unit.id) ?? 0;
    this._likeThumbButtonView?.updateIsFavorite(this._isLiked === 1);
    this._notLikeThumbButtonView?.updateIsFavorite(this._isLiked === -1);
  }

  private _fetchIsFavorite() {
    const user = Plugin.getInstance().user;
    if (this._unit.id && user.isActive()) {
      Plugin.getInstance()
        .getIsFavorite(user, this._unit.id, "video")
        .subscribe(
          value => {
            Log.app.log("[getIsFavorite] Next !", value);
            this._isFavorites = value;
            this._onSourceReady();
          },
          error => {
            Log.app.error("[getIsFavorite] Error !", error);
          }
        );
    } else {
      this._onSourceReady();
    }
  }

  private _onSourceReady = () => {
    const buttonsContainer = DOMHelper.createDivWithParent(this.rootElement, null, "buttonsContainer");

    const buttons: (FavoriteButtonType | "playButton")[] = ["playButton"];
    buttons.push("favorite");
    if (Didomi.isVendorAllowedToTrack("spideo-TcYnKH8L")) {
      buttons.push("likeThumb");
      buttons.push("notLikeThumb");
    }

    this.delegate = createListComponent(
      {
        rootElement: buttonsContainer,
        modelSource: new StaticModelSource<FavoriteButtonType | "playButton">(buttons),
        horizontal: true,
        pageSize: 10,
        viewFactory: model => {
          if (model === "playButton") {
            return this._playButtonView;
          } else if (model === "likeThumb") {
            this._likeThumbButtonView = new FavoriteButton(this._isLiked === 1, model);
            return this._likeThumbButtonView;
          } else if (model === "notLikeThumb") {
            this._notLikeThumbButtonView = new FavoriteButton(this._isLiked === -1, model);
            return this._notLikeThumbButtonView;
          } else {
            this._favoriteButtonView = new FavoriteButton(this._isFavorites, model);
            return this._favoriteButtonView;
          }
        },
        onSelect: model => {
          switch (model) {
            case "playButton":
              pushPlayerPage(this._unit);
              return true;
            case "favorite": {
              const user = Plugin.getInstance().user;
              this._sendPianoAnalyticsClick(this._isFavorites ? "favori_retrait" : "favori_ajout");
              if (user.isActive()) {
                if (this._isFavorites) {
                  this._fetchDelFavorite(user);
                } else {
                  this._fetchAddFavorite(user);
                }
                this._isFavorites = !this._isFavorites;
                this._favoriteButtonView?.updateIsFavorite(this._isFavorites);
              } else {
                pushPopUpLogin("Vous souhaitez mettre ce programme en favoris ? Connectez-vous !");
              }
              return true;
            }
            case "likeThumb": {
              const user = Plugin.getInstance().user;
              if (user.isActive()) {
                if (this._isLiked === -1) {
                  this._isLiked = 1;
                  this._notLikeThumbButtonView?.updateIsFavorite(false);
                } else if (this._isLiked === 0) {
                  this._isLiked = 1;
                } else {
                  this._isLiked = 0;
                }
                this._sendPianoAnalyticsClick(this._isLiked === 1 ? "ajout_like_video" : "retrait_like_video");
                UpdateContentRating(
                  user,
                  this._unit.id,
                  dateToTimestampInS(this._unit.media?.end_date ?? undefined),
                  this._isLiked
                );
                this._likeThumbButtonView?.updateIsFavorite(this._isLiked === 1);
              } else {
                this._sendPianoAnalyticsClick("tentative_like_video");
                popUpUserNeedConnectToReview();
              }
              return true;
            }
            case "notLikeThumb": {
              const user = Plugin.getInstance().user;
              if (user.isActive()) {
                if (this._isLiked === 1) {
                  this._isLiked = -1;
                  this._likeThumbButtonView?.updateIsFavorite(false);
                } else if (this._isLiked === 0) {
                  this._isLiked = -1;
                } else {
                  this._isLiked = 0;
                }
                this._sendPianoAnalyticsClick(this._isLiked === -1 ? "ajout_dislike_video" : "retrait_dislike_video");
                UpdateContentRating(
                  user,
                  this._unit.id,
                  dateToTimestampInS(this._unit.media?.end_date ?? undefined),
                  this._isLiked
                );
                this._notLikeThumbButtonView?.updateIsFavorite(this._isLiked === -1);
              } else {
                this._sendPianoAnalyticsClick("tentative_dislike_video");
                popUpUserNeedConnectToReview();
              }
              return true;
            }
          }
        },
      },
      list => list.setFocusOnIndex(0)
    );
  };

  private _sendPianoAnalyticsClick = (clickValue: string) => {
    const markerPiano = parseMarkerPiano(this._unit.extras);

    if (markerPiano !== undefined) {
      sendPianoAnalytic("click.action", {}, { ...markerPiano.additional_properties, click: clickValue });
    } else {
      Log.analytics.error("Failed to send piano analytics click");
    }
  };

  private _fetchDelFavorite(user: ILoggedInUser) {
    this._sendSpideoEvent("unfavorite");
    Plugin.getInstance()
      .delFavorite(user, this._unit.id, "video")
      .subscribe(
        value => {
          Log.app.log("[delFavorite] Next !", value);
        },
        error => {
          Log.app.error("[delFavorite] Error !", error);
        }
      );
  }

  private _fetchAddFavorite(user: ILoggedInUser) {
    this._sendSpideoEvent("favorite");
    Plugin.getInstance()
      .addFavorite(user, this._unit.id, "video")
      .subscribe(
        value => {
          Log.app.log("[addFavorite] Next !", value);
        },
        error => {
          Log.app.error("[addFavorite] Error !", error);
        }
      );
  }

  private _sendSpideoEvent(event: "favorite" | "unfavorite") {
    Spideo.sendSpideoEvent({
      eventName: event,
      videoId: this._unit.id,
    });
  }

  onShown() {
    this._fetchResume();
  }

  private _fetchResume() {
    const user = Plugin.getInstance().user;
    if (user.isActive()) {
      const duration = this._unit.metadata.duration;
      const offset = Math.max(0, Math.min(duration, PlayHistoryHelper.getCurrentOffset(this._unit) ?? 0));
      if (offset <= 0 || duration <= 0 || offset > 0.96 * duration) {
        this._playButtonView.updateButton("regarder", "", { duration });
      } else {
        this._playButtonView.updateButton("reprendre", "", {
          duration: undefined,
          remaining_duration: duration - offset,
          percentage: offset / duration,
        });
      }
    }
  }
}
