"use strict";

import "./popupValidate.scss";

import { DOMHelper, IPage, Keys, View } from "~ui-lib";

import { navigationStack } from "../../main";
import { MainMenuItemSlug } from "../../views/mainMenu/itemMenu";
import { AccountTab, listAccount } from "../account/accountTab";
import { ConnexionPage } from "../connexion/connexionPage";
import { FavoritesTab } from "../favorites/favoritesTab";
import { HomeTab } from "../home/homeTab";
import { pushTabWithMenu, RootPage } from "../rootPage";

class PopupPage extends View implements IPage {
  timer: number;
  constructor(topPage: IPage | undefined) {
    super(DOMHelper.createDivWithParent(null, "ValidateScreen-overlay"));

    const textBox = DOMHelper.createDivWithParent(this.rootElement, null, "validateTextBox");
    DOMHelper.createDivWithParent(textBox, null, "validateText", "Votre TV est bien connectée à France.tv");
    DOMHelper.createDivImg(this.rootElement, null, "validateImg", require("~images/loader/validate.png"));
    this.timer = window.setTimeout(() => {
      // remove codePage
      navigationStack.removePage(topPage);
      // remove popup
      navigationStack.removePage(navigationStack.topPage);

      const newTopPage = navigationStack.topPage;
      if (
        newTopPage instanceof RootPage &&
        (newTopPage?.rootElement.id == "AccountPage" || newTopPage?.rootElement.id == "FavoritesPage")
      ) {
        const view = newTopPage.getContent();
        if (view instanceof FavoritesTab)
          pushTabWithMenu(new FavoritesTab(), "FavoritesPage", MainMenuItemSlug.favorites);
        else if (view instanceof AccountTab) {
          view.listComponent.resetContent();
          view.listComponent.setFocusOnId(listAccount.politique);
        }
      } else if (newTopPage instanceof ConnexionPage) {
        pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
      }
    }, 1000);
  }
  isPopup = () => true;
}

export class PopupValidate extends PopupPage {
  topPage: IPage | undefined;

  constructor(topPage: IPage | undefined) {
    super(topPage);
  }
  onRelease(): void {
    clearInterval(this.timer);
  }

  onNav = (key: Keys) => true;
}
