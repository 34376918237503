import { ItemCollection } from "./itemCollection";

export class Item {
  id: string;
  readonly type: string;
  title: string;
  summary?: string;
  itemCollection?: ItemCollection;

  constructor(id: string, type: string, title: string, summary?: string) {
    this.id = "" + id;
    this.title = title;
    this.type = type;
    this.summary = summary;
  }
}
