import "./meaSwimlane.scss";

import { ItemCollection } from "~models/itemCollection";
import { Program } from "~models/program";
import { Unit } from "~models/unit";
import { onSelectTile } from "~pages/rootPage";
import { createListComponent, DOMHelper, StaticModelSource, View } from "~ui-lib";
import { horizontalArrowFactory } from "~views/arrows/horizontalArrow";
import { MEAView } from "~views/meaView";

export class MEASwimlane extends View {
  cacheable = true;
  constructor(source: ItemCollection) {
    super(DOMHelper.createDivWithParent(null, "HorizontalSwimlaneMEAVignette", "list"));
    this.delegate = createListComponent({
      rootElement: this.rootElement,
      modelSource: new StaticModelSource(source.items as (Program | Unit)[]),
      viewFactory: model => new MEAView(model),
      horizontal: true,
      pageSize: 3,
      visibleBefore: 0,
      visibleAfter: 1,
      onSelect: onSelectTile,
      arrowFactory: horizontalArrowFactory,
    });
  }
}
