"use strict";

import "./popupPage.scss";

import { createListComponent, DOMHelper, IPage, StaticModelSource, View } from "~ui-lib";

import { navigationStack } from "../../main";
import { MainMenuItemSlug } from "../../views/mainMenu/itemMenu";
import { HomeTab } from "../home/homeTab";
import { pushTabWithMenu } from "../rootPage";

interface Button {
  label: string;
  action?: () => void;
}

class PopupButton extends View {
  constructor(button: Button) {
    super(DOMHelper.createDivWithParent(null, null, "popup-button"));
    const buttonZoom = DOMHelper.createDivWithParent(this.rootElement, null, "buttonZoom");
    DOMHelper.createDivWithParent(buttonZoom, null, "popup-button-text", button.label || "buttonLabel");
  }
}

export class PopupPage extends View implements IPage {
  constructor(
    params: {
      title: string;
      description: string;
      button1?: Button;
      button2?: Button;
      button3?: Button;
    },
    lightMode?: boolean
  ) {
    super(DOMHelper.createDivWithParent(null, lightMode ? "popup-overlay-light-mode" : "popup-overlay"));
    const { title, description, button1, button2, button3 } = params;

    const popup = DOMHelper.createDivWithParent(this.rootElement, "popup-container", null);
    DOMHelper.createDivWithParent(popup, "popup-title", null, title);
    DOMHelper.createDivWithParent(popup, "popup-msg", null, description);

    const buttons = [];
    button1 && buttons.push(button1);
    button2 && buttons.push(button2);
    button3 && buttons.push(button3);

    this.delegate = createListComponent(
      {
        rootElement: DOMHelper.createDivWithParent(popup, "popup-button-container"),
        // hack: add the index as an id to please the list
        modelSource: new StaticModelSource(buttons.map((button, index) => ({ ...button, id: index }))),
        viewFactory: button => {
          return new PopupButton(button);
        },
        horizontal: true,
        fitToChildren: true,
        onSelect: button => {
          navigationStack.removePage(this);
          button.action?.();
          return true;
        },
      },
      list => {
        list.setFocusOnIndex(0);
      }
    );
  }
  isPopup = () => true;
}

export class ErrorPage extends PopupPage {
  topPage: IPage | undefined;

  constructor(
    params: { title: string; description: string; button1?: Button; button2?: Button; button3?: Button },
    topPage: IPage | undefined
  ) {
    super(params);

    this.topPage = topPage;
  }
  onRelease(): void {
    // On release popup that has been pushed, I asked navigation to perform back from delegate popuup page
    // TODO: Check if error is generic and poped from page, otherwise we will need to handle differently
    if (this.topPage) {
      navigationStack.removePage(this.topPage);

      /**
       * Since we are removing top page, we can encounter cases when the navigationStack is empty
       * To prevent app to be stuck without any page, we are pushing the home page.
       */
      if (navigationStack.pages$.value.length <= 0) {
        pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
      }
    }
  }
}
